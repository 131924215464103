import { StyleSheet } from "react-native";

export const footerStyles = StyleSheet.create({
    appbar: {
        bottom: 0,
        display: "flex",
        left: 0,
        paddingLeft: 30,
        paddingRight: 30,
        position: "fixed",
        width: "100%",
        zIndex: 1000,
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        margin: 0,
        padding: 0,
    },
    buttons: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: "100%",
    },
    buttonDescription: {
        textAlign: "center",
    },
    footerDesktopMenu: {
        alignItems: "center",
        bottom: 0,
        height: 35,
        justifyContent: "center",
        left: 0,
        position: "fixed",
        width: "100%",
    },
    footerDesktopLinks: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
    },
    footerDesktopLink: {
        fontSize: 16,
        textDecorationLine: "none",
    },
    footerDesktopLinkSeparator: {
        fontSize: 16,
        marginHorizontal: 10,
    },
});

