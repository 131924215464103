import React, { Component, PureComponent } from "react";
import { FlatList, View, StyleSheet, Dimensions } from "react-native";
import { Button, Text, withTheme } from "react-native-paper";
import { withRouter } from "react-router";
import ShopItem from "./ShopItem";
import { ShopStyles } from "./ShopStyles";
import { Ionicons } from "@expo/vector-icons";
import { store } from "../../store/store";
import { setCurrentCategory } from "../../store/actions/onlineShopCategoriesActions";

class Category extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            scrollingPosition: 0,
            windowWidth: Dimensions.get("window").width,
            hoveredItem: undefined,
        };
        this.ref = React.createRef();
        this.renderItem = this.renderItem.bind(this);
        this.goToCategory = this.goToCategory.bind(this);
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.setHoveredItem = this.setHoveredItem.bind(this);
        this.renderCell = this.renderCell.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.handleResize);
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.handleResize);
    }

    handleResize() {
        this.setState({ windowWidth: Dimensions.get("window").width });
    }

    categoryHasIcons() {
        return this.props.onlineShopItems.some((item) => item.articleIcons && item.articleIcons.length > 0);
    }

    renderItem(item) {
        const { mobile } = this.props;
        const categoryHasIcons = this.categoryHasIcons();
        return (
            <ShopItem
                item={item}
                url={this.props.category.url}
                mobile={mobile}
                setHoveredItem={this.setHoveredItem}
                hoveredItem={this.state.hoveredItem}
                showAddRecipeToWeeklyPlanPopup={this.props.showAddRecipeToWeeklyPlanPopup}
                showAddArticleToCartPopup={this.props.showAddArticleToCartPopup}
                cookRecipe={this.props.cookRecipe}
                toggleLoginPopup={this.props.toggleLoginPopup}
                isCarousel={true}
                categoryHasIcons={categoryHasIcons}
            />
        );
    }

    goToCategory() {
        const { history } = this.props;
        store.dispatch(setCurrentCategory(this.props.category));
        history.push(
            "/category/" +
                this.props.category.seoName +
                "/" +
                this.props.category.categoriesId +
                "/" +
                encodeURIComponent(this.props.category.url)
        );
    }

    scrollLeft() {
        const itemsToScroll = this.props.mobile ? 2 : 3;
        const newPosition = Math.max(this.state.scrollingPosition - itemsToScroll, 0);
        this.ref.scrollToIndex({
            animated: true,
            index: newPosition,
        });

        this.setState({ scrollingPosition: newPosition });
    }

    scrollRight() {
        const itemsToScroll = this.props.mobile ? 2 : 3;
        const newPosition = Math.min(
            this.state.scrollingPosition + itemsToScroll,
            this.props.onlineShopItems.length - 1
        );
        this.ref.scrollToIndex({
            animated: true,
            index: newPosition,
        });

        this.setState({ scrollingPosition: newPosition });
    }

    setHoveredItem(id) {
        this.setState({
            hoveredItem: id,
        });
    }

    renderCell({ index, style, ...props }) {
        const zIndex = {
            zIndex: this.state.hoveredItem && props.item.id === this.state.hoveredItem ? 2 : 0,
        };

        return <View style={[style, zIndex]} {...props} />;
    }

    render() {
        const theme = this.props.theme;
        const mobile = this.props.mobile;
        const numberOfTiles = this.props.numberOfTiles;
        const showArrows = this.props.onlineShopItems.length > numberOfTiles;

        return (
            <View key={this.props.key}>
                {this.props.category && (
                    <React.Fragment>
                        <Text
                            style={{
                                marginLeft: mobile ? 25 : 50,
                                fontSize: mobile ? 18 : 28,
                                marginBottom: mobile ? 0 : -70,
                                marginTop: mobile ? 10 : -40,
                                marginRight: mobile ? 25 : 50,
                                zIndex: this.state.hoveredItem ? 0 : 9999,
                            }}
                        >
                            {this.props.category.name}

                            {this.props.category.categoriesId !== -1 && (
                                <Button
                                    style={{ fontSize: mobile ? 10 : 12 }}
                                    labelStyle={{ marginBottom: mobile ? 0 : 0 }}
                                    onPress={this.goToCategory}
                                    rippleColor="transparent"
                                >
                                    Mehr...
                                </Button>
                            )}
                        </Text>
                        <View style={mobile ? ShopStyles.CategoryMobile : ShopStyles.Category}>
                            <FlatList
                                data={this.props.onlineShopItems}
                                showsHorizontalScrollIndicator={false}
                                horizontal={true}
                                renderItem={this.renderItem}
                                maxToRenderPerBatch={6}
                                style={{
                                    marginLeft: mobile ? 25 : 40,
                                    marginRight: mobile ? 25 : 50,
                                }}
                                contentContainerStyle={{
                                    height: mobile ? 280 : 350,
                                    alignItems: "center",
                                    overflow: "visible",
                                }}
                                CellRendererComponent={this.renderCell}
                                initialNumToRender={6}
                                ref={(ref) => (this.ref = ref)}
                                keyExtractor={(item, index) => item.id}
                            />
                            {showArrows && this.state.scrollingPosition > 0 && (
                                <Ionicons
                                    name="chevron-back"
                                    style={[
                                        ShopStyles.previousButton,
                                        mobile && ShopStyles.mobileCatArrowButton,
                                        mobile && ShopStyles.mobileCatArrowPrevButton,
                                    ]}
                                    onPress={this.scrollLeft}
                                    size={40}
                                    color={theme.colors.primary}
                                />
                            )}
                            {showArrows &&
                                this.state.scrollingPosition < this.props.onlineShopItems.length - numberOfTiles && (
                                    <Ionicons
                                        name="chevron-forward"
                                        color={theme.colors.primary}
                                        size={40}
                                        style={[
                                            ShopStyles.nextButton,
                                            mobile && ShopStyles.mobileCatArrowButton,
                                            mobile && ShopStyles.mobileCatArrowNextButton,
                                        ]}
                                        onPress={this.scrollRight}
                                    />
                                )}
                        </View>
                    </React.Fragment>
                )}
            </View>
        );
    }
}

export default withRouter(withTheme(Category));
