import React, { useEffect, useRef, useState } from "react";
import { View, StyleSheet, Pressable } from "react-native";

const CustomMenu = ({
    anchor = <View>Menu</View>,
    children = [],
    direction = "down",
    menuItemBackgroundColor = "#ffffff",
    menuItemTextColor = "#000000",
    menuAlignment = "right",
}) => {
    const [visible, setVisible] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = (event) => {
        if (visible) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    };

    const closeMenu = () => {
        setVisible(false);
    };

    const handleClickOutside = (node, event) => {
        if (node && !node.contains(event.target)) {
            setVisible(false);
        }
    };

    const handleEvent = (node) => (event) => handleClickOutside(node, event);

    useEffect(() => {
        document.addEventListener("mousedown", handleEvent(menuRef.current));

        return () => {
            document.removeEventListener("mousedown", handleEvent(menuRef.current));
        };
    }, []);

    const menuPositionStyle = direction === "down" ? styles.menuDown : styles.menuUp;

    const renderedChildren = React.Children.map(children, (child, index) => {
        const isLastItem = index === React.Children.count(children) - 1;
        const menuItemStyle = isLastItem ? styles.lastMenuItem : styles.menuItem;

        return React.cloneElement(child, {
            onPress: () => {
                child.props.onPress();
                closeMenu();
            },
            style: menuItemStyle,
            backgroundColor: menuItemBackgroundColor,
            textColor: menuItemTextColor,
        });
    });

    let menuAlignmentStyle = {};
    switch (menuAlignment) {
        case "left":
            menuAlignmentStyle = { left: 0 };
            break;
        case "center":
            menuAlignmentStyle = { left: "50%", transform: [{ translateX: "-50%" }] };
            break;
        case "right":
        default:
            menuAlignmentStyle = { right: 0 };
            break;
    }

    return (
        <View ref={menuRef} style={styles.container}>
            <Pressable onPress={(event) => toggleMenu(event)} style={styles.anchor}>
                {anchor}
            </Pressable>
            {visible && (
                <View
                    style={[
                        styles.menu,
                        menuPositionStyle,
                        menuAlignmentStyle,
                        { backgroundColor: menuItemBackgroundColor },
                    ]}
                >
                    {renderedChildren}
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        position: "relative",
        zIndex: 2000,
    },
    anchor: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    menu: {
        position: "absolute",
        backgroundColor: "white",
        padding: 10,
        borderRadius: 5,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        zIndex: 2000,
    },
    menuDown: {
        top: "100%",
    },
    menuUp: {
        bottom: "100%",
    },
    menuItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#ccc",
        whiteSpace: "nowrap",
    },
    lastMenuItem: {
        padding: 10,
        borderBottomWidth: 0,
    },
});

export default CustomMenu;
