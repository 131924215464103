import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { Button } from "react-native-paper";
import { getContrastColor } from "../../../shared/helpers";
import { customerDataStyles } from "../CustomerDataStyles";

const DialogActions = ({
    windowWidth,
    theme,
    hideCustomerData,
    account,
    customerDataForm,
    submitButtonName,
    step,
    prevStep,
    nextStep,
    paymentMethod,
    datenschutzIsChecked,
    addresses,
    toggleMissingAddressPopup,
    desktopBreakpoint,
    formIsValid,
}) => {
    return (
        <View style={{ paddingBottom: 0, paddingTop: 15 }}>
            <View
                style={[
                    windowWidth <= desktopBreakpoint
                        ? customerDataStyles.containerFullRow
                        : customerDataStyles.containerSplitRow,
                    {
                        paddingBottom: 0,
                    },
                ]}
            >
                {step === 0 && (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: theme.colors.notification,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                        }}
                        onPress={hideCustomerData}
                        uppercase={false}
                    >
                        Abbrechen
                    </Button>
                )}
                {step > 0 ? (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: theme.colors.primary,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                        }}
                        type="button"
                        onPress={prevStep}
                        uppercase={false}
                    >
                        Zurück
                    </Button>
                ) : null}
                {step === 0 || step === 1 ? (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor:
                                formIsValid && datenschutzIsChecked ? theme.colors.primary : theme.colors.disabled,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                            opacity: formIsValid && datenschutzIsChecked ? 1 : 0.5,
                        }}
                        onPress={() => {
                            if (account.customer && addresses.length < 1) {
                                toggleMissingAddressPopup();
                            } else {
                                nextStep();
                            }
                        }}
                        disabled={!formIsValid || !datenschutzIsChecked}
                        uppercase={false}
                    >
                        Weiter
                    </Button>
                ) : null}
                {step === 2 && (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: !datenschutzIsChecked ? theme.colors.disabled : theme.colors.primary,
                            marginRight: windowWidth <= desktopBreakpoint ? 0 : "2%",
                            marginBottom: windowWidth <= desktopBreakpoint ? 10 : 0,
                            width: windowWidth <= desktopBreakpoint ? "100%" : "48%",
                        }}
                        onPress={() => {
                            if (account.customer && addresses.length < 1) {
                                toggleMissingAddressPopup();
                            } else {
                                customerDataForm.current.submitForm();
                            }
                        }}
                        disabled={!formIsValid}
                        uppercase={false}
                    >
                        {submitButtonName}
                    </Button>
                )}
            </View>
        </View>
    );
};

export default DialogActions;
