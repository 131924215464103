import moment from "moment";
import React, { Component } from "react";
import CustomDatePicker from "../../../helpers/datepicker/CustomDatePicker";
import { withTheme, ActivityIndicator, Button, Dialog, Paragraph, IconButton, Text } from "react-native-paper";
import { View, Dimensions } from "react-native";
import { connect } from "react-redux";
import { getContrastColor, tabletBreakpoint } from "../../../shared/helpers";
import { Picker } from "@react-native-picker/picker";
import { styles } from "./AddToWeeklyPlanDialogStyles";
import { styles as sharedStyles } from "../../../shared/styles";
import AmountSelection from "../addToCartDialog/AmountSelection";
import { showToast } from "../../../helpers/toastMessage/ToastMessage";

class AddToWeeklyPlanDialog extends Component {
    constructor(props) {
        super(props);

        const sortedArticleSizes =
            props.articleSizes && props.articleSizes.length > 0
                ? props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder)
                : [];

        const initialArticleSizeId = props.selectedArticleSize ? props.selectedArticleSize.articleSizesId : undefined;

        let selectedArticleSizeId;
        if (props.selectedCookingProcess && props.selectedCookingProcess.CookingProcessArticleSizes) {
            selectedArticleSizeId = props.selectedCookingProcess.CookingProcessArticleSizes[0].articleSizesId;
        } else if (initialArticleSizeId) {
            selectedArticleSizeId = initialArticleSizeId;
        } else if (sortedArticleSizes.length > 0) {
            selectedArticleSizeId = sortedArticleSizes[0].articleSizesId;
        } else {
            selectedArticleSizeId = undefined;
        }

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            selectedCustomerCourse: undefined,
            selectedCustomerCourseId:
                this.props.account.customer.customerCourses && this.props.account.customer.customerCourses.length > 0
                    ? this.props.account.customer.customerCourses[0].customerCoursesId
                    : undefined,

            selectedArticleSizeId: selectedArticleSizeId,

            amount: this.props.selectedCookingProcess
                ? this.props.selectedCookingProcess.CookingProcessArticleSizes[0].amount
                : 1,
            selectedDate: this.props.selectedCookingProcess
                ? new moment(this.props.selectedCookingProcess.dayToCook)
                : new moment(),
        };

        this.addRecipeToWeeklyPlan = this.addRecipeToWeeklyPlan.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        if (this.props.account.customer.customerCourses && this.props.account.customer.customerCourses.length > 0) {
            this.setState({ selectedCustomerCourse: this.props.account.customer.customerCourses[0] });
        }
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    addRecipeToWeeklyPlan() {
        var errorFound = false;
        let message = "";
        let icon = "";
        if (!this.state.selectedArticleSizeId && !this.props.selectedCookingProcess) {
            this.setState({
                selectedArticleSizesIdError: true,
            });
            errorFound = true;
        } else {
            this.setState({
                selectedArticleSizesIdError: false,
            });
        }

        if (!errorFound) {
            if (this.props.selectedCookingProcess) {
                message = `Der Kochprozess von '${this.props.selectedCookingProcess.name}' wurde erfolgreich angepasst`;
                icon = "checkmark-outline";
                this.props.updateCookingProcess(
                    parseInt(this.state.selectedCustomerCourseId, 10),
                    parseInt(this.state.selectedArticleSizeId, 10),
                    this.state.selectedDate,
                    this.state.amount,
                    this.props.selectedCookingProcess.cookingProcessId
                );
            } else {
                message = `Das Rezept '${this.props.selectedRecipe.name}' wurde in den Wochenplan gelegt`;
                icon = "calendar";
                this.props.addRecipeToWeeklyPlan(
                    parseInt(this.state.selectedCustomerCourseId, 10),
                    parseInt(this.state.selectedArticleSizeId, 10),
                    this.props.recipesId,
                    this.state.selectedDate,
                    this.state.amount,
                    this.props.settings.store.urlBasePath
                );
            }
            showToast(message, icon, "success");
        }
    }

    handleDateChange(dateObj) {
        let date;
        if (dateObj && dateObj.date) {
            date = new Date(dateObj.date);
        } else if (dateObj instanceof Date && !isNaN(dateObj)) {
            date = dateObj;
        }

        if (date) {
            this.setState({
                selectedDate: moment(date),
            });
        } else {
            console.error("Ungültiges Datum erhalten:", dateObj);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.recipesId !== prevProps.recipesId) {
            this.setState({
                selectedCustomerCourse: undefined,
                selectedArticleSize: undefined,
                amount: 0,
                selectedDate: new moment(),
            });
        }
        if (this.props.selectedCookingProcess !== prevProps.selectedCookingProcess) {
            this.setState({
                selectedCustomerCourse: this.props.selectedCookingProcess
                    ? this.props.selectedCookingProcess.CustomerCourse
                    : undefined,
                selectedArticleSize: this.props.selectedCookingProcess
                    ? this.props.selectedCookingProcess.CookingProcessArticleSizes[0]
                    : undefined,
                amount: this.props.selectedCookingProcess
                    ? this.props.selectedCookingProcess.CookingProcessArticleSizes[0].amount
                    : 0,
                selectedDate: this.props.selectedCookingProcess
                    ? new moment(this.props.selectedCookingProcess.dayToCook)
                    : new moment(),
            });
        }
        if (
            this.props.articlesId !== prevProps.articlesId ||
            this.props.selectedArticleSize !== prevProps.selectedArticleSize
        ) {
            const sortedArticleSizes = this.props.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);

            this.setState({
                selectedArticleSize: this.props.selectedArticleSize || sortedArticleSizes[0],
            });
        }
    }

    render() {
        const windowWidth = this.state.windowWidth;
        const sortedArticleSizes =
            this.props.articleSizes && this.props.articleSizes.length > 0
                ? [...this.props.articleSizes].sort((a, b) => a.Size.sortorder - b.Size.sortorder)
                : [];
        let receipeName = "";

        if (this.props.selectedRecipe) {
            receipeName = this.props.selectedRecipe.name;
        } else if (this.props.selectedCookingProcess) {
            receipeName = this.props.selectedCookingProcess.name;
        }

        const customerCourses = this.props.account.customer.customerCourses || [];

        return (
            <Dialog
                visible={this.props.addToWeeklyPlanVisible}
                onDismiss={this.props.toggleAddToWeeklyPlanDialog}
                style={{ backgroundColor: this.props.theme.colors.surface, marginLeft: "auto", marginRight: "auto" }}
                dismissable={false}
            >
                <IconButton
                    icon="close"
                    size={24}
                    onPress={this.props.toggleAddToWeeklyPlanDialog}
                    style={[sharedStyles.dialogCloseButton, { backgroundColor: this.props.theme.colors.primary }]}
                    iconColor={getContrastColor(this.props.theme.colors.primary)}
                    rippleColor="transparent"
                />
                <Dialog.Title style={[sharedStyles.dialogHeadline, { paddingRight: 40 }]}>
                    {this.props.startCookingProcessImmediately
                        ? "Kochprozess anlegen"
                        : this.props.selectedRecipe
                        ? "In den Wochenplan legen"
                        : "Kochprozess anpassen"}
                </Dialog.Title>

                <Dialog.Content>
                    {this.props.loading && (
                        <View
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ActivityIndicator
                                size={"large"}
                                animating={true}
                                color={this.props.theme.colors.primary}
                            />
                        </View>
                    )}
                    <View style={{ alignItems: "center", justifyContent: "center", marginBottom: 20 }}>
                        <Text style={styles.addToWeeklyPlanArticleName}>{receipeName}</Text>
                    </View>
                    {!this.props.startCookingProcessImmediately && (
                        <View style={{ marginBottom: 20 }}>
                            <Text style={styles.addToWeeklyPlanDialogSelectLabel}>Gang</Text>
                            <Picker
                                selectedValue={this.state.selectedCustomerCourseId}
                                onValueChange={(itemValue) => this.setState({ selectedCustomerCourseId: itemValue })}
                                style={[
                                    styles.addToWeeklyPlanDialogSelect,
                                    {
                                        fontFamily: "regular",
                                        backgroundColor: this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                        color: getContrastColor(this.props.theme.colors.surface),
                                    },
                                ]}
                                itemStyle={{ fontFamily: "regular", fontWeight: "bold" }}
                            >
                                {customerCourses.map((course) => (
                                    <Picker.Item
                                        key={course.customerCoursesId}
                                        label={course.Courses.name}
                                        value={course.customerCoursesId}
                                    />
                                ))}
                            </Picker>
                            {this.state.selectedCustomerCoursesIdError && (
                                <Paragraph style={{ fontFamily: "regular", color: this.props.theme.colors.error }}>
                                    Dies ist eine Pflichtangabe
                                </Paragraph>
                            )}
                        </View>
                    )}
                    {sortedArticleSizes.length > 1 && (
                        <View style={{ marginBottom: 20 }}>
                            <Text style={styles.addToWeeklyPlanDialogSelectLabel}>Größe</Text>
                            <Picker
                                selectedValue={this.state.selectedArticleSizeId}
                                onValueChange={(itemValue) => this.setState({ selectedArticleSizeId: itemValue })}
                                style={[
                                    styles.addToWeeklyPlanDialogSelect,
                                    {
                                        fontFamily: "regular",
                                        backgroundColor: this.props.theme.colors.surface,
                                        borderColor: this.props.theme.colors.primary,
                                        color: getContrastColor(this.props.theme.colors.surface),
                                    },
                                ]}
                            >
                                {sortedArticleSizes.map((articleSize) => (
                                    <Picker.Item
                                        key={articleSize.articleSizesId}
                                        label={articleSize.Size.name}
                                        value={articleSize.articleSizesId}
                                    />
                                ))}
                            </Picker>
                            {this.state.selectedArticleSizesIdError && (
                                <Paragraph style={{ fontFamily: "regular", color: this.props.theme.colors.error }}>
                                    Dies ist eine Pflichtangabe
                                </Paragraph>
                            )}
                        </View>
                    )}
                    {!this.props.startCookingProcessImmediately && (
                        <CustomDatePicker
                            loading={this.props.loading}
                            label="Wähle einen Tag aus"
                            handleDateChange={this.handleDateChange}
                            selectedDate={this.state.selectedDate}
                            backgroundColor={this.props.theme.colors.surface}
                            textColor={getContrastColor(this.props.theme.colors.surface)}
                            borderColor={this.props.theme.colors.primary}
                            iconColor={getContrastColor(this.props.theme.colors.surface)}
                        />
                    )}
                    <View style={{ marginTop: 20 }}>
                        <Text style={styles.addToWeeklyPlanDialogSelectLabel}>Menge</Text>
                        <AmountSelection
                            initialAmount={this.state.amount}
                            maxAmount={this.props.settings.store.maxPortionNumber}
                            theme={this.props.theme}
                            onAmountChange={(newAmount) => this.setState({ amount: newAmount })}
                        />
                    </View>
                </Dialog.Content>
                <Dialog.Actions
                    style={{ justifyContent: "center", width: "100%", marginLeft: "auto", marginRight: "auto" }}
                >
                    <View
                        style={
                            windowWidth <= tabletBreakpoint
                                ? sharedStyles.dialogButtonContainerHorizontal
                                : sharedStyles.dialogButtonContainerVertical
                        }
                    >
                        <Button
                            textColor={getContrastColor(this.props.theme.colors.primary)}
                            style={{
                                backgroundColor: this.props.theme.colors.error,
                                flex: 1,
                                marginRight: windowWidth <= tabletBreakpoint ? 0 : "2%",
                                marginBottom: windowWidth <= tabletBreakpoint ? 10 : 10,
                            }}
                            uppercase={false}
                            onPress={this.props.toggleAddToWeeklyPlanDialog}
                            disabled={this.props.loading}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            textColor={getContrastColor(this.props.theme.colors.primary)}
                            style={{
                                backgroundColor: this.props.theme.colors.primary,
                                flex: 1,
                                marginRight: windowWidth <= tabletBreakpoint ? 0 : "2%",
                                marginBottom: windowWidth <= tabletBreakpoint ? 10 : 10,
                            }}
                            uppercase={false}
                            onPress={this.addRecipeToWeeklyPlan}
                            disabled={this.props.loading}
                        >
                            {this.props.selectedCookingProcess
                                ? "Speichern"
                                : this.props.startCookingProcessImmediately
                                ? "Jetzt kochen"
                                : "Hinzufügen"}
                        </Button>
                    </View>
                </Dialog.Actions>
            </Dialog>
        );
    }
}
function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}
export default connect(mapStateToProps)(withTheme(AddToWeeklyPlanDialog));
