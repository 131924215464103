import { store } from "../../store/store";
import { showSessionExpiredDialog } from "../../store/actions/uiActions";
import keycloak from "../../helpers/keycloakConfig/KeycloakConfig";

const TOKEN_REFRESH_INTERVAL = 3600; // 1 hour in seconds

const waitForTokenParsed = (retryInterval = 1000, maxRetries = 5) => {
    let retries = 0;

    return new Promise((resolve, reject) => {
        const checkToken = async () => {
            const tokenParsed = keycloak.tokenParsed;

            if (tokenParsed) {
                resolve(tokenParsed);
            } else if (retries < maxRetries) {
                retries++;
                setTimeout(checkToken, retryInterval);
            } else {
                reject(new Error("No token available"));
                store.dispatch(showSessionExpiredDialog());
            }
        };

        checkToken();
    });
};

export async function checkAndRefreshToken() {
    try {
        const tokenParsed = await waitForTokenParsed();
        // console.log("Token Parsed:", tokenParsed);

        const currentTime = Math.floor(Date.now() / 1000);
        const tokenAge = currentTime - tokenParsed.iat;
        const tokenExpiresIn = tokenParsed.exp - currentTime;
        const tokenTotalLifetime = tokenParsed.exp - tokenParsed.iat;

        // console.log("Current Time (seconds since epoch):", currentTime);
        // console.log("Token Issued At (seconds since epoch):", tokenParsed.iat);
        // console.log("Token Expires At (seconds since epoch):", tokenParsed.exp);
        // console.log("Token Age (seconds since issued):", tokenAge);
        // console.log("Token Expires In (seconds from now):", tokenExpiresIn);
        // console.log("Token Total Lifetime (seconds):", tokenTotalLifetime);

        if (tokenAge > tokenTotalLifetime) {
            // console.log("Token has expired.");
            store.dispatch(showSessionExpiredDialog());
            throw new Error("Session expired");
        } else if (tokenExpiresIn <= TOKEN_REFRESH_INTERVAL) {
            try {
                const minValidity = TOKEN_REFRESH_INTERVAL; // Refresh if token is about to expire within the refresh interval
                const refreshed = await keycloak.updateToken(minValidity);
                if (refreshed) {
                    // console.log("Token was successfully refreshed");
                    const newTokenAge = Math.floor(Date.now() / 1000) - keycloak.tokenParsed.iat;
                    // console.log("New token age (seconds since issued):", newTokenAge);
                } else {
                    // console.log("Token is still valid but older than 1 hour");
                }
            } catch (error) {
                console.error("Token refresh failed:", error);
                store.dispatch(showSessionExpiredDialog());
                throw new Error("Session expired");
            }
        } else {
            // console.log("Token is still valid and within the refresh interval");
        }
    } catch (error) {
        store.dispatch(showSessionExpiredDialog());
        return Promise.reject(error);
    }
}
