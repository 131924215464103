import React, { useEffect } from "react";
import { Card, Text, Avatar } from "react-native-paper";
import { View } from "react-native-web";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { setOnlineShopItemType } from "../../store/actions/onlineShopCategoriesActions";
import { desktopBreakpoint, getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";

const CustomerNavigation = ({ windowWidth }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const theme = useSelector((state) => state.settings.theme);
    const isDesktop = windowWidth >= desktopBreakpoint;

    useEffect(() => {
        dispatch(setOnlineShopItemType(-2));
    }, [dispatch]);

    const navigationItems = [
        {
            icon: "account-edit",
            label: "Kundendaten",
            link: "/customer/profile/",
        },
        {
            icon: "home-edit",
            label: "Adressen",
            link: "/customer/addresses/",
        },
    ];

    return (
        <View style={{ ...styles.customerNavigationContainer }}>
            {navigationItems.map((item, index) => {
                const isActive = location.pathname === item.link;

                return (
                    <Card
                        key={index}
                        style={{
                            ...styles.customerNavigationCard,
                            backgroundColor: isActive ? theme.colors.primary : undefined,
                            margin: isDesktop ? 5 : 5,
                            marginHorizontal: isDesktop ? 5 : 5,
                            maxWidth: isDesktop ? undefined : 115,
                            width: isDesktop ? "calc(100% - 10px)" : `calc(50% - 10px)`,
                        }}
                        onPress={() => history.push(item.link)}
                    >
                        <Card.Content style={{ margin: "auto" }}>
                            <View style={styles.customerNavigationCardContent}>
                                <Avatar.Icon
                                    icon={item.icon}
                                    style={{
                                        backgroundColor: isActive ? theme.colors.surface : theme.colors.primary,
                                    }}
                                    color={getContrastColor(isActive ? theme.colors.surface : theme.colors.primary)}
                                    size={38}
                                />
                                <Text
                                    style={{
                                        marginTop: 15,
                                        color: getContrastColor(isActive ? theme.colors.primary : theme.colors.surface),
                                    }}
                                >
                                    {item.label}
                                </Text>
                            </View>
                        </Card.Content>
                    </Card>
                );
            })}
        </View>
    );
};

export default CustomerNavigation;
