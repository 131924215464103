import React from "react";
import { Paragraph } from "react-native-paper";
import moment from "moment";
import { View } from "react-native";
import { WeeklyPlanStyles } from "./WeeklyPlanStyles";

export default function WeeklyPlanDays({ props, monday }) {
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "stretch",
                backgroundColor: props.theme.colors.background,
                flex: 1,
            }}
        >
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph style={WeeklyPlanStyles.dayText}>Mo.</Paragraph>
                <Paragraph style={WeeklyPlanStyles.dayText}>{monday.format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph style={WeeklyPlanStyles.dayText}>Di.</Paragraph>
                <Paragraph style={WeeklyPlanStyles.dayText}>{moment(monday).add(1, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph style={WeeklyPlanStyles.dayText}>Mi.</Paragraph>
                <Paragraph style={WeeklyPlanStyles.dayText}>{moment(monday).add(2, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph style={WeeklyPlanStyles.dayText}>Do.</Paragraph>
                <Paragraph style={WeeklyPlanStyles.dayText}>{moment(monday).add(3, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph style={WeeklyPlanStyles.dayText}>Fr.</Paragraph>
                <Paragraph style={WeeklyPlanStyles.dayText}>{moment(monday).add(4, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph style={WeeklyPlanStyles.dayText}>Sa.</Paragraph>
                <Paragraph style={WeeklyPlanStyles.dayText}>{moment(monday).add(5, "days").format("D.M.")}</Paragraph>
            </View>
            <View
                style={{
                    flex: 1,
                    height: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Paragraph style={WeeklyPlanStyles.dayText}>So.</Paragraph>
                <Paragraph style={WeeklyPlanStyles.dayText}>{moment(monday).add(6, "days").format("D.M.")}</Paragraph>
            </View>
        </View>
    );
}
