const { StyleSheet } = require("react-native");

export const styles = StyleSheet.create({
    // accordionContentContainer: { backgroundColor: "rgba(0,0,0,0.1)" },
    additionalProductDetailsContainer: {
        flex: 1,
    },
    additionalProductDetailsContainerMobile: {
        width: "100%",
    },
    accordionContainer: { borderRadius: 7, overflow: "hidden" },
    listSection: { marginTop: 0, marginBottom: 0 },
    tabViewContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
    },
    tabViewDrawerContainer: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        // backgroundColor: "rgba(0, 0, 0, 0.5)",
        paddingTop: 7,
        paddingBottom: 5,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    tabViewDrawerItem: {
        fontSize: 18,
        overflow: "hidden",
    },
    tabViewDrawerItemContent: {
        paddingHorizontal: 17,
        paddingVertical: 10,
    },
    tabViewDrawerItemActive: {
        borderRadius: 25,
    },
    tabViewContentContainer: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "auto",
        borderWidth: 2,
        borderStyle: "solid",
        // borderColor: "rgba(0, 0, 0, 0.5)",
        // backgroundColor: "rgba(0,0,0,0.1)",
        flex: 1,
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        padding: 10,
    },
});
