import React, { Component } from "react";
import { Animated, StyleSheet, View, Pressable, Platform } from "react-native";
import { Icon, Portal, Text, withTheme } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { connect } from "react-redux";
import { Link, withRouter } from "../../routers/routing";
import { styles } from "../../shared/styles";
import { getContrastColor, filterOnlineShopItems, checkForRecipes, withLocation } from "../../shared/helpers";
import Config from "../../../Config";
import { store } from "../../store/store";
import { mobileMenuStyle } from "./MobileMenuStyles";
import { setOnlineShopItemType, setCurrentCategory } from "../../store/actions/onlineShopCategoriesActions";
import * as CookieConsent from "vanilla-cookieconsent";
import ExternalLink from "../../helpers/link/ExternalLink";

const config = new Config();

class MobileMenu extends Component {
    constructor(props) {
        super(props);

        this.widthValue = new Animated.Value(0);

        this.state = {
            showRecipes: false,
            biosInstances: [],
            expandedMenus: {},
        };

        this.slideIn = this.slideIn.bind(this);
        this.slideOut = this.slideOut.bind(this);
        this.handleLoginPress = this.handleLoginPress.bind(this);
        this.handleLogoutPress = this.handleLogoutPress.bind(this);
        this.navigate = this.navigate.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        const showRecipes = checkForRecipes(this.props.biosInstances, this.props.onlineShopCategories);
        this.setState({ showRecipes });

        this.getShopCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        const { open } = this.props;

        if (open && !prevProps.open) {
            this.slideIn();
        } else if (!open && prevProps.open) {
            this.slideOut();
        }

        if (prevProps !== this.props) {
            const showRecipes = checkForRecipes(this.props.biosInstances, this.props.onlineShopCategories);
            this.setState({ showRecipes });
        }
    }

    getShopCategories() {
        let biosInstances = [];
        this.setState({ biosInstances: biosInstances });
        var categories = [];
        biosInstances.push({ url: config.backendHost });
        biosInstances.forEach((instance) => {
            const recipes = this.props.onlineShopCategories["recipes_" + instance.url];
            const articles = this.props.onlineShopCategories["articles_" + instance.url];
            if (
                this.props.onlineShopCategories["recipes_" + instance.url] &&
                this.props.onlineShopCategories["recipes_" + instance.url][0]
            ) {
                if (recipes && Array.isArray(recipes[0])) {
                    this.props.onlineShopCategories["recipes_" + instance.url][0].forEach((category) => {
                        if (
                            category.showInOnlineShopHeader &&
                            (this.props.onlineShopCategories.onlineShopItemType === -1 ||
                                category.onlineShopItemType === this.props.onlineShopCategories.onlineShopItemType)
                        ) {
                            categories.push({ ...category, isRecipe: true });
                        }
                    });
                }
            }
            if (
                this.props.onlineShopCategories["articles_" + instance.url] &&
                this.props.onlineShopCategories["articles_" + instance.url][0]
            ) {
                this.props.onlineShopCategories["articles_" + instance.url][0].forEach((category) => {
                    if (
                        category.showInOnlineShopHeader &&
                        (this.props.onlineShopCategories.onlineShopItemType === -1 ||
                            category.onlineShopItemType === this.props.onlineShopCategories.onlineShopItemType)
                    ) {
                        categories.push({ ...category, isRecipe: false });
                    }
                });
            }
        });
        this.setState({
            categories: categories,
        });
    }

    resetAndOpenMenus(itemType = null) {
        const newExpandedMenus = {
            shop: false,
            recipes: false,
        };
        if (itemType !== null) {
            if (itemType === 0) {
                newExpandedMenus.shop = true;
            } else if (itemType === 1) {
                newExpandedMenus.recipes = true;
            }
        }
        this.setState({ expandedMenus: newExpandedMenus });
    }

    toggleMenu(menuKey) {
        this.setState((prevState) => ({
            expandedMenus: {
                ...prevState.expandedMenus,
                [menuKey]: !prevState.expandedMenus[menuKey],
            },
        }));
    }

    renderMenuItem(label, onPress, active = false, hasSubMenu = false, menuKey, external = false, url = "") {
        const { theme } = this.props;
        const { expandedMenus } = this.state;
        const isExpanded = expandedMenus[menuKey];
        return (
            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                {external ? (
                    <Pressable
                        style={{
                            ...StyleSheet.flatten(mobileMenuStyle.menuItem),
                            borderBottomColor: getContrastColor(theme.colors.primary),
                        }}
                    >
                        <ExternalLink
                            url={url}
                            style={{
                                ...StyleSheet.flatten(mobileMenuStyle.menuItemText),
                                color: getContrastColor(theme.colors.primary),
                            }}
                            textStyle={{ color: getContrastColor(theme.colors.primary) }}
                        >
                            {label}
                        </ExternalLink>
                    </Pressable>
                ) : (
                    <Pressable
                        onPress={onPress}
                        style={{
                            ...StyleSheet.flatten(mobileMenuStyle.menuItem),
                            borderBottomColor: getContrastColor(theme.colors.primary),
                        }}
                    >
                        <Text
                            style={{
                                ...StyleSheet.flatten(mobileMenuStyle.menuItemText),
                                color: getContrastColor(theme.colors.primary),
                            }}
                        >
                            {active ? <u>{label}</u> : label}
                        </Text>
                    </Pressable>
                )}
                {hasSubMenu && (
                    <Pressable
                        onPress={() => this.toggleMenu(menuKey)}
                        style={{
                            position: "relative",
                            top: "-8px",
                        }}
                    >
                        <Ionicons
                            name={isExpanded ? "chevron-up" : "chevron-down"}
                            size={20}
                            color={getContrastColor(theme.colors.primary)}
                        />
                    </Pressable>
                )}
            </View>
        );
    }

    renderSubMenuItem(category, onlineShopItemType) {
        const { theme } = this.props;
        const handlePress = () => {
            this.resetAndOpenMenus(onlineShopItemType);
            store.dispatch(setCurrentCategory(category));
            store.dispatch(setOnlineShopItemType(onlineShopItemType));
            this.props.onBackdropPress();
        };

        return (
            <Pressable
                key={category.categoriesId + "_" + category.url + "_" + category.isRecipe}
                style={{
                    ...StyleSheet.flatten(mobileMenuStyle.subMenuItem),
                    borderBottomColor: getContrastColor(theme.colors.primary),
                }}
            >
                <Link
                    to={
                        "/category/" +
                        category.seoName +
                        "/" +
                        category.categoriesId +
                        "/" +
                        encodeURIComponent(category.url)
                    }
                    onClick={handlePress}
                    style={{ textDecorationLine: "none" }}
                >
                    <Text
                        style={{
                            ...StyleSheet.flatten(mobileMenuStyle.menuItemText),
                            color: getContrastColor(theme.colors.primary),
                        }}
                    >
                        {category.name}
                    </Text>
                </Link>
            </Pressable>
        );
    }
    slideIn() {
        Animated.timing(this.widthValue, {
            toValue: 0,
            duration: 300,
            useNativeDriver: true,
        }).start();
    }

    slideOut() {
        Animated.timing(this.widthValue, {
            toValue: -200,
            duration: 300,
            useNativeDriver: true,
        }).start();
    }

    handleLoginPress() {
        this.props.toggleLoginPopup();
        this.props.onBackdropPress();
    }

    handleLogoutPress() {
        this.props.logout();
        this.props.onBackdropPress();
    }

    navigate(e, route) {
        this.resetAndOpenMenus();
        this.props.onBackdropPress(e);
        this.props.history.push(route);
    }

    render() {
        const { open, onBackdropPress, theme, settings, account, showCookieButton, location } = this.props;
        const { pathname } = location;
        const { showRecipes } = this.state;

        const slideAnimation = {
            transform: [
                {
                    translateX: this.widthValue,
                },
            ],
        };

        var filteredCategoriesCat0 = [];
        var filteredCategoriesCat1 = [];

        if (this.state.categories) {
            const itemTypes = [0, 1];

            itemTypes.forEach((itemType) => {
                this.state.categories.forEach((category) => {
                    var filteredOnlineShopItems = filterOnlineShopItems(
                        category.OnlineShopItems,
                        this.props.settings.searchText
                    );
                    if (filteredOnlineShopItems.length > 0 && itemType === category.onlineShopItemType) {
                        if (itemType === 0) {
                            filteredCategoriesCat0.push(category);
                        } else if (itemType === 1) {
                            filteredCategoriesCat1.push(category);
                        }
                    }
                });
            });
        }

        const mobileMenuStyle = {};

        return (
            <Portal>
                {open && (
                    <Animated.View
                        style={[
                            {
                                ...StyleSheet.flatten(mobileMenuStyle.container),
                                height: "100vh",
                                zIndex: 1,
                                position: "fixed",
                                padding: 10,
                                backgroundColor: theme.colors.primary,
                                width: 200,
                            },
                            slideAnimation,
                        ]}
                    >
                        <View style={{ marginTop: 15 }}>
                            <Text
                                style={{
                                    ...StyleSheet.flatten(mobileMenuStyle.mobileNavTitle),
                                    marginBottom: 25,
                                    fontSize: 20,
                                    color: getContrastColor(theme.colors.primary),
                                }}
                            >
                                {theme.title}
                            </Text>
                            {this.renderMenuItem(
                                "Startseite",
                                (e) => this.navigate(e, "/"),
                                this.props.onlineShopCategories.onlineShopItemType === -1,
                                false
                            )}
                            {this.renderMenuItem(
                                "Shop",
                                (e) => this.navigate(e, "/shop"),
                                this.props.onlineShopCategories.onlineShopItemType === 0,
                                true,
                                "shop"
                            )}

                            {this.state.expandedMenus["shop"] &&
                                Array.isArray(filteredCategoriesCat0) &&
                                filteredCategoriesCat0.map((category) => this.renderSubMenuItem(category, 0))}

                            {showRecipes &&
                                this.renderMenuItem(
                                    "Rezepte",
                                    (e) => this.navigate(e, "/recipes"),
                                    this.props.onlineShopCategories.onlineShopItemType === 1,
                                    true,
                                    "recipes"
                                )}
                            {this.state.expandedMenus["recipes"] &&
                                showRecipes &&
                                Array.isArray(filteredCategoriesCat1) &&
                                filteredCategoriesCat1.map((category) => this.renderSubMenuItem(category, 1))}

                            {account.customer && showRecipes && (
                                <>
                                    {this.renderMenuItem("Wochenplan", (e) => this.navigate(e, "/wochenplan"), false)}
                                    <Pressable
                                        style={{
                                            ...StyleSheet.flatten(mobileMenuStyle.menuItem),
                                            borderBottomColor: getContrastColor(theme.colors.primary),
                                        }}
                                    >
                                        <a
                                            style={{
                                                color: getContrastColor(theme.colors.primary),
                                                textDecorationLine: "none",
                                                marginBottom: 20,
                                            }}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                            href={(() => {
                                                let cookingMonitorUrl = this.props.settings.store.urlBasePath;
                                                if (!cookingMonitorUrl.startsWith("https://")) {
                                                    cookingMonitorUrl = "https://" + cookingMonitorUrl;
                                                }
                                                return (
                                                    cookingMonitorUrl +
                                                    "guest/allCookingProcessesCustomer/" +
                                                    account.customer.customerUid +
                                                    "/p/"
                                                );
                                            })()}
                                        >
                                            <Text
                                                style={[
                                                    mobileMenuStyle.menuItemText,
                                                    { color: getContrastColor(theme.colors.primary) },
                                                ]}
                                            >
                                                Kochmonitor
                                            </Text>
                                        </a>
                                    </Pressable>
                                    {this.renderMenuItem(
                                        "Einkaufsliste",
                                        (e) => this.navigate(e, "/shoppingList"),
                                        false
                                    )}
                                </>
                            )}
                            {this.renderMenuItem(
                                "Impressum",
                                (e) => this.navigate(e, "/impressum"),
                                pathname === "/impressum",
                                false
                            )}
                            {this.renderMenuItem(
                                "Datenschutz",
                                (e) => this.navigate(e, "/datenschutz"),
                                pathname === "/datenschutz",
                                false
                            )}
                            {this.renderMenuItem(
                                "Disclaimer",
                                (e) => this.navigate(e, "/disclaimer"),
                                pathname === "/disclaimer",
                                false
                            )}
                            {this.renderMenuItem(
                                "AGB",
                                (e) => this.navigate(e, "/allgemeinegeschaeftsbedingungen"),
                                pathname === "/allgemeinegeschaeftsbedingungen",
                                false
                            )}
                               {this.renderMenuItem(
                                "Versandkosten",
                                (e) => this.navigate(e, "/versandkosten"),
                                pathname === "/versandkosten",
                                false
                            )}
                            {settings.store.customFooterLinks &&
                                settings.store.customFooterLinks.map((link, index) =>
                                    this.renderMenuItem(link.label, null, false, false, null, true, link.path)
                                )}
                            {Platform.OS === "web" && showCookieButton && (
                                <button
                                    className="showCookieConsentDesktopButton showCookieConsentDesktopButtonMobile"
                                    data-cc="showPreferences"
                                    onClick={() => CookieConsent.showPreferences()}
                                    type="button"
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                        color: getContrastColor(theme.colors.primary),
                                    }}
                                >
                                    <Icon source="cookie" color={getContrastColor(theme.colors.primary)} size={14} />
                                    <span>Cookie Einstellungen</span>
                                </button>
                            )}
                        </View>
                    </Animated.View>
                )}
                {open && (
                    <Pressable onPress={onBackdropPress} style={StyleSheet.absoluteFill}>
                        <View
                            nativeID="mobileMenuBackground"
                            style={{ ...StyleSheet.flatten(styles.backdrop), backgroundColor: theme.colors.backdrop }}
                        ></View>
                    </Pressable>
                )}
            </Portal>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, onlineShopCategories } = state;
    return { settings, account, onlineShopCategories };
}

export default connect(mapStateToProps)(withRouter(withTheme(withLocation(MobileMenu))));
