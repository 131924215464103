const { StyleSheet } = require("react-native");

export const styles = StyleSheet.create({
    addToWeeklyPlanDialogSelect: {
        fontFamily: "regular",
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
    },
    addToWeeklyPlanDialogSelectLabel: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 15,
    },
    addToWeeklyPlanDialogButton: {
        paddingHorizontal: 15,
        paddingVertical: 2,
        marginLeft: 5,
    },
    addToWeeklyPlanArticleName: {
        flex: 1,
        flexWrap: "wrap",
        fontWeight: "bold",
        fontSize: 18,
    },
});
