import React, { useState } from "react";
import { View } from "react-native";
import { DataTable } from "react-native-paper";

export default function Additives(data) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    let additives = data.data;
    const numberOfRows = additives ? additives.length : 0;

    return (
        <View style={{ width: "100%", backgroundColor: windowWidth > 800 ? "inherit" : "#dddddd" }}>
            <DataTable>
                {(!additives || additives.length === 0) && (
                    <DataTable.Row style={{ cursor: "default", borderBottomWidth: 0 }}>
                        <DataTable.Cell style={{ cursor: "default", borderBottomWidth: 0 }}>Keine</DataTable.Cell>
                    </DataTable.Row>
                )}
                {additives && numberOfRows > 0 && (
                    <>
                        {additives.map((item, index) => {
                            const isLastRow = index === numberOfRows - 1;
                            const rowStyle = {
                                borderBottomWidth: numberOfRows === 1 || isLastRow ? 0 : 1,
                                cursor: "default",
                            };

                            return (
                                <DataTable.Row key={index} style={rowStyle}>
                                    <DataTable.Cell style={{ cursor: "default" }}>{item.name}</DataTable.Cell>
                                </DataTable.Row>
                            );
                        })}
                    </>
                )}
            </DataTable>
        </View>
    );
}
