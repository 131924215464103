import React, { Component } from "react";
import { View } from "react-native";

import { withRouter } from "../../../routers/routing";
import DetailsRow from "./DetailsRow";

class ArticleDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            articleDetailsRows: null,
            isDesktop: false,
        };
    }

    render() {
        const { articleDetailsRows, isDesktop, windowWidth } = this.props;
        return (
            <View
                nativeID="articleDetails"
                style={{ marginBottom: 50, marginLeft: isDesktop ? 0 : 30, marginRight: isDesktop ? 0 : 30 }}
            >
                {articleDetailsRows && (
                    <View nativeID="articleDetailsContainer">
                        {articleDetailsRows.map((articleDetailsRow) => (
                            <DetailsRow
                                key={articleDetailsRow.articleDetailsRowsId}
                                articleDetailsRow={articleDetailsRow}
                                windowWidth={windowWidth}
                            />
                        ))}
                    </View>
                )}
            </View>
        );
    }
}

export default withRouter(ArticleDetails);
