import React, { Component } from "react";
import { Button, Dialog, IconButton, Portal, Text, withTheme } from "react-native-paper";
import { View } from "react-native";
import { getContrastColor } from "../../shared/helpers";
import { withRouter } from "../../routers/routing";
import { styles } from "../../shared/styles";

class CookingMonitorDialog extends Component {
    handleOpenMonitor = () => {
        const { cookingMonitorUrl } = this.props;
        window.open(cookingMonitorUrl, "_blank");
        this.props.toggleOverlay();
    };

    render() {
        const { theme, visible, toggleOverlay } = this.props;
        return (
            <Portal>
                <View style={{ position: "fixed", width: "100%", height: "100%" }}>
                    <Dialog
                        visible={visible}
                        onDismiss={toggleOverlay}
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor: theme.colors.surface,
                        }}
                        dismissable={false}
                    >
                        <IconButton
                            icon="close"
                            size={24}
                            onPress={toggleOverlay}
                            style={[styles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                            iconColor={getContrastColor(theme.colors.primary)}
                            rippleColor="transparent"
                        />
                        <Dialog.Title style={[styles.dialogHeadline, { paddingRight: 40 }]}>
                            <Text>Kochmonitor öffnen</Text>
                        </Dialog.Title>
                        <Dialog.Content>
                            <Text>Der Kochprozess wurde erfolgreich angelegt.</Text>
                            <Text>Möchten Sie den Kochmonitor öffnen?</Text>
                        </Dialog.Content>
                        <Dialog.Actions>
                            <View style={styles.dialogButtonContainerVertical}>
                                <Button
                                    textColor={getContrastColor(theme.colors.primary)}
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                        flex: 1,
                                        marginRight: "2%",
                                        marginBottom: 0,
                                    }}
                                    uppercase={false}
                                    onPress={this.handleOpenMonitor}
                                >
                                    Kochmonitor öffnen
                                </Button>
                            </View>
                        </Dialog.Actions>
                    </Dialog>
                </View>
            </Portal>
        );
    }
}

export default withRouter(withTheme(CookingMonitorDialog));
