import React, { useEffect, useState } from "react";
import { View, Dimensions } from "react-native";
import { Text, ActivityIndicator, DataTable } from "react-native-paper";
import { getSettings } from "../../store/actions/settingsActions";
import { useDispatch, useSelector } from "react-redux";
import { ShippingCostsStyles as styles } from "./ShippingCostsStyles";
import { styles as sharedStyles } from "../../shared/styles";
import { tabletBreakpoint } from "../../shared/helpers";
import { List } from "react-native-paper";

function formatCurrency(value) {
    return ` ${Number(value).toFixed(2).replace(".", ",")} €`;
}

function ShippingCosts({ windowWidth }) {
    const settings = useSelector((state) => state.settings.store);
    const [isMobile, setIsMobile] = useState(windowWidth < tabletBreakpoint);
    const isDesktop = !isMobile && windowWidth >= 1024;

    useEffect(() => {
        setIsMobile(windowWidth <= tabletBreakpoint);
    }, [windowWidth]);

    const formatCurrency = (value) => {
        return value > 0 ? `${Number(value).toFixed(2).replace(".", ",")} €` : "-";
    };

    if (settings === null || settings === undefined) {
        return <ActivityIndicator size="large" />;
    }

    if (!settings || !settings.countries) {
        return <Text>Keine Versandinformationen verfügbar.</Text>;
    }

    console.log("ismobile", isMobile);

    return (
        <View style={[isDesktop ? sharedStyles.desktopContainerWrapper : null, { padding: !isDesktop ? 20 : null }]}>
            <Text style={styles.title}>Versandkosten</Text>
            <Text style={styles.subtitle}>Hier finden Sie Informationen zu unseren Versandkosten:</Text>
            {isMobile ? (
                settings.countries.map((item) => (
                    <List.Accordion key={item.countryId.toString()} title={item.name}>
                        {item.deliveryFee > 0 && (
                            <List.Item
                                title={`Kostenlos ab: ${
                                    item.deliveryFreeAt
                                        ? Number(item.deliveryFreeAt).toFixed(2).replace(".", ",")
                                        : "0,00 €"
                                }`}
                            />
                        )}
                        <List.Item
                            title={
                                item.deliveryFee
                                    ? `Lieferkosten: ${Number(item.deliveryFee).toFixed(2).replace(".", ",")} €`
                                    : "0,00 $"
                            }
                        />
                        {item.minOrderValue > 0 && (
                            <List.Item title={`Mindestbestellwert: ${formatCurrency(item.minOrderValue)}`} />
                        )}
                        {!settings.dynamicMinOrderCharge && (
                            <List.Item
                                title={`Mindermengenaufschlag: ${
                                    item.deliveryFee > 0
                                        ? Number(item.deliveryFee).toFixed(2).replace(".", ",")
                                        : "0,00 $"
                                }`}
                            />
                        )}
                    </List.Accordion>
                ))
            ) : (
                <DataTable style={styles.table}>
                    <DataTable.Header>
                        <DataTable.Title textStyle={styles.tableHeader}>Land</DataTable.Title>
                        <DataTable.Title textStyle={styles.tableHeader}>Kostenlos ab</DataTable.Title>
                        <DataTable.Title textStyle={styles.tableHeader}>Lieferkosten</DataTable.Title>
                        <DataTable.Title textStyle={styles.tableHeader}>Mindestbestellwert</DataTable.Title>
                        {!settings.dynamicMinOrderCharge && (
                            <DataTable.Title textStyle={styles.tableHeader}>Mindermengenaufschlag</DataTable.Title>
                        )}
                    </DataTable.Header>

                    {settings.countries.map((item) => (
                        <DataTable.Row key={item.countryId.toString()}>
                            <DataTable.Cell>{item.name}</DataTable.Cell>
                            <DataTable.Cell>{formatCurrency(item.deliveryFreeAt)}</DataTable.Cell>
                            <DataTable.Cell>{Number(item.deliveryFee).toFixed(2).replace(".", ",")} €</DataTable.Cell>
                            <DataTable.Cell>{formatCurrency(item.minOrderValue)}</DataTable.Cell>
                            {!settings.dynamicMinOrderCharge && (
                                <DataTable.Cell>
                                    {Number(item.deliveryFee).toFixed(2).replace(".", ",")} €
                                </DataTable.Cell>
                            )}
                        </DataTable.Row>
                    ))}
                </DataTable>
            )}
            {settings.dynamicMinOrderCharge && (
                <Text style={styles.mindermengenaufschlag}>
                    {"Mindermengenaufschlag: Differenz zu Mindestbestellwert"}
                </Text>
            )}
        </View>
    );
}

export default ShippingCosts;
