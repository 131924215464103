import { StyleSheet } from "react-native";

const styleDesktop = {
    logo: {
        height: "80%",
        width: 100,
        cursor: "pointer",
    },
    appbar: {
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        paddingLeft: 30,
        zIndex: 1100,
        display: "flex",
    },
    appsubbar: {
        width: "100%",
        position: "fixed",
        top: 64,
        height: 40,
        left: 0,
        zIndex: 1000,
        boxShadow: "rgb(0 0 0 / 24%) 0px 3px 4px",
    },
    appcategoriesbar: {
        width: "100%",
        position: "fixed",
        top: 96,
        left: 0,
        zIndex: 1000,
        boxShadow: "rgb(0 0 0 / 24%) 0px 3px 4px",
    },
    menu: {
        flexDirection: "row",
        marginLeft: 20,
        justifyContent: "end",
        alignItems: "center",
    },
    submenu: {
        flexDirection: "row",
        marginLeft: 20,
        justifyContent: "end",
        alignItems: "center",
    },
    drawerButton: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    links: {
        flexDirection: "row",
        alignItems: "center",
    },
    link: {
        display: "flex",
        flexDirection: "column",
        textDecorationLine: "none",
        display: "flex",
        marginRight: 10,
    },
    buttons: {
        flexDirection: "row",
        alignItems: "center",
    },
    buttonIcons: {
        marginRight: 10,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    buttonsText: {
        fontSize: 12,
        marginTop: 3,
    },
    searchActiveTextInput: {
        width: "auto",
        height: 40,
        fontSize: 14,
        flex: 1,
    },
    searchInactiveTextInput: {
        flex: 1,
        fontSize: 14,
        backgroundColor: "transparent",
        width: 0,
        height: 0,
    },
    searchActiveView: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        borderWidth: 1,
        borderRadius: 3,
        height: 35,
        maxWidth: "40%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    searchInactiveView: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
};

const styleMobile = {
    appbarMobile: {
        ...styleDesktop.appbar,
        paddingLeft: 20,
        paddingRight: 20,
    },
    logoMobile: {
        height: "100%",
        width: 30,
        cursor: "pointer",
    },
    searchActiveViewMobile: {
        ...styleDesktop.searchActiveView,
        marginLeft: "auto",
        marginRight: "auto",
        justifyContent: "stretch",
        maxWidth: "80%",
        minWidth: "50%",
    },
    searchActiveViewTablet: {
        ...styleDesktop.searchActiveView,
        justifyContent: "stretch",
        maxWidth: "50%",
        minWidth: "50%",
    },
};

export const headerStyles = StyleSheet.create({ ...styleDesktop, ...styleMobile });
