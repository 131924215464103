import React, { useState, useEffect } from "react";
import { Dimensions, View, StyleSheet, Platform } from "react-native";
import { Checkbox, Card, Text, withTheme } from "react-native-paper";
import { Link } from "../../../routers/routing";
import { customerDataStyles } from "../CustomerDataStyles";
import CustomInputField from "../../../helpers/inputField/CustomInputField";
import CustomDropdown from "../../../helpers/inputField/CustomDropdown";
import CustomerCheckoutAddAddress from "../CustomerCheckoutAddAddress";
import TitleRadioButtons from "./TitleRadioButtons";
import { getContrastColor, desktopBreakpoint } from "../../../shared/helpers";

const FormElements = ({
    settings,
    props,
    account,
    addresses,
    windowWidth,
    theme,
    keycloak,
    refreshAddresses,
    setDatenschutzIsChecked,
    datenschutzIsChecked,
}) => {
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(0);
    const [selectedBillingAddress, setSelectedBillingAddress] = useState(0);

    return settings.customerData.fields.map((field, index) => {
        const error = props.errors.hasOwnProperty(field.id) && props.errors[field.id];

        const { customer } = account;

        const isBillingAddressAttribute = [
            "billingTitle",
            "billingFirstName",
            "billingLastName",
            "billingStreet",
            "billingHousenumber",
            "billingZipCode",
            "billingCity",
            "billingCountry",
        ].includes(field.id);

        const shouldDisplayField =
            (customer && field.showWhenLoggedIn) ||
            (!customer && field.showWhenNotLoggedIn && !isBillingAddressAttribute) ||
            (!customer && isBillingAddressAttribute && props.values["useDifferentBillingAddress"]) ||
            field.type === "useDifferentBillingAddress";

        if (!shouldDisplayField) {
            return null;
        }

        if (field.type === "text") {
            const fieldId = isBillingAddressAttribute ? `billing-${field.id}` : field.id;

            return (
                <View
                    key={field.id}
                    id={field.id}
                    style={
                        windowWidth <= desktopBreakpoint
                            ? customerDataStyles.fieldFormFullRow
                            : customerDataStyles.fieldFormSplitRow
                    }
                >
                    <CustomInputField
                        key={index}
                        id={fieldId}
                        label={field.placeholder}
                        disabled={field.disabled}
                        onChange={props.handleChange(field.id)}
                        value={props.values[field.id] || ""}
                        error={!!props.errors[field.id]}
                        helperText={props.errors[field.id] || ""}
                    />
                </View>
            );
        }

        if (field.id === "country" || field.id === "billingCountry") {
            const availableCountries = settings.store.countries || [];
            const sortedCountries = [...availableCountries].sort((a, b) => a.name.localeCompare(b.name));
            const isSingleCountry = sortedCountries.length === 1;

            const defaultCountry = sortedCountries.find((country) => country.isDefault);

            return (
                <View
                    key={field.id}
                    id={field.id}
                    style={
                        windowWidth <= desktopBreakpoint
                            ? customerDataStyles.fieldFormFullRow
                            : customerDataStyles.fieldFormSplitRow
                    }
                >
                    <CustomDropdown
                        label="Land"
                        error={props.errors[field.id] ? true : false}
                        helperText={props.errors[field.id] ? props.errors[field.id] : ""}
                        onChange={(value) => props.setFieldValue(field.id, value)}
                        items={sortedCountries.map((country) => ({
                            label: country.name,
                            value: country.countryId,
                        }))}
                        value={props.values[field.id] || (defaultCountry ? defaultCountry.countryId : "")}
                        placeholder="Wählen Sie ein Land"
                        disabled={isSingleCountry}
                    />
                </View>
            );
        }

        if (field.type === "password") {
            return (
                <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                    <CustomInputField
                        key={index}
                        type="password"
                        label={field.placeholder}
                        disabled={field.disabled}
                        onChange={props.handleChange(field.id)}
                        value={props.values[field.id] || ""}
                        error={!!props.errors[field.id]}
                        helperText={props.errors[field.id] || ""}
                    />
                </View>
            );
        }

        if (field.type === "textarea") {
            return (
                <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                    <CustomInputField
                        key={index}
                        multiline
                        minRows={5}
                        label={field.placeholder}
                        disabled={field.disabled}
                        onChange={props.handleChange(field.id)}
                        value={props.values[field.id] || ""}
                        error={!!props.errors[field.id]}
                        helperText={props.errors[field.id] || ""}
                    />
                </View>
            );
        }

        if (field.type === "printBillCheckbox") {
            return (
                <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                    <Checkbox.Item
                        label="Rechnung drucken"
                        status={props.values[field.id] ? "checked" : "unchecked"}
                        onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                        uncheckedColor={theme.colors.primary}
                        color={getContrastColor(theme.colors.primary)}
                        style={[
                            customerDataStyles.checkbox,
                            {
                                backgroundColor: props.values[field.id] ? theme.colors.primary : theme.colors.surface,
                                borderColor: theme.colors.primary,
                            },
                        ]}
                        labelStyle={{
                            color: props.values[field.id]
                                ? getContrastColor(theme.colors.primary)
                                : getContrastColor(theme.colors.surface),
                        }}
                        position="leading"
                        rippleColor="transparent"
                        mode="android"
                    />
                </View>
            );
        }

        if (field.id === "acceptTerms") {
            return (
                <View key={field.id} id={field.id} style={customerDataStyles.fieldRow}>
                    <Checkbox.Item
                        label="Ich akzeptiere die AGB und Datenschutzbestimmungen"
                        status={props.values[field.id] ? "checked" : "unchecked"}
                        onPress={() => {
                            props.setFieldValue(field.id, !props.values[field.id]);
                            setDatenschutzIsChecked(!datenschutzIsChecked);
                        }}
                        uncheckedColor={theme.colors.primary}
                        color={getContrastColor(theme.colors.primary)}
                        style={[
                            customerDataStyles.checkbox,
                            {
                                backgroundColor: props.values[field.id] ? theme.colors.primary : theme.colors.surface,
                                borderColor: theme.colors.primary,
                            },
                        ]}
                        labelStyle={{
                            color: props.values[field.id]
                                ? getContrastColor(theme.colors.primary)
                                : getContrastColor(theme.colors.surface),
                        }}
                        position="leading"
                        rippleColor="transparent"
                        mode="android"
                    />
                    {props.errors[field.id] && (
                        <Text style={[customerDataStyles.helperText, { color: theme.colors.error }]}>
                            Bitte akzeptieren Sie die AGB und Datenschutzbestimmungen
                        </Text>
                    )}
                    <Text style={customerDataStyles.helperText}>
                        Unsere Datenschutzbestimmungen / AGB können Sie
                        {Platform.OS === "web" ? (
                            <a
                                style={{
                                    ...StyleSheet.flatten(customerDataStyles.customLink),
                                    color: theme.colors.text,
                                }}
                                href="/datenschutz"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                hier
                            </a>
                        ) : (
                            <Link
                                style={{
                                    ...StyleSheet.flatten(customerDataStyles.customLink),
                                    color: theme.colors.text,
                                }}
                                to="/datenschutz"
                            >
                                hier
                            </Link>
                        )}
                        finden
                    </Text>
                </View>
            );
        }

        if (field.id === "title" || field.id === "billingTitle") {
            return <TitleRadioButtons key={field.id} field={field} props={props} error={error} customer={customer} />;
        }

        if (field.type === "useDifferentBillingAddress") {
            return (
                <View key={field.id} id={field.id} style={[customerDataStyles.fieldRow, { marginTop: 15 }]}>
                    <Checkbox.Item
                        label="Abweichende Rechnungsadresse"
                        status={props.values[field.id] ? "checked" : "unchecked"}
                        onPress={() => props.setFieldValue(field.id, !props.values[field.id])}
                        uncheckedColor={theme.colors.primary}
                        color={getContrastColor(theme.colors.primary)}
                        style={[
                            customerDataStyles.checkbox,
                            {
                                backgroundColor: props.values[field.id] ? theme.colors.primary : theme.colors.surface,
                                borderColor: theme.colors.primary,
                            },
                        ]}
                        labelStyle={{
                            color: props.values[field.id]
                                ? getContrastColor(theme.colors.primary)
                                : getContrastColor(theme.colors.surface),
                        }}
                        position="leading"
                        rippleColor="transparent"
                        mode="android"
                    />
                </View>
            );
        }

        if (field.type === "selectAddress") {
            const tempAddresses = addresses.length > 0 ? addresses : [];

            const sortedDeliveryAddresses = [...tempAddresses].sort((a, b) => {
                if (a.isDefaultDeliveryAddress) return -1;
                if (b.isDefaultDeliveryAddress) return 1;
                return a.addressId - b.addressId;
            });

            if (sortedDeliveryAddresses && sortedDeliveryAddresses.length > 0 && !props.values["deliveryAddress"]) {
                props.setFieldValue("deliveryAddress", JSON.stringify(sortedDeliveryAddresses[0]));
            }

            const sortedBillingAddresses = [...tempAddresses].sort((a, b) => {
                if (a.isDefaultBillingAddress) return -1;
                if (b.isDefaultBillingAddress) return 1;
                return a.addressId - b.addressId;
            });

            if (sortedBillingAddresses && sortedBillingAddresses.length > 0 && !props.values["billingAddress"]) {
                props.setFieldValue("billingAddress", JSON.stringify(sortedBillingAddresses[0]));
            }
            return (
                customer &&
                (field.id === "deliveryAddress" || props.values["useDifferentBillingAddress"]) && (
                    <View key={field.id} id={field.id} style={{ width: "100%" }}>
                        <Text style={{ width: "100%", marginBottom: 15, marginLeft: 10, fontWeight: "bold" }}>
                            {field.id === "deliveryAddress" ? "Lieferadresse" : "Rechnungsadresse"}
                        </Text>
                        <View style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", width: "100%" }}>
                            {field.id === "deliveryAddress" &&
                                sortedDeliveryAddresses &&
                                sortedDeliveryAddresses.map((address, index) => {
                                    return (
                                        <Card
                                            onPress={() => {
                                                props.setFieldValue(field.id, JSON.stringify(address));
                                                setSelectedDeliveryAddress(index);
                                            }}
                                            style={{
                                                backgroundColor:
                                                    selectedDeliveryAddress === index
                                                        ? theme.colors.primary
                                                        : theme.colors.surface,
                                                borderColor: theme.colors.primary,
                                                borderWidth: "1px",
                                                borderStyle: "solid",
                                                width: windowWidth <= 768 ? "calc(100% - 10px)" : "calc(33% - 10px)",
                                                display: "flex",
                                                margin: 5,
                                                cursor: "pointer",
                                            }}
                                            key={index}
                                        >
                                            <Card.Title
                                                titleStyle={{
                                                    color:
                                                        selectedDeliveryAddress === index
                                                            ? getContrastColor(theme.colors.primary)
                                                            : theme.colors.onSurface,
                                                }}
                                                title={address.firstName + " " + address.lastName}
                                            />
                                            <Card.Content>
                                                <Text
                                                    style={{
                                                        color:
                                                            selectedDeliveryAddress === index
                                                                ? getContrastColor(theme.colors.primary)
                                                                : theme.colors.onSurface,
                                                    }}
                                                >
                                                    {address.street +
                                                        " " +
                                                        address.housenumber +
                                                        "\n" +
                                                        address.zip +
                                                        " " +
                                                        address.city +
                                                        "\n" +
                                                        address.country +
                                                        "\n\n" +
                                                        (address.company ? address.company : "") +
                                                        "\n" +
                                                        (address.addressDetails ? address.addressDetails : "")}
                                                </Text>
                                            </Card.Content>
                                        </Card>
                                    );
                                })}
                            {field.id === "billingAddress" &&
                                sortedBillingAddresses &&
                                sortedBillingAddresses.map((address, index) => {
                                    return (
                                        <Card
                                            onPress={() => {
                                                props.setFieldValue(field.id, JSON.stringify(address));
                                                setSelectedBillingAddress(index);
                                            }}
                                            style={{
                                                backgroundColor:
                                                    selectedBillingAddress === index
                                                        ? theme.colors.primary
                                                        : theme.colors.surface,
                                                borderColor: theme.colors.primary,
                                                borderWidth: "1px",
                                                borderStyle: "solid",
                                                width: windowWidth <= 768 ? "calc(100% - 10px)" : "calc(33% - 10px)",
                                                display: "flex",
                                                margin: 5,
                                                cursor: "pointer",
                                            }}
                                            key={index}
                                        >
                                            <Card.Title
                                                titleStyle={{
                                                    color:
                                                        selectedBillingAddress === index
                                                            ? getContrastColor(theme.colors.primary)
                                                            : theme.colors.onSurface,
                                                }}
                                                title={address.firstName + " " + address.lastName}
                                            />
                                            <Card.Content>
                                                <Text
                                                    style={{
                                                        color:
                                                            selectedBillingAddress === index
                                                                ? getContrastColor(theme.colors.primary)
                                                                : theme.colors.onSurface,
                                                    }}
                                                >
                                                    {address.street +
                                                        " " +
                                                        address.housenumber +
                                                        "\n" +
                                                        address.zip +
                                                        " " +
                                                        address.city +
                                                        "\n" +
                                                        address.country +
                                                        "\n\n" +
                                                        (address.company ? address.company : "") +
                                                        "\n" +
                                                        (address.addressDetails ? address.addressDetails : "")}
                                                </Text>
                                            </Card.Content>
                                        </Card>
                                    );
                                })}
                            <CustomerCheckoutAddAddress update={refreshAddresses} keycloak={keycloak} />
                        </View>
                    </View>
                )
            );
        }

        return "";
    });
};

export default withTheme(FormElements);
