import React from "react";
import { View } from "react-native";

import ColumnContent from "./ColumnContent";
import { desktopBreakpoint } from "../../../shared/helpers";

export default function RowColumn({ width, articleDetailsContents, windowWidth }) {
    return (
        <View
            nativeID="rowColumn"
            style={{ width: windowWidth >= desktopBreakpoint ? width : "100%", paddingHorizontal: 10 }}
        >
            {articleDetailsContents
                .sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
                .map((articleDetailsContent) => {
                    return (
                        <ColumnContent
                            key={articleDetailsContent.articleDetailsContentsId}
                            size={articleDetailsContent.size}
                            {...articleDetailsContent}
                        />
                    );
                })}
        </View>
    );
}
