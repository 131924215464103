import React, { useEffect, useRef } from "react";
import { Animated } from "react-native";

export default function FadeInOut({ children = null, duration = 1000, show = false }) {
    const fadeAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: show ? 1 : 0,
            duration: duration,
            useNativeDriver: true,
        }).start();
    }, [fadeAnim, show, duration]);

    return (
        <Animated.View
            style={{
                opacity: fadeAnim,
            }}
        >
            {children}
        </Animated.View>
    );
}
