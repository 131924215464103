import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import { persistReducer } from "redux-persist";
import AsyncStorage from '@react-native-async-storage/async-storage';
import rootReducer from "./reducers/rootReducer";

const persistConfig = {
    key: "root",
    storage: AsyncStorage,
    blacklist: ["articles"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));

export const persistor = persistStore(store);
