import React, { Component, PureComponent } from "react";
import { Dimensions, findNodeHandle, Image, Pressable, StyleSheet, View } from "react-native";
import { Avatar, Icon, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "../../routers/routing";
import Config from "../../../Config";
import { getAltAndTitleByGuidFilenName } from "../../store/actions/onlineShopCategoriesActions";
import { SET_SEARCHTEXT } from "../../store/actions/settingsActions";
import { store } from "../../store/store";
import { ShopStyles } from "./ShopStyles";
import { getContrastColor, tabletBreakpoint } from "../../shared/helpers";

const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

class ShopItem extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
            translateX: 0,
            alt: "",
            title: "",
        };

        this.ref = React.createRef();
        this.goToDetails = this.goToDetails.bind(this);
        this.toggleHoverIn = this.toggleHoverIn.bind(this);
        this.toggleHoverOut = this.toggleHoverOut.bind(this);
        this.addShopHeaderItem = this.addShopHeaderItem.bind(this);
        this.cookRecipe = this.cookRecipe.bind(this);
    }

    goToDetails(item) {
        const { history, mobile } = this.props;
        store.dispatch({ type: SET_SEARCHTEXT, searchText: "" });
        history.push(
            "/ProductDetails/" +
                item.seoName +
                "/" +
                item.onlineShopItemType +
                "/" +
                item.id +
                "/" +
                encodeURIComponent(this.props.url)
        );
    }

    toggleHoverIn() {
        this.ref.measureLayout(findNodeHandle(this.props.settings.appRef), (fx, fy, width, height, px, py) => {
            // console.log("Component width is: " + width);
            // console.log("Component height is: " + height);
            // console.log("X offset to frame: " + fx);
            // console.log("Y offset to frame: " + fy);
            // console.log("X offset to page: " + px);
            // console.log("Y offset to page: " + py);
            // console.log(px, width, px + width * 1.4 - (width * 1.4 - 300) / 2, Dimensions.get("window").width);

            if (px - (width * 1.4 - 300) / 2 < 50) {
                this.setState({
                    translateX: 50 - (px - (width * 1.4 - 300) / 2),
                    hovered: true,
                });
            } else if (px + width * 1.4 - (width * 1.4 - 300) / 2 > Dimensions.get("window").width - 50) {
                this.setState({
                    translateX: Dimensions.get("window").width - 70 - (px + width * 1.4 - (width * 1.4 - 300) / 2),
                    hovered: true,
                });
            } else {
                this.setState({
                    hovered: true,
                    translateX: 0,
                });
            }
            // this.setState({
            //     portalX: px,
            //     portalY: py,
            //     portalWidth: width,
            //     portalHeight: height,
            // });
        });
        this.props.setHoveredItem(this.props.item.item.id);
    }

    // componentDidMount() {
    //     if (this.props.item.item.guidFileName !== null) {
    //         store
    //             .dispatch(getAltAndTitleByGuidFilenName(this.props.url, this.props.item.item.guidFileName))
    //             .then((response) => {
    //                 if (response) {
    //                     this.setState({
    //                         alt: response.alt ? response.alt : "",
    //                         title: response.title ? response.title : "",
    //                     });
    //                 }
    //             });
    //     }
    // }

    toggleHoverOut() {
        this.setState({
            hovered: false,
            translateX: 0,
        });
        this.props.setHoveredItem(undefined);
    }

    addShopHeaderItem(item) {
        if (item.onlineShopItemType === 0) {
            this.props.showAddArticleToCartPopup(item);
        } else {
            if (!this.props.account || !this.props.account.customer) {
                this.props.toggleLoginPopup();
            } else {
                this.props.showAddRecipeToWeeklyPlanPopup(item);
            }
        }
    }

    cookRecipe(item) {
        if (!this.props.account || !this.props.account.customer) {
            this.props.toggleLoginPopup();
        } else {
            this.props.cookRecipe(item);
        }
    }

    render() {
        const theme = this.props.theme;
        const mobile = this.props.mobile;
        const isCarousel = this.props.isCarousel;
        const width = Dimensions.get("window").width;
        let item = this.props.item.item;
        const config = new Config();
        var hasMultipleSizes = "";
        var price = 0;
        var price100 = 0;
        if (item && item.onlineShopItemType === 0) {
            const sortedArticleSizes = item.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);
            const articleSize = sortedArticleSizes.find((a) => a.Prices.length > 0);
            if (articleSize && this.props.settings.store.orderTypes && this.props.settings.store.priceGroups) {
                const articlePrice = articleSize.Prices.find(
                    (p) =>
                        p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                        p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                );
                price = articlePrice ? articlePrice.price1 : 0;
                price100 = (price / articleSize.portionSize) * 100;
            }
            if (item.articleSizes.length > 1) {
                hasMultipleSizes = "ab ";
            }
        }
        let icons = [];
        if (item && item.articleIcons) {
            try {
                icons = JSON.parse(item.articleIcons);
            } catch (error) {
                console.error("error", error);
            }
        }

        return (
            item && (
                <View
                    style={[
                        this.state.hovered && this.props.hoveredItem === item.id ? ShopStyles.CardWrapperHover : {},
                        mobile ? { height: isCarousel ? 280 : "auto" } : {},
                    ]}
                    clickable={false}
                >
                    <Pressable
                        style={
                            mobile
                                ? ShopStyles.CardMobile
                                : this.state.hovered && this.props.hoveredItem === item.id
                                ? [
                                      ShopStyles.HoveredCard,
                                      {
                                          transform: [{ translateX: this.state.translateX }, { scale: 1.4 }],
                                          backgroundColor: theme.colors.background,
                                      },
                                  ]
                                : ShopStyles.Card
                        }
                        onPress={(event) => {
                            undefined;
                        }}
                        ref={(view) => {
                            this.ref = view;
                        }}
                        onHoverIn={() => this.toggleHoverIn()}
                        onHoverOut={() => this.toggleHoverOut()}
                    >
                        {item.soldOut && (
                            <View
                                style={[
                                    ShopStyles.CardSoldOutBadge,
                                    width < tabletBreakpoint ? ShopStyles.CardSoldOutBadgeMobile : {},
                                    {
                                        backgroundColor: theme.colors.primary,
                                    },
                                ]}
                            >
                                <Text
                                    style={[
                                        width < tabletBreakpoint ? ShopStyles.CardSoldOutBadgeTextMobile : {},
                                        { color: getContrastColor(theme.colors.primary) },
                                    ]}
                                >
                                    Ausverkauft
                                </Text>
                            </View>
                        )}
                        {icons.length > 0 && !mobile && (
                            <View
                                style={[
                                    ShopStyles.CardArticleIconsWrapper,
                                    {
                                        backgroundColor: theme.colors.primary,
                                        bottom: this.state.hovered && this.props.hoveredItem === item.id ? 77 : 0,
                                    },
                                ]}
                            >
                                {icons.map((icon, index) => (
                                    <Image key={index} source={{ uri: icon }} style={ShopStyles.ArticleIconsItem} />
                                ))}
                            </View>
                        )}

                        <Avatar.Icon
                            size={mobile ? 35 : 50}
                            color={getContrastColor(theme.colors.primary)}
                            style={{
                                ...StyleSheet.flatten(ShopStyles.Icon),
                                backgroundColor: theme.colors.primary,
                                padding: 4,
                            }}
                            icon={item.onlineShopItemType == 0 ? "cart-outline" : "pot-steam"}
                        />
                        <Link
                            to={
                                "/ProductDetails/" +
                                item.seoName +
                                "/" +
                                item.onlineShopItemType +
                                "/" +
                                item.id +
                                "/" +
                                encodeURIComponent(this.props.url)
                            }
                            style={{
                                height: "100%",
                                width: "100%",
                                zIndex: -1,
                                objectFit: "cover",
                                objectPosition: "center center",
                            }}
                        >
                            <img
                                alt={this.state.alt ? this.state.alt : item.name}
                                title={this.state.title ? this.state.title : item.name}
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    zIndex: -1,
                                    objectFit: "cover",
                                    objectPosition: "center center",
                                }}
                                src={
                                    item.onlineShopTileImage
                                        ? config.imageStorageBlobURL + item.onlineShopTileImage
                                        : item.guidFileName
                                        ? config.imageStorageBlobURL + item.guidFileName
                                        : "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/c57c74ca-9b1a-4e86-9f13-d1131d8dd670.png"
                                }
                            />
                        </Link>
                        {this.state.hovered && this.props.hoveredItem === item.id && item.rating > 0 && (
                            <Text
                                size={mobile ? 35 : 50}
                                color={getContrastColor(theme.colors.primary)}
                                style={{
                                    ...StyleSheet.flatten(ShopStyles.Rating),
                                    backgroundColor: theme.colors.primary,
                                    color: getContrastColor(theme.colors.primary),
                                    fontSize: 12,
                                }}
                            >
                                <Avatar.Icon
                                    icon="star"
                                    size={22}
                                    style={{ backgroundColor: "transparent" }}
                                    color={theme.colors.notification}
                                />
                                {Math.round(item.rating * 10) / 10}
                            </Text>
                        )}
                        {this.state.hovered && this.props.hoveredItem === item.id && (
                            <View
                                style={{
                                    ...StyleSheet.flatten(ShopStyles.ShopItemInfoArea),
                                    backgroundColor: theme.colors.primary,
                                }}
                            >
                                <View
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginRight: 10,
                                        alignItems: "center",
                                    }}
                                >
                                    <Text style={{ fontSize: 12, color: getContrastColor(theme.colors.primary) }}>
                                        {item.name}
                                    </Text>
                                    <View
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        {item.onlineShopItemType === 1 && (
                                            <Pressable
                                                onPress={(event) => {
                                                    event.stopPropagation();
                                                    this.cookRecipe(item);
                                                }}
                                                style={{
                                                    ...StyleSheet.flatten(ShopStyles.ShopItemInfoAreaIconButtonWrapper),
                                                    backgroundColor: theme.colors.primary,
                                                    borderColor: getContrastColor(theme.colors.primary),
                                                    marginRight: 5,
                                                }}
                                                onHoverIn={() => this.toggleHoverIn()}
                                                onHoverOut={() => this.toggleHoverIn()}
                                            >
                                                <Icon
                                                    source="pot-steam"
                                                    size={25}
                                                    color={getContrastColor(theme.colors.primary)}
                                                />
                                            </Pressable>
                                        )}
                                        {item.onlineShopItemType === 0 && (
                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "end",
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        fontSize: 22,
                                                        marginRight: 5,
                                                        color: getContrastColor(theme.colors.primary),
                                                    }}
                                                >
                                                    {hasMultipleSizes}
                                                    {formatter.format(price)}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: 10,
                                                        marginRight: 5,
                                                        marginTop: 5,
                                                        marginBottom: 5,
                                                        color: getContrastColor(theme.colors.primary),
                                                    }}
                                                >
                                                    ({formatter.format(price100)} / 100 g)
                                                </Text>
                                            </View>
                                        )}

                                        {item.onlineShopItemType === 1 || !item.soldOut ? (
                                            <Pressable
                                                onPress={(event) => {
                                                    event.stopPropagation();
                                                    this.addShopHeaderItem(item);
                                                }}
                                                style={{
                                                    ...StyleSheet.flatten(ShopStyles.ShopItemInfoAreaIconButtonWrapper),
                                                    backgroundColor: theme.colors.primary,
                                                    borderColor: getContrastColor(theme.colors.primary),
                                                }}
                                                onHoverIn={() => this.toggleHoverIn()}
                                                onHoverOut={() => this.toggleHoverIn()}
                                            >
                                                <Icon
                                                    source={item.onlineShopItemType === 0 ? "cart" : "calendar"}
                                                    size={25}
                                                    color={getContrastColor(theme.colors.primary)}
                                                />
                                            </Pressable>
                                        ) : null}
                                    </View>
                                </View>
                                {/* <View style={ShopStyles.ShopItemCategory}>
                                {item.categories.length > 0 && (
                                    <Text
                                        style={{
                                            textAlign: mobile ? "center" : "left",
                                            fontSize: mobile ? 9 : 10,
                                        }}
                                    >
                                        {item.categories.join(" | ")}
                                    </Text>
                                )}
                            </View> */}
                            </View>
                        )}
                    </Pressable>
                    {mobile && (
                        <Pressable
                            onPress={() => this.goToDetails(item)}
                            ref={(view) => {
                                this.ref = view;
                            }}
                        >
                            <View
                                style={{
                                    ...StyleSheet.flatten(ShopStyles.ShopItemInfoAreaMobile),
                                    backgroundColor: theme.colors.background,
                                }}
                            >
                                {icons.length > 0 ? (
                                    <View
                                        style={[
                                            ShopStyles.CardArticleIconsWrapperMobile,
                                            {
                                                backgroundColor: theme.colors.primary,
                                            },
                                        ]}
                                    >
                                        {icons.map((icon, index) => (
                                            <Image
                                                key={index}
                                                source={{ uri: icon }}
                                                style={ShopStyles.ArticleIconsItem}
                                            />
                                        ))}
                                    </View>
                                ) : (
                                    this.props.categoryHasIcons && <View style={ShopStyles.CardArticleNoIconsMobile} />
                                )}
                                <Text
                                    style={{
                                        ...StyleSheet.flatten(ShopStyles.ShopItemInfoAreaMobileTitle),
                                        color: getContrastColor(theme.colors.background),
                                    }}
                                    numberOfLines={2}
                                    ellipsizeMode="tail"
                                >
                                    {item.name}
                                </Text>
                                {item.onlineShopItemType === 0 && (
                                    <View
                                        style={{
                                            ...StyleSheet.flatten(ShopStyles.ShopItemInfoAreaMobilePriceWrapper),
                                        }}
                                    >
                                        <Text
                                            style={{
                                                ...StyleSheet.flatten(ShopStyles.ShopItemInfoAreaMobilePrice),
                                                color: getContrastColor(theme.colors.background),
                                            }}
                                        >
                                            {hasMultipleSizes}
                                            {formatter.format(price)}
                                        </Text>
                                        <Text
                                            style={{
                                                ...StyleSheet.flatten(ShopStyles.ShopItemInfoAreaMobileBasePrice),
                                                color: getContrastColor(theme.colors.background),
                                            }}
                                        >
                                            ({hasMultipleSizes}
                                            {formatter.format(price100)} / 100 g)
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </Pressable>
                    )}
                </View>
            )
        );
    }
}
function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}
export default connect(mapStateToProps)(withRouter(withTheme(ShopItem)));
