import { StyleSheet } from 'react-native';

export const ShippingCostsStyles = StyleSheet.create({
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',
		padding: 20,
		backgroundColor: '#f5f5f5',
		overflow: 'hidden'
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: 10,
		color: '#333',
		textAlign: 'center',
		marginTop: 20
	},
	subtitle: {
		fontSize: 18,
		fontWeight: 'bold',
		marginTop: 10,
		marginBottom: 5,
		color: '#555',
		textAlign: 'center'
	},
	tabel:{
		width: '100%',
		borderWidth: 1,
		borderColor: '#ddd',
		borderRadius: 8,
	
	},
	tabelHeader: {
		fontSize:14,
		fontWeight: 'bold',		
		textAlign: 'center',
		whiteSpace: 'nowrap'
	},
	mindermengenaufschlag: {
		fontSize: 18,
		paddingTop: 20,
		fontWeight: 'bold'
	}
});
