import React from "react";
import moment from "moment";
import { View, Pressable, Image, StyleSheet } from "react-native";
import { Text, Icon, IconButton } from "react-native-paper";
import { WeeklyPlanStyles } from "./WeeklyPlanStyles";
import Config from "../../../Config";
import { getContrastColor } from "../../shared/helpers";

const config = new Config();

export default function WeeklyPlanEntry({
    course,
    dayIndex,
    counter,
    customerCoursesId,
    toggleHoverIn,
    toggleHoverOut,
    deleteCookingProcess,
    editCookingProcess,
    selectWeeklyPlanEntry,
    currentMonday,
    toggleCourseIndex,
    toggleDayIndex,
    theme,
}) {
    if (!course) {
        return (
            <Pressable
                style={{
                    ...StyleSheet.flatten(WeeklyPlanStyles.Card),
                    backgroundColor: theme.colors.onSurface,
                    borderColor: theme.colors.accent,
                    backgroundColor: counter % 2 == 0 ? theme.colors.surface : theme.colors.background,
                    alignItems: "center",
                    justifyContent: "center",
                }}
                key={customerCoursesId + "_" + dayIndex}
                onHoverIn={() => toggleHoverIn(dayIndex, counter)}
                onHoverOut={() => toggleHoverOut()}
                onPress={() => {
                    var current = new moment(currentMonday);
                    selectWeeklyPlanEntry(customerCoursesId, current.add(dayIndex - 1, "days"));
                }}
            >
                {toggleCourseIndex === counter && toggleDayIndex === dayIndex && (
                    <View
                        style={{
                            ...StyleSheet.flatten(WeeklyPlanStyles.CardAddButtonWrapper),
                            backgroundColor: theme.colors.primary,
                        }}
                    >
                        <Icon size={25} color={getContrastColor(theme.colors.primary)} source="plus" />
                    </View>
                )}
            </Pressable>
        );
    }
    var maxCookingProcessCountByCourse = getMaxCookingProcessCountByCourse(course);
    var cookingProcesses = course.filter((cookingProcess) => {
        if (moment(cookingProcess.dayToCook).day() === dayIndex) {
            return cookingProcess;
        }
    });
    let cookingProcessSlots = [];
    var count = 0;
    if (cookingProcesses.length > 0) {
        cookingProcesses.map((cookingProcess) => {
            // console.log(cookingProcess);
            count++;
            cookingProcessSlots.push(
                <View
                    key={cookingProcess.cookingProcessId}
                    style={{
                        ...StyleSheet.flatten(WeeklyPlanStyles.Card),
                    }}
                >
                    <IconButton
                        size={20}
                        iconColor={getContrastColor(theme.colors.primary)}
                        style={{
                            ...StyleSheet.flatten(WeeklyPlanStyles.DeleteIcon),
                            backgroundColor: theme.colors.primary,
                        }}
                        icon={"delete"}
                        onPress={() => deleteCookingProcess(cookingProcess)}
                    />
                    <IconButton
                        size={20}
                        iconColor={getContrastColor(theme.colors.primary)}
                        style={{
                            ...StyleSheet.flatten(WeeklyPlanStyles.EditIcon),
                            backgroundColor: theme.colors.primary,
                        }}
                        icon={"pencil"}
                        onPress={() => editCookingProcess(cookingProcess)}
                    />
                    <Image
                        style={{ height: "100%", width: "100%", zIndex: -1 }}
                        source={
                            cookingProcess.imageUrl
                                ? config.imageStorageBlobURL + cookingProcess.imageUrl
                                : "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/c57c74ca-9b1a-4e86-9f13-d1131d8dd670.png"
                        }
                    />
                    <Text
                        style={{
                            ...StyleSheet.flatten(WeeklyPlanStyles.Banner),
                            backgroundColor: theme.colors.primary,
                            color: getContrastColor(theme.colors.primary),
                        }}
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                    >
                        {cookingProcess.CookingProcessArticleSizes.length > 0
                            ? cookingProcess.CookingProcessArticleSizes[0].amount +
                              "x " +
                              cookingProcess.name +
                              " (" +
                              cookingProcess.CookingProcessArticleSizes[0].ArticleSize.Size.name +
                              ")"
                            : cookingProcess.name}
                    </Text>
                </View>
            );
        });
    }
    for (let i = count; i < maxCookingProcessCountByCourse; i++) {
        cookingProcessSlots.push(
            <Pressable
                style={{
                    ...StyleSheet.flatten(WeeklyPlanStyles.Card),
                    alignItems: "center",
                    justifyContent: "center",
                }}
                key={customerCoursesId + "_" + dayIndex + "_" + i}
                onHoverIn={() => toggleHoverIn(dayIndex, counter)}
                onHoverOut={() => toggleHoverOut()}
                onPress={() => {
                    var current = new moment(currentMonday);
                    selectWeeklyPlanEntry(customerCoursesId, current.add(dayIndex - 1, "days"));
                }}
            >
                {toggleCourseIndex === counter && toggleDayIndex === dayIndex && (
                    // <Icon size={25} color={getContrastColor(theme.colors.primary)} source="plus" />
                    <View
                        style={{
                            ...StyleSheet.flatten(WeeklyPlanStyles.CardAddButtonWrapper),
                            backgroundColor: theme.colors.primary,
                        }}
                    >
                        <Icon size={25} color={getContrastColor(theme.colors.primary)} source="plus" />
                    </View>
                )}
            </Pressable>
        );
    }
    return cookingProcessSlots;
}

function getMaxCookingProcessCountByCourse(course) {
    const usersByColor = course.reduce((acc, cookingProcess) => {
        // Group initialization
        if (!acc[moment(cookingProcess.dayToCook).day()]) {
            acc[moment(cookingProcess.dayToCook).day()] = [];
        }

        // Grouping
        acc[moment(cookingProcess.dayToCook).day()].push(cookingProcess);

        return acc;
    }, {});
    var count = 0;
    if (usersByColor[1]) {
        count = usersByColor[1].length;
    }
    if (usersByColor[2] && usersByColor[2].length > count) {
        count = usersByColor[2].length;
    }
    if (usersByColor[3] && usersByColor[3].length > count) {
        count = usersByColor[3].length;
    }
    if (usersByColor[4] && usersByColor[4].length > count) {
        count = usersByColor[4].length;
    }
    if (usersByColor[5] && usersByColor[5].length > count) {
        count = usersByColor[5].length;
    }
    if (usersByColor[6] && usersByColor[6].length > count) {
        count = usersByColor[6].length;
    }
    if (usersByColor[7] && usersByColor[7].length > count) {
        count = usersByColor[7].length;
    }
    return count;
}
