import React, { Component } from "react";
import { Dimensions, View } from "react-native";
import { ActivityIndicator, Appbar, Icon, IconButton, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "../../routers/routing";
import { footerStyles } from "./FooterStyle";
import Config from "../../../Config";
import { withHooksHOC, getContrastColor } from "../../shared/helpers";
import CustomMenu from "../../helpers/overlayMenu/CustomMenu";
import CustomMenuItem from "../../helpers/overlayMenu/CustomMenuItem";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            orderItemsCount: props.cart.orderItems.length,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleStorageChange = this.handleStorageChange.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
        window.addEventListener("storage", this.handleStorageChange);
        this.setState({ orderItemsCount: this.props.cart.orderItems.length });
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.updateDimensions);
        window.removeEventListener("storage", this.handleStorageChange);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cart.orderItems.length !== this.props.cart.orderItems.length) {
            this.setState({ orderItemsCount: this.props.cart.orderItems.length });
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    handleStorageChange(event) {
        if (event.key === "orderItems") {
            const orderItems = JSON.parse(event.newValue) || [];
            this.setState({ orderItemsCount: orderItems.length });
        }
    }

    handleLogin() {
        this.props.toggleLoginPopup();
    }

    handleLogout() {
        this.props.logout();
    }

    render() {
        const { theme, onMenuClick, account, onCartClick, authLoading } = this.props;
        const { orderItemsCount } = this.state;
        return (
            <React.Fragment>
                <Appbar.Header style={[footerStyles.appbar, { backgroundColor: theme.colors.primary }]}>
                    <View nativeID="buttons" style={footerStyles.buttons}>
                        <View style={footerStyles.buttonContainer}>
                            <IconButton
                                iconColor={getContrastColor(theme.colors.primary)}
                                style={footerStyles.button}
                                icon="menu"
                                onPress={() => onMenuClick()}
                            />
                            <Text
                                style={[
                                    footerStyles.buttonDescription,
                                    { color: getContrastColor(theme.colors.primary) },
                                ]}
                            >
                                Menü
                            </Text>
                        </View>
                        <View style={footerStyles.buttonContainer}>
                            <IconButton
                                iconColor={getContrastColor(theme.colors.primary)}
                                style={footerStyles.button}
                                icon="cart-outline"
                                onPress={() => onCartClick()}
                            />
                            <Text
                                style={[
                                    footerStyles.buttonDescription,
                                    { color: getContrastColor(theme.colors.primary) },
                                ]}
                            >
                                Warenkorb{orderItemsCount ? " (" + orderItemsCount + ")" : ""}
                            </Text>
                        </View>

                        {!account.customer && !authLoading ? (
                            <View style={footerStyles.buttonContainer}>
                                <IconButton
                                    iconColor={getContrastColor(theme.colors.primary)}
                                    style={footerStyles.button}
                                    icon="account-arrow-right-outline"
                                    onPress={() => this.handleLogin()}
                                />
                                <Text
                                    style={[
                                        footerStyles.buttonDescription,
                                        { color: getContrastColor(theme.colors.primary) },
                                    ]}
                                >
                                    Anmelden
                                </Text>
                            </View>
                        ) : authLoading ? (
                            <View>
                                <ActivityIndicator
                                    size="small"
                                    color={getContrastColor(theme.colors.primary)}
                                    style={{ marginRight: "5px" }}
                                />
                            </View>
                        ) : (
                            <View style={{ zIndex: 2000 }}>
                                <CustomMenu
                                    anchor={
                                        <View style={footerStyles.buttonContainer}>
                                            <IconButton
                                                iconColor={getContrastColor(theme.colors.primary)}
                                                style={footerStyles.button}
                                                icon="account-outline"
                                            />
                                            <Text
                                                style={[
                                                    footerStyles.buttonDescription,
                                                    { color: getContrastColor(theme.colors.primary) },
                                                ]}
                                            >
                                                Account
                                            </Text>
                                        </View>
                                    }
                                    direction="up"
                                    menuAlignment="center"
                                    menuItemBackgroundColor={theme.colors.accent}
                                    menuItemTextColor={getContrastColor(theme.colors.accent)}
                                >
                                    <CustomMenuItem
                                        onPress={() => this.props.history.push("/customer/profile/")}
                                        title="Kundendaten"
                                    />
                                    <CustomMenuItem
                                        onPress={() => this.props.history.push("/customer/addresses/")}
                                        title="Meine Adressen"
                                    />
                                    <CustomMenuItem onPress={() => this.handleLogout()} title="Logout" />
                                </CustomMenu>
                            </View>
                        )}
                    </View>
                </Appbar.Header>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, cart } = state;
    return { settings, account, cart };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(Footer))));
