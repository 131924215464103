export function handleResponse(response) {
    if (200 <= response.status && response.status < 400) {
        return response
            .clone()
            .text()
            .then(function (text) {
                try {
                    let responseParsed = JSON.parse(text);

                    return responseParsed;
                } catch {
                    return response;
                }
            });
    } else {
        if (response.status === 404) {
        } else {
            var result = response.clone();
            response.json().then((json) => console.log(json));
            return result;
        }
    }
}
