import { StyleSheet } from "react-native";

export const cartStyles = StyleSheet.create({
    containerWrapper: {
        zIndex: 1,
        overflow: "hidden",
        position: "fixed",
        right: 0,
    },
    container: {
        flex: 1,
        margin: 5,
        minWidth: 320,
    },
    cartCloseButton: {
        alignItems: "center",
        borderRadius: 25,
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: 25,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 7,
        paddingTop: 5,
        marginHorizontal: 15,
    },
    cartCloseButtonText: {
        fontSize: 16,
        fontWeight: "bold",
        marginRight: 15,
    },
    cartCloseButtonIcon: {
        borderRadius: 25,
        padding: 5,
    },
    articleContainer: {
        flexGrow: 1,
        paddingHorizontal: 15,
        paddingVertical: 10,
    },
    emptyCartText: {
        marginHorizontal: 15,
        marginVertical: 20,
    },
    cartSelect: {
        paddingHorizontal: 7,
        paddingVertical: 4,
        borderRadius: 5,
        marginBottom: 10,
    },
    cartSelectLabel: {
        fontSize: 13,
        fontWeight: "bold",
    },
    cartItemCard: {
        borderRadius: 10,
        marginBottom: 15,
    },
    cartItemSize: {
        fontSize: 13,
    },
    cartItemCardContainer: {
        padding: 15,
        position: "relative",
    },
    cartItemCardContent: {
        paddingHorizontal: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingLeft: 0,
    },
    cartItemTitle: {
        fontSize: 14,
        fontWeight: "bold",
        marginBottom: 10,
        textAlign: "center",
    },
    cartItemAmountBox: {
        flexDirection: "column",
        justifyContent: "space-between",
    },
    cartItemPrice: {
        fontSize: 15,
        fontWeight: "bold",
    },
    cartItemCloseButton: {
        top: -10,
        right: -10,
        width: 23,
        height: 23,
        position: "absolute",
    },
    articleInformationContainer: {
        width: "100%",
    },
    articleInformationLabel: {
        flexDirection: "row",
        alignItems: "center",
    },
    articleInformationInfos: {
        flexDirection: "row",
        alignItems: "center",
    },
    articleInformationRow: {
        width: "100%",
        marginBottom: 10,
    },
    cartSummaryWrapper: {
        alignItems: "flex-end",
        borderTopStyle: "solid",
        borderTopWidth: 2,
        display: "flex",
        flexDirection: "column",
        height: "auto",
        marginBottom: 20,
        paddingLeft: 15,
        paddingRight: 25,
        paddingVertical: 10,
    },
    cartButton: {
        marginHorizontal: 15,
        marginBottom: 25,
    },
});
