import { LinearGradient } from "expo-linear-gradient";
import React, { Component, PureComponent } from "react";
import { Dimensions, FlatList, ScrollView, View } from "react-native";
import { Paragraph, withTheme } from "react-native-paper";
import Config from "../../../Config";
import { getCurrentRecommendationsForOnlineShop } from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import { ShopStyles } from "./ShopStyles";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Ionicons } from "@expo/vector-icons";
import ShopHeaderItem from "./ShopHeaderItem";

const config = new Config();

class ShopHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            scrollingPosition: 0,
            url: "",
            scrollBegin: false,
        };
        this.ref = React.createRef();
        this.goToNext = this.goToNext.bind(this);
        this.goToPrevious = this.goToPrevious.bind(this);
        this.interval = window.setInterval(this.goToNext, 8000);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        store.dispatch(getCurrentRecommendationsForOnlineShop(config.backendHost)).then((result) => {
            this.setState({
                isLoading: false,
            });
        });
    }

    goToNext() {
        var currentRecommendations =
            this.props.onlineShopCategories.currentRecommendations &&
            this.props.onlineShopCategories.currentRecommendations.OnlineShopItems
                ? this.props.onlineShopCategories.currentRecommendations.OnlineShopItems.filter(
                      (currentRecommendation) =>
                          this.props.onlineShopItemType === -1 ||
                          this.props.onlineShopItemType === currentRecommendation.onlineShopItemType
                  )
                : undefined;

        if (currentRecommendations) {
            let newIndex =
                this.state.scrollingPosition < currentRecommendations.length - 1 ? this.state.scrollingPosition + 1 : 0;
            if (this.ref && this.ref.props) {
                this.ref.scrollToIndex({
                    animated: true,
                    index: newIndex,
                });
            }

            this.setState({
                scrollingPosition: newIndex,
            });
        }
    }

    goToPrevious() {
        var currentRecommendations =
            this.props.onlineShopCategories.currentRecommendations &&
            this.props.onlineShopCategories.currentRecommendations.OnlineShopItems
                ? this.props.onlineShopCategories.currentRecommendations.OnlineShopItems.filter(
                      (currentRecommendation) =>
                          this.props.onlineShopItemType === -1 ||
                          this.props.onlineShopItemType === currentRecommendation.onlineShopItemType
                  )
                : undefined;

        if (currentRecommendations) {
            let newIndex =
                this.state.scrollingPosition > 0 ? this.state.scrollingPosition - 1 : currentRecommendations.length - 1;

            this.ref.scrollToIndex({
                animated: true,
                index: newIndex,
            });

            this.setState({
                scrollingPosition: newIndex,
            });
        }
    }

    render() {
        const theme = this.props.theme;
        const mobile = this.props.mobile;
        var currentRecommendations =
            this.props.onlineShopCategories.currentRecommendations &&
            this.props.onlineShopCategories.currentRecommendations.OnlineShopItems
                ? this.props.onlineShopCategories.currentRecommendations.OnlineShopItems.filter(
                      (currentRecommendation) =>
                          this.props.onlineShopItemType === -1 ||
                          this.props.onlineShopItemType === currentRecommendation.onlineShopItemType
                  )
                : undefined;

        return (
            <React.Fragment>
                {!this.state.isLoading && !currentRecommendations && (
                    <View style={{ height: 300, alignItems: "center", justifyContent: "center" }}>
                        <Paragraph style={{ fontSize: 20 }}>
                            Der Onlineshop ist aktuell nicht erreichbar. Bitte versuchen Sie es später erneut.
                        </Paragraph>
                    </View>
                )}
                {currentRecommendations && currentRecommendations.length > 0 && (
                    <ScrollView showsHorizontalScrollIndicator={false}>
                        <View style={mobile ? ShopStyles.HeaderContainerMobile : ShopStyles.HeaderContainer}>
                            {!mobile && currentRecommendations.length > 1 && (
                                <Ionicons
                                    name="chevron-back"
                                    color={theme.colors.primary}
                                    style={{ marginLeft: 10, zIndex: 9999 }}
                                    size={50}
                                    onPress={this.goToPrevious}
                                />
                            )}
                            <View style={ShopStyles.HeaderListContainer}>
                                <FlatList
                                    onScrollToIndexFailed={(info) => {
                                        const wait = new Promise((resolve) => setTimeout(resolve, 500));
                                        wait.then(() => this.ref.scrollToIndex({ index: info.index, animated: true }));
                                    }}
                                    pagingEnabled={mobile ? true : false}
                                    style={{
                                        width: "100vw",
                                        height: 126,
                                        flex: 1,
                                        height: "95%",
                                        marginBottom: 10,
                                    }}
                                    keyExtractor={(item, index) => index.toString()}
                                    horizontal={true}
                                    extraData={this.props.onlineShopItemType}
                                    data={[...currentRecommendations, currentRecommendations[0]]}
                                    ref={(c) => {
                                        this.ref = c;
                                    }}
                                    onScroll={({ nativeEvent }) => {
                                        const { x } = nativeEvent.contentOffset;
                                        if (x === currentRecommendations.length * Dimensions.get("window").width)
                                            this.ref.scrollToOffset({ x: 0, animated: false });
                                    }}
                                    showsHorizontalScrollIndicator={false}
                                    renderItem={(item) => (
                                        <ShopHeaderItem
                                            item={item}
                                            mobile={mobile}
                                            showAddRecipeToWeeklyPlanPopup={this.props.showAddRecipeToWeeklyPlanPopup}
                                            showAddArticleToCartPopup={this.props.showAddArticleToCartPopup}
                                            backendUrl={this.props.onlineShopCategories.currentRecommendations.url}
                                        />
                                    )}
                                />
                            </View>
                            {!mobile && currentRecommendations.length > 1 && (
                                <Ionicons
                                    style={{ marginRight: 10, zIndex: 9999 }}
                                    name="chevron-forward"
                                    color={theme.colors.primary}
                                    size={50}
                                    onPress={() => this.goToNext()}
                                />
                            )}
                        </View>
                    </ScrollView>
                )}
                {currentRecommendations && currentRecommendations.length === 0 && (
                    <View style={{ height: mobile ? 90 : 250 }}></View>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { onlineShopCategories, account } = state;
    return { onlineShopCategories, account };
}

export default compose(connect(mapStateToProps), withRouter, withTheme)(ShopHeader);
