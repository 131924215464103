import { Ionicons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component, PureComponent } from "react";
import { ImageBackground, Pressable, ScrollView, View } from "react-native";
import { Button, Colors, IconButton, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";

import Config from "../../../Config";
import { checkIfArticleSizeHasPrices, getContrastColor } from "../../shared/helpers";
import { SET_SEARCHTEXT } from "../../store/actions/settingsActions";
import { store } from "../../store/store";
import { ShopStyles } from "./ShopStyles";
import { Link } from "../../routers/routing";

class ShopHeaderItem extends PureComponent {
    constructor(props) {
        super(props);

        this.goToDetails = this.goToDetails.bind(this);
        this.addShopHeaderItem = this.addShopHeaderItem.bind(this);
    }

    goToDetails(item) {
        const { history, mobile } = this.props;
        store.dispatch({ type: SET_SEARCHTEXT, searchText: "" });
        history.push(
            "/ProductDetails/" +
                item.seoName +
                "/" +
                item.onlineShopItemType +
                "/" +
                item.id +
                "/" +
                encodeURIComponent(this.props.backendUrl)
        );
    }

    addShopHeaderItem(item) {
        if (item.onlineShopItemType === 0) {
            this.props.showAddArticleToCartPopup(item);
        } else {
            this.props.showAddRecipeToWeeklyPlanPopup(item);
        }
    }

    checkAddButtonVisibility(recommendation) {
        if (recommendation.onlineShopItemType === 1) return true;
        if (
            recommendation.articleSizes.find((articleSize) => {
                return checkIfArticleSizeHasPrices(
                    articleSize,
                    this.props.settings.store.orderTypes.orderTypesId,
                    this.props.settings.store.priceGroups.priceGroupsId
                );
            })
        )
            return true;
        return false;
    }

    handleButtonPress = (event, action) => {
        event.preventDefault();
        event.stopPropagation();
        action();
    };

    render() {
        const { theme, history, item, mobile } = this.props;
        let recommendation = item.item;
        const config = new Config();
        return (
            <Pressable key={item.index} onPress={() => undefined}>
                <View style={mobile ? ShopStyles.HeaderItemContainerMobile : ShopStyles.HeaderItemContainer}>
                    <Link
                        to={
                            "/ProductDetails/" +
                            recommendation.seoName +
                            "/" +
                            recommendation.onlineShopItemType +
                            "/" +
                            recommendation.id +
                            "/" +
                            encodeURIComponent(this.props.backendUrl)
                        }
                        style={{
                            height: "100%",
                            width: "100%",
                            zIndex: -1,
                            objectFit: "cover",
                            objectPosition: "center center",
                            textDecorationLine: "none",
                        }}
                    >
                        <ImageBackground
                            style={mobile ? ShopStyles.HeaderImageMobile : ShopStyles.HeaderImage}
                            source={{
                                uri: recommendation.onlineShopHeaderImage
                                    ? config.imageStorageBlobURL + recommendation.onlineShopHeaderImage
                                    : recommendation.guidFileName
                                    ? config.imageStorageBlobURL + recommendation.guidFileName
                                    : "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/c57c74ca-9b1a-4e86-9f13-d1131d8dd670.png",
                            }}
                        >
                            <LinearGradient
                                colors={["transparent", "transparent", theme.colors.background]}
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    height: "100%",
                                }}
                                start={{ x: 1, y: 0.5 }}
                                end={{ x: 0, y: 0.5 }}
                            />
                            <LinearGradient
                                colors={["transparent", "transparent", theme.colors.background]}
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    top: 0,

                                    height: "100%",
                                }}
                            />
                            <View style={mobile ? ShopStyles.HeaderLeftMobile : ShopStyles.HeaderLeft}>
                                <Text
                                    style={[
                                        {
                                            fontSize: 32,
                                            marginBottom: 10,
                                            textAlign: mobile ? "center" : "left",
                                            wordWrap: "break-word",
                                            hyphens: "auto",
                                        },
                                    ]}
                                >
                                    {recommendation.name}
                                </Text>
                                <ScrollView showsVerticalScrollIndicator={false} style={{ height: 200 }}>
                                    <Text style={mobile ? ShopStyles.descriptionMobile : ShopStyles.description}>
                                        {recommendation.description}
                                        {recommendation.categories.length > 0 && (
                                            <Text
                                                style={[
                                                    {
                                                        textAlign: mobile ? "center" : "left",
                                                        fontSize: mobile ? 12 : 14,
                                                    },
                                                ]}
                                            >
                                                {recommendation.categories.join(" | ")}
                                            </Text>
                                        )}
                                    </Text>
                                    <View
                                        style={mobile ? ShopStyles.HeaderCategoryMobile : ShopStyles.HeaderCategory}
                                    ></View>
                                </ScrollView>

                                <View style={mobile ? ShopStyles.HeaderButtonsMobile : ShopStyles.HeaderButtons}>
                                    {this.props.account.customer && this.checkAddButtonVisibility(recommendation) && (
                                        <Button
                                            textColor={getContrastColor(theme.colors.primary)}
                                            style={{
                                                backgroundColor: theme.colors.primary,
                                                marginRight: mobile ? 0 : 10,
                                                marginBottom: mobile ? 10 : 0,
                                            }}
                                            icon={recommendation.onlineShopItemType === 0 ? "cart" : "calendar"}
                                            onPress={(e) =>
                                                this.handleButtonPress(e, () => {
                                                    this.addShopHeaderItem(recommendation);
                                                })
                                            }
                                        >
                                            {recommendation.onlineShopItemType === 0
                                                ? "In den Warenkorb"
                                                : "In den Wochenplan"}
                                        </Button>
                                    )}
                                    <Button
                                        textColor={getContrastColor(theme.colors.primary)}
                                        style={{
                                            backgroundColor: theme.colors.primary,
                                            marginRight: mobile ? 0 : 10,
                                            marginBottom: mobile ? 10 : 0,
                                        }}
                                        icon={"information-outline"}
                                        onPress={(e) =>
                                            this.handleButtonPress(e, () => {
                                                this.goToDetails(recommendation);
                                            })
                                        }
                                    >
                                        Details
                                    </Button>
                                </View>
                            </View>
                        </ImageBackground>
                    </Link>
                </View>
            </Pressable>
        );
    }
}

function mapStateToProps(state) {
    const { onlineShopCategories, account, settings } = state;
    return { onlineShopCategories, account, settings };
}

export default compose(connect(mapStateToProps), withRouter, withTheme)(ShopHeaderItem);
