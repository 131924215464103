import { HttpTransportType, HubConnectionBuilder, LogLevel, signalMiddleware, withCallbacks } from "redux-signalr";
import { store } from "../../store/store";
import Config from "../../../Config";
import { getOrderStatus, setOrderStatus } from "../actions/orderStatusActions";

const config = new Config();

export const connection = new HubConnectionBuilder()
    .configureLogging(LogLevel.Debug)
    .withUrl(config.backendHost + "/OrderStatusHub", {
        skipNegotiation: false,
        transport: HttpTransportType.WebSockets,
    })
    .build();

connection.on("SetOrderStatus", (result) => {
    store.dispatch(setOrderStatus(JSON.parse(result)));
});
const callbacks = withCallbacks()
    .add("SetOrderStatus", (orderStatus) => (dispatch, getState, invoke) => {
        dispatch(setOrderStatus(JSON.parse(orderStatus)));
    })
    .add("UpdateOrderStatus", () => (dispatch, getState) => {
        let state = getState();
        dispatch(getOrderStatus(state.orderStatus.uid));
    });

export const orderStatus = signalMiddleware({
    callbacks,
    connection,
    shouldConnectionStartImmediately: false,
});
