import React, { Component } from "react";
import { getImprint } from "../../store/actions/settingsActions";
import { store } from "../../store/store";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { desktopBreakpoint, withHooksHOC } from "../../shared/helpers";
import { setOnlineShopItemType } from "../../store/actions/onlineShopCategoriesActions";
import ContentText from "./ArticleDetails/Contents/ContentText";
import { View } from "react-native-web";
import { styles } from "../../shared/styles";

class Imprint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imprint: undefined,
        };
    }

    componentDidMount() {
        store.dispatch(setOnlineShopItemType(-2));
        store
            .dispatch(getImprint())
            .then((result) => {
                this.setState({
                    imprint: result.impressum,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const { windowWidth } = this.props;
        const isDesktop = windowWidth >= desktopBreakpoint;
        return (
            <View>
                {this.state.imprint && (
                    <View
                        style={[isDesktop ? styles.desktopContainerWrapper : null, { padding: !isDesktop ? 20 : null }]}
                    >
                        <ContentText text={this.state.imprint} />
                    </View>
                )}
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(Imprint)));
