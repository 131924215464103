import { Linking, Pressable, Platform } from "react-native";
import { Text } from "react-native-paper";

const ExternalLink = ({ url, children, style, textStyle, id }) => {
    const handlePress = () => {
        if (Platform.OS === "web") {
            window.open(url, "_blank", "noopener,noreferrer");
        } else {
            Linking.openURL(url);
        }
    };

    return (
        <Pressable nativeID={id} onPress={handlePress} style={style}>
            <Text style={textStyle}>{children}</Text>
        </Pressable>
    );
};

export default ExternalLink;
