import React from "react";
import { View } from "react-native";
import NumberFormat from "react-number-format";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function ArticleList(props) {
    return (
        <View style={orderTrackingStyles.articleList}>
            <View>
                <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                    Artikel
                </Text>
            </View>
            <View>
                {props.cartJson.map((cartItem, index) => {
                    return (
                        <View
                            key={index}
                            style={
                                cartItem.articleCount > 0
                                    ? orderTrackingStyles.cartRow
                                    : orderTrackingStyles.sumToPayRow
                            }
                        >
                            <View style={orderTrackingStyles.articleTitle}>
                                {cartItem.articleCount > 0 && (
                                    <Text style={orderTrackingStyles.text}>{" " + cartItem.articleCount}x</Text>
                                )}
                                <Text style={orderTrackingStyles.text}>{" " + cartItem.name}</Text>
                                {cartItem.comment && (
                                    <View style={orderTrackingStyles.comment}>
                                        <Text style={orderTrackingStyles.text}>{cartItem.comment}</Text>
                                    </View>
                                )}
                                {cartItem.articleCount > 1 && (
                                    <View style={orderTrackingStyles.priceSingle}>
                                        <NumberFormat
                                            value={cartItem.singleArticlePrice / 100}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            thousandSeparator="."
                                            suffix=" €"
                                            displayType="text"
                                            renderText={(value) => (
                                                <Text style={orderTrackingStyles.text}>{` (${value})`}</Text>
                                            )}
                                        />
                                    </View>
                                )}
                            </View>
                            <View>
                                <NumberFormat
                                    value={
                                        cartItem.articleCount > 1
                                            ? (cartItem.articleCount * cartItem.singleArticlePrice) / 100
                                            : cartItem.totalArticlePrice / 100
                                    }
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    thousandSeparator="."
                                    suffix=" €"
                                    displayType="text"
                                    renderText={(value) => <Text style={orderTrackingStyles.text}>{value}</Text>}
                                />
                            </View>
                        </View>
                    );
                })}
            </View>
        </View>
    );
}
