import React, { Component } from "react";
import { Button, Dialog, IconButton, Portal, Text, withTheme } from "react-native-paper";
import { Dimensions } from "react-native";
import { getContrastColor } from "../../shared/helpers";
import { withRouter } from "../../routers/routing";
import { styles } from "../../shared/styles";
import { View } from "react-native";

class UpdateUserInformationPopup extends Component {
    handlePopupConfirmed = () => {
        this.props.togglePopup();
        this.props.toggleCart();
        this.props.history.push("/customer/profile?redirectToOrderDetails=true");
    };

    render() {
        const { theme, windowHeight } = this.props;
        return (
            <Portal>
                <View style={{ position: "fixed", width: "100%", height: windowHeight }}>
                    <Dialog
                        visible={this.props.visible}
                        onDismiss={this.props.togglePopup}
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor: theme.colors.surface,
                        }}
                        dismissable={false}
                    >
                        <IconButton
                            icon="close"
                            size={24}
                            onPress={this.props.togglePopup}
                            style={[styles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                            iconColor={getContrastColor(theme.colors.primary)}
                            rippleColor="transparent"
                        />
                        <Dialog.Title style={[styles.dialogHeadline, { paddingRight: 40 }]}>
                            <Text>Benutzerdaten unvollständig</Text>
                        </Dialog.Title>
                        <Dialog.Content>
                            <Text>Bitte vervollständigen Sie zuerst Ihre Benutzerdaten.</Text>
                        </Dialog.Content>
                        <Dialog.Actions>
                            <View style={styles.dialogButtonContainerVertical}>
                                <Button
                                    textColor={getContrastColor(theme.colors.primary)}
                                    style={{
                                        backgroundColor: theme.colors.primary,
                                        flex: 1,
                                        marginRight: "2%",
                                        marginBottom: 0,
                                    }}
                                    uppercase={false}
                                    onPress={this.handlePopupConfirmed}
                                >
                                    Zum Profil
                                </Button>
                            </View>
                        </Dialog.Actions>
                    </Dialog>
                </View>
            </Portal>
        );
    }
}

export default withRouter(withTheme(UpdateUserInformationPopup));
