import React from "react";
import { View } from "react-native";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function CustomerInformations(props) {
    const { customer } = props;

    // Helper function to join name parts with optional title
    const formatName = (title, firstName, lastName) => {
        return `${title} ${firstName} ${lastName}`.trim();
    };

    return (
        <View>
            <View>
                <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                    Kundendaten
                </Text>
            </View>
            <View style={orderTrackingStyles.text}>
                {customer.deliveryCompany && <Text style={orderTrackingStyles.text}>{customer.deliveryCompany}</Text>}
                <Text style={orderTrackingStyles.text}>
                    {formatName(customer.deliveryTitle, customer.deliveryFirstName, customer.deliveryLastName)}
                </Text>
                {customer.deliveryAddressDetails && (
                    <Text style={orderTrackingStyles.text}>{customer.deliveryAddressDetails}</Text>
                )}
                <Text style={orderTrackingStyles.text}>
                    {customer.deliveryStreet} {customer.deliveryHousenumber}
                </Text>
                <Text style={orderTrackingStyles.text}>
                    {customer.deliveryZipcode} {customer.deliveryCity}
                </Text>
                {customer.deliveryCountry && <Text style={orderTrackingStyles.text}>{customer.deliveryCountry}</Text>}
                <Text style={orderTrackingStyles.text}>{customer.email}</Text>
                {customer.phoneNumbers && <Text style={orderTrackingStyles.text}>{customer.phoneNumbers}</Text>}
                {customer.comment && <Text style={orderTrackingStyles.text}>{customer.comment}</Text>}
            </View>
            {/* Billing address */}
            {customer.useDifferentBillingAddress && (
                <View style={orderTrackingStyles.billingAddress}>
                    <Text variant="headlineSmall" style={orderTrackingStyles.headline}>
                        Rechnungsadresse
                    </Text>
                    {customer.billingCompany && <Text style={orderTrackingStyles.text}>{customer.billingCompany}</Text>}

                    <Text style={orderTrackingStyles.text}>
                        {formatName(customer.billingTitle, customer.billingFirstName, customer.billingLastName)}
                    </Text>
                    {customer.billingAddressDetails && (
                        <Text style={orderTrackingStyles.text}>{customer.billingAddressDetails}</Text>
                    )}
                    <Text style={orderTrackingStyles.text}>
                        {customer.billingStreet} {customer.billingHousenumber}
                    </Text>
                    <Text style={orderTrackingStyles.text}>
                        {customer.billingZipcode} {customer.billingCity}
                    </Text>
                    {customer.billingCountry && <Text style={orderTrackingStyles.text}>{customer.billingCountry}</Text>}
                </View>
            )}
        </View>
    );
}
