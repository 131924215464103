import { SET_UID, SET_ORDER_STATUS } from "../actions/orderStatusActions";

const initialState = {
    uid: null,
    data: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_UID:
            return Object.assign({}, state, {
                uid: action.uid,
            });
        case SET_ORDER_STATUS:
            return Object.assign({}, state, {
                data: action.data,
            });
        default:
            return state;
    }
};
