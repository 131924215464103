import { Platform, StyleSheet } from "react-native";
import { Colors, useTheme } from "react-native-paper";

export const WeeklyPlanStyles = StyleSheet.create({
    container: {
        flex: 1,
    },
    desktop: {
        flex: 1,
        marginBottom: 50,
    },
    weekNavigation: {
        flexGrow: 0,
        flexShrink: 0,
        height: 75,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
    },
    Card: {
        minHeight: 100,
        flex: 1,
        borderWidth: "1px",
        overflow: "hidden",
    },
    CardAddButtonWrapper: {
        height: 50,
        width: 50,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
    },
    DeleteIcon: {
        position: "absolute",
        top: 0,
        left: 0,
        borderRadius: 0,
        borderBottomRightRadius: 8,
        margin: 0,
    },
    EditIcon: {
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: 0,
        borderBottomLeftRadius: 8,
        margin: 0,
    },
    fab: {
        position: "absolute",
        margin: 16,
        right: 0,
        bottom: 0,
    },
    Banner: {
        position: "absolute",
        bottom: 0,
        left: 0,
        fontSize: 12,
        padding: 5,
        width: "100%",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
    },
    BannerMobile: {
        position: "absolute",
        bottom: 50,
        left: -5,
        fontSize: 12,
        padding: 5,
        paddingLeft: 10,
        borderRadius: 6,
        maxWidth: 120,
    },
    weeklyPlanSelect: {
        fontFamily: "regular",
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
    },
    dayText: {
        fontFamily: "regular",
    },
});
