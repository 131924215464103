import {
    SET_ARTICLES,
    SET_CURRENT_CATEGORY,
    SET_CURRENT_RECOMMENDATIONS,
    SET_ONLINESHOPITEMTYPE,
    SET_RECIPES,
    SET_RECOMMENDATIONS,
} from "../../store/actions/onlineShopCategoriesActions";

const initialState = {
    currentRecommendations: { OnlineShopItems: [] },
    currentCategory: {},
    onlineShopItemType: -1,
};

export default (state = initialState, action) => {
    if (action.err) console.log(action.type + "wirft" + action.err);
    switch (action.type) {
        case SET_ARTICLES:
            return { ...state, ["articles_" + action.url]: [action.payload] };
        case SET_RECIPES:
            return { ...state, ["recipes_" + action.url]: [action.payload] };
        case SET_RECOMMENDATIONS:
            return { ...state, ["recommendations_" + action.url]: [action.payload] };
        case SET_CURRENT_RECOMMENDATIONS:
            return { ...state, currentRecommendations: action.payload };
        case SET_CURRENT_CATEGORY:
            return { ...state, currentCategory: action.payload };
        case SET_ONLINESHOPITEMTYPE:
            return { ...state, onlineShopItemType: action.onlineShopItemType };

        default:
            return state;
    }
};
