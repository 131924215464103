export const ADD_ORDER_ITEM = "ADD_ORDER_ITEM";

export function addOrderItem(orderItem) {
    return {
        type: ADD_ORDER_ITEM,
        orderItem,
    };
}

export const REMOVE_ORDER_ITEM = "REMOVE_ORDER_ITEM";

export function removeOrderItem(orderItem) {
    return {
        type: REMOVE_ORDER_ITEM,
        orderItem,
    };
}

export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM";

export function updateOrderItem(orderItem) {
    return {
        type: UPDATE_ORDER_ITEM,
        orderItem,
    };
}

export const REMOVE_ALL_ORDER_ITEMS = "REMOVE_ALL_ORDER_ITEMS";

export function removeAllOrderItems() {
    return {
        type: REMOVE_ALL_ORDER_ITEMS,
    };
}

export const LOAD_CART_ITEMS = "LOAD_CART_ITEMS";

export function loadCartItems() {
    return {
        type: LOAD_CART_ITEMS,
    };
}
