import { showSessionExpiredDialog } from "../../store/actions/uiActions";
import { store } from "../../store/store";
import { checkAndRefreshToken } from "./tokenHelper";

export async function fetchWithSessionHandling(url, requestOptions) {
    try {
        await checkAndRefreshToken();
    } catch (error) {
        console.error("Error checking and refreshing token:", error);
        const state = store.getState();
        if (!state.uiReducer.isLoggingOut) {
            store.dispatch(showSessionExpiredDialog());
        }
        throw error;
    }

    return fetch(url, requestOptions).then((response) => {
        if (response.status === 401) {
            const state = store.getState();
            // console.log("state.uiReducer.isLoggingOut: ", state.uiReducer.isLoggingOut);
            if (!state.uiReducer.isLoggingOut) {
                store.dispatch(showSessionExpiredDialog());
            }
            throw new Error("Session expired");
        }
        return response;
    });
}
