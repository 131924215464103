import { SHOW_SESSION_EXPIRED_DIALOG, HIDE_SESSION_EXPIRED_DIALOG, SET_LOGOUT_FLAG } from "../actions/uiActions";

const initialState = {
    sessionExpiredDialogVisible: false,
    isLoggingOut: false,
};

export default function uiReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionExpiredDialogVisible: true,
            };
        case HIDE_SESSION_EXPIRED_DIALOG:
            return {
                ...state,
                sessionExpiredDialogVisible: false,
            };
        case SET_LOGOUT_FLAG:
            return {
                ...state,
                isLoggingOut: action.payload,
            };
        default:
            return state;
    }
}
