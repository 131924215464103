import React from "react";
import { View, Text } from "react-native";
import { DataTable } from "react-native-paper";
import { useSelector } from "react-redux";
import { getContrastColor } from "../../../shared/helpers";

const Summary = ({ props, theme, settings }) => {
    const cart = useSelector((state) => state.cart);

    const total = cart.orderItems
        .reduce((sum, item) => {
            return sum + item.Price.price1 * item.entry.amount;
        }, 0)
        .toFixed(2);

    const billingCountry = settings.store.countries.find(
        (country) => country.countryId === Number(props.values.billingCountry)
    );

    const deliveryCountry = settings.store.countries.find(
        (country) => country.countryId === Number(props.values.country)
    );

    const billingAddress = props.values.billingAddress
        ? JSON.parse(props.values.billingAddress)
        : {
              title: props.values.billingTitle,
              firstName: props.values.billingFirstName,
              lastName: props.values.billingLastName,
              street: props.values.billingStreet,
              housenumber: props.values.billingHousenumber,
              zip: props.values.billingZipCode,
              city: props.values.billingCity,
              country: billingCountry ? billingCountry.name : "",
          };
    const deliveryAddress = props.values.deliveryAddress
        ? JSON.parse(props.values.deliveryAddress)
        : {
              title: props.values.title,
              firstName: props.values.firstName,
              lastName: props.values.lastName,
              street: props.values.txtStreet,
              housenumber: props.values.housenumber,
              zip: props.values.txtZipCode,
              city: props.values.txtCity,
              country: deliveryCountry ? deliveryCountry.name : "",
          };

    const deliveryCountryFees = settings.store.countries.find(
        (country) => country.name === deliveryAddress.country
    ) || {
        deliveryFee: 0,
        deliveryFreeAt: 0,
        minOrderValue: 0,
        minOrderCharge: 0,
        name: "Unbekanntes Land",
    };

    const calculateMinOrderCharge = (total, deliveryCountryFees, settings) => {
        const minOrderValue = Number(deliveryCountryFees.minOrderValue);
        const minOrderCharge = Number(deliveryCountryFees.minOrderCharge);

        if (settings.store.dynamicMinOrderCharge) {
            return total < minOrderValue ? (minOrderValue - total).toFixed(2) : 0;
        } else {
            return total < minOrderValue && minOrderCharge ? minOrderCharge.toFixed(2) : 0;
        }
    };

    const minOrderCharge = calculateMinOrderCharge(total, deliveryCountryFees, settings);

    const deliveryFee =
        Number(deliveryCountryFees.deliveryFee) > 0 &&
        (Number(deliveryCountryFees.deliveryFreeAt) === 0 || total < Number(deliveryCountryFees.deliveryFreeAt))
            ? Number(deliveryCountryFees.deliveryFee).toFixed(2)
            : 0;

    const areAddressesEqual = (address1, address2) => {
        return (
            address1.title === address2.title &&
            address1.firstName === address2.firstName &&
            address1.lastName === address2.lastName &&
            address1.street === address2.street &&
            address1.housenumber === address2.housenumber &&
            address1.zip === address2.zip &&
            address1.city === address2.city
        );
    };

    return (
        props.values && (
            <>
                <View>
                    <DataTable>
                        <DataTableHeader theme={theme}>
                            <DataTableTitle theme={theme}>Lieferadresse</DataTableTitle>
                        </DataTableHeader>

                        <DataTableRow>
                            <DataTableCell>Vor- und Nachname</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {deliveryAddress.title} {deliveryAddress.firstName} {deliveryAddress.lastName}
                            </DataTableCell>
                        </DataTableRow>

                        <DataTableRow>
                            <DataTableCell>Lieferungsadresse</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {deliveryAddress.street} {deliveryAddress.housenumber}
                            </DataTableCell>
                        </DataTableRow>

                        <DataTableRow>
                            <DataTableCell>PLZ und Stadt</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {deliveryAddress.zip} {deliveryAddress.city}
                            </DataTableCell>
                        </DataTableRow>

                        <DataTableRow>
                            <DataTableCell>Land</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>{deliveryAddress.country}</DataTableCell>
                        </DataTableRow>
                    </DataTable>
                </View>
                {billingAddress &&
                    props.values.useDifferentBillingAddress &&
                    !areAddressesEqual(billingAddress, deliveryAddress) && (
                        <View>
                            <DataTable>
                                <DataTableHeader theme={theme}>
                                    <DataTableTitle theme={theme}>Rechnungsadresse</DataTableTitle>
                                </DataTableHeader>

                                <DataTableRow>
                                    <DataTableCell>Vor- und Nachname</DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric>
                                        {billingAddress.title} {billingAddress.firstName} {billingAddress.lastName}
                                    </DataTableCell>
                                </DataTableRow>

                                <DataTableRow>
                                    <DataTableCell>Lieferungsadresse</DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric>
                                        {billingAddress.street} {billingAddress.housenumber}
                                    </DataTableCell>
                                </DataTableRow>

                                <DataTableRow>
                                    <DataTableCell>PLZ und Stadt</DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric>
                                        {billingAddress.zip} {billingAddress.city}
                                    </DataTableCell>
                                </DataTableRow>

                                <DataTableRow>
                                    <DataTableCell>Land</DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric></DataTableCell>
                                    <DataTableCell numeric>{billingAddress.country}</DataTableCell>
                                </DataTableRow>
                            </DataTable>
                        </View>
                    )}
                <View>
                    <DataTable>
                        <DataTableHeader theme={theme}>
                            <DataTableTitle theme={theme}>Zahlungsinfo</DataTableTitle>
                        </DataTableHeader>

                        <DataTableRow>
                            <DataTableCell style={{ textTransform: "capitalize" }}>
                                {settings.store.availablePaymentTypes.find(
                                    (type) => type.identifier === props.values.paymentMethod
                                )?.name || props.values.paymentMethod}
                            </DataTableCell>
                        </DataTableRow>
                    </DataTable>
                </View>
                {props.values.comment && (
                    <View>
                        <DataTable>
                            <DataTableHeader theme={theme}>
                                <DataTableTitle theme={theme}>Kommentar/Wünsche</DataTableTitle>
                            </DataTableHeader>

                            <DataTableRow>
                                <DataTableCell>{props.values.comment}</DataTableCell>
                            </DataTableRow>
                        </DataTable>
                    </View>
                )}
                <View>
                    <DataTable>
                        <DataTableHeader theme={theme}>
                            <DataTableTitle theme={theme}>Artikel</DataTableTitle>
                            <DataTableTitle theme={theme} numeric>
                                Größe
                            </DataTableTitle>
                            <DataTableTitle theme={theme} numeric>
                                Menge
                            </DataTableTitle>
                            <DataTableTitle theme={theme} numeric>
                                Preis
                            </DataTableTitle>
                        </DataTableHeader>

                        {cart.orderItems.map((item) => (
                            <DataTableRow key={item.orderItemId}>
                                <DataTableCell>{item.entry.name}</DataTableCell>
                                <DataTableCell numeric>{item.ArticleSize.Size.displayName}</DataTableCell>
                                <DataTableCell numeric>{item.entry.amount}</DataTableCell>
                                <DataTableCell numeric>
                                    {(item.Price.price1 * item.entry.amount).toFixed(2)}
                                    <Text>€</Text>
                                </DataTableCell>
                            </DataTableRow>
                        ))}

                        {deliveryFee > 0 && (
                            <DataTableRow>
                                <DataTableCell>Lieferkosten</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>
                                    {deliveryFee.replace(".", ",")}
                                    <Text>€</Text>
                                </DataTableCell>
                            </DataTableRow>
                        )}

                        {minOrderCharge > 0 && (
                            <DataTableRow>
                                <DataTableCell>Mindermengenzuschlag</DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric></DataTableCell>
                                <DataTableCell numeric>
                                    {minOrderCharge.replace(".", ",")}
                                    <Text>€</Text>
                                </DataTableCell>
                            </DataTableRow>
                        )}

                        <DataTableRow>
                            <DataTableCell>Gesamt</DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric></DataTableCell>
                            <DataTableCell numeric>
                                {(Number(total) + Number(deliveryFee) + Number(minOrderCharge))
                                    .toFixed(2)
                                    .replace(".", ",")}
                                <Text>€</Text>
                            </DataTableCell>
                        </DataTableRow>
                    </DataTable>
                </View>
            </>
        )
    );
};

const DataTableHeader = ({ children, theme }) => {
    return (
        <DataTable.Header
            style={{
                backgroundColor: theme.colors.primary,
                borderBottomWidth: 0,
            }}
        >
            {children}
        </DataTable.Header>
    );
};

const DataTableRow = ({ children }) => {
    return <DataTable.Row style={{ cursor: "default", backgroundColor: "#ddd" }}>{children}</DataTable.Row>;
};

const DataTableCell = ({ children, numeric }) => {
    return (
        <DataTable.Cell style={{ cursor: "default" }} numeric={numeric ? numeric : false}>
            {children}
        </DataTable.Cell>
    );
};

const DataTableTitle = ({ children, numeric, theme }) => {
    return (
        <DataTable.Title
            textStyle={{
                color: getContrastColor(theme.colors.primary),
                textTransform: numeric ? "none" : "uppercase",
                fontWeight: numeric ? "normal" : "bold",
            }}
            numeric={numeric ? numeric : false}
        >
            {children}
        </DataTable.Title>
    );
};

export default Summary;
