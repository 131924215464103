import React, { Component } from "react";
import { Button, Dialog, IconButton, Portal, Text } from "react-native-paper";
import { getContrastColor } from "../../shared/helpers";
import { styles } from "../../shared/styles";
import { View } from "react-native";

export default class ConfirmDeletePopup extends Component {
    render() {
        const { theme, title, content, onConfirm, visible, togglePopup } = this.props;
        return (
            <Portal>
                <Dialog
                    visible={this.props.visible}
                    onDismiss={this.props.togglePopup}
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        backgroundColor: theme.colors.surface,
                    }}
                    dismissable={false}
                >
                    <IconButton
                        icon="close"
                        size={24}
                        onPress={this.props.togglePopup}
                        style={[styles.dialogCloseButton, { backgroundColor: theme.colors.primary }]}
                        iconColor={getContrastColor(theme.colors.primary)}
                        rippleColor="transparent"
                    />
                    <Dialog.Title style={[styles.dialogHeadline, { paddingRight: 40 }]}>
                        <Text>{title}</Text>
                    </Dialog.Title>
                    <Dialog.Content>
                        <Text>{content}</Text>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <View style={styles.dialogButtonContainerVertical}>
                            <Button
                                textColor={getContrastColor(theme.colors.primary)}
                                style={{
                                    backgroundColor: theme.colors.primary,
                                    flex: 1,
                                    marginRight: "2%",
                                    marginBottom: 0,
                                }}
                                uppercase={false}
                                onPress={this.props.togglePopup}
                            >
                                Nein
                            </Button>
                            <Button
                                textColor={getContrastColor(theme.colors.primary)}
                                style={{
                                    backgroundColor: theme.colors.error,
                                    flex: 1,
                                    marginRight: 0,
                                    marginBottom: 0,
                                }}
                                onPress={() => {
                                    onConfirm();
                                    this.props.togglePopup();
                                }}
                                uppercase={false}
                            >
                                Ja
                            </Button>
                        </View>
                    </Dialog.Actions>
                </Dialog>
            </Portal>
        );
    }
}
