import { StyleSheet } from "react-native";

export const customerDataStyles = StyleSheet.create({
    container: {
        // width: 350,
        width: "95%",
        marginLeft: "auto",
        marginRight: "auto",
        zIndex: 1,
    },
    rowWithMultiInput: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    fieldHouseNumber: {
        width: "30%",
        paddingRight: 0,
    },
    checkbox: {
        width: "98%",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 15,
    },
    fieldStreet: {
        width: "70%",
        paddingLeft: 0,
    },
    fieldPassword: {
        width: "70%",
        paddingLeft: 0,
    },
    fieldPasswordConfirm: {
        width: "70%",
        paddingLeft: 0,
    },
    fieldCity: {
        width: "70%",
        paddingRight: 0,
    },
    fieldZip: {
        width: "30%",
        paddingLeft: 0,
    },
    fieldsWrapper: {
        flexDirection: "row",
        flexWrap: "wrap",
        // maxWidth: 320,
        maxWidth: "95%",
        marginTop: 10,
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: 0,
        paddingRight: 0,
        paddingHorizontal: 0,
    },
    fieldRow: {
        width: "100%",
        marginBottom: 16,
    },
    containerFullRow: {
        width: "100%",
        flexDirection: "column",
    },
    containerSplitRow: {
        width: "100%",
        flexDirection: "row",
    },
    fieldFormFullRow: {
        width: "100%",
        marginBottom: 16,
    },
    fieldFormSplitRow: {
        width: "48%",
        marginRight: "2%",
        marginBottom: 16,
    },
    fieldEven: {
        backgroundColor: "red",
    },
    fieldOdd: {
        backgroundColor: "green",
    },
    helperText: {
        marginTop: 10,
        marginLeft: 15,
    },
    headlineText: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 10,
        textAlign: "center",
    },
    paymentInfoText: {
        textAlign: "center",
        marginBottom: 25,
    },
    customLink: {
        textDecorationLine: "underline",
        marginLeft: 4,
        marginRight: 4,
    },
    countryDialogSelect: {
        fontFamily: "regular",
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
    },
    countryDialogSelectLabel: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 15,
    },
});
