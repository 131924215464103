import moment from "moment";
import React from "react";
import { View } from "react-native";
import { orderTrackingStyles } from "./OrderTrackingStyles";
import { Text } from "react-native-paper";

export default function OrderInformations(props) {
    return (
        <View>
            <View style={orderTrackingStyles.row}>
                <View style={orderTrackingStyles.rowLabel}>
                    <Text style={orderTrackingStyles.text}>{"Status: " + props.data.orderStateString} </Text>
                </View>
            </View>
            <View style={orderTrackingStyles.row}>
                <View style={orderTrackingStyles.rowLabel}>
                    <Text style={orderTrackingStyles.text}>{"Zahlungsweise: " + props.data.paymentTypeString}</Text>
                </View>
            </View>
        </View>
    );
}
