import React, { Component } from "react";
import { ActivityIndicator, Avatar, Card, Text, withTheme } from "react-native-paper";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Toast } from "react-native-toast-notifications";

import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import {
    deleteAddress,
    getCustomerAddresses,
    updateStandardBillingAddress,
    updateStandardDeliveryAddress,
} from "../../store/actions/accountActions";
import { store } from "../../store/store";
import AddAddressPopup from "./AddAddressPopup";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import { desktopBreakpoint, getContrastColor, phabletBreakpoint } from "../../shared/helpers";
import { showToast, updateToast } from "../../helpers/toastMessage/ToastMessage";
import { styles } from "../../shared/styles";
import CustomerNavigation from "./CustomerNavigation";

class CustomerAddresses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addresses: [],
            showAddAddressPopup: false,
            test: false,
            additionalAddressToEdit: 0,
            edit: false,
            showConfirmDeletePopup: false,
            addressToDelete: undefined,
            indexToDelete: 0,
            addressesAreLoading: false,
        };

        this.toggleAddAddressPopup = this.toggleAddAddressPopup.bind(this);
        this.toggleConfirmDeletePopup = this.toggleConfirmDeletePopup.bind(this);
        this.editAddress = this.editAddress.bind(this);
    }

    componentDidMount() {
        this.fetchCustomerAddresses(this.props.keycloak.token);
    }

    toggleAddAddressPopup() {
        if (this.state.edit && this.state.showAddAddressPopup) this.setState({ edit: false });
        document.body.style.overflow = !this.state.showAddAddressPopup ? "hidden" : "auto";
        this.setState({ showAddAddressPopup: !this.state.showAddAddressPopup });
    }

    toggleConfirmDeletePopup() {
        if (this.state.edit && this.state.showConfirmDeletePopup) this.setState({ edit: false });
        document.body.style.overflow = !this.state.showConfirmDeletePopup ? "hidden" : "auto";
        this.setState({ showConfirmDeletePopup: !this.state.showConfirmDeletePopup });
    }

    async deleteAddress() {
        const { customer } = this.props.account;
        const address = this.state.addressToDelete;

        const toastId = showToast("Adresse wird gelöscht...", "trash", "info");

        try {
            const response = await store.dispatch(
                deleteAddress(
                    customer.customerUid,
                    this.state.indexToDelete,
                    address.housenumber,
                    address.addressUid,
                    address.addressId,
                    this.props.keycloak
                )
            );

            if (response) {
                updateToast(toastId, "Adresse gelöscht", "checkmark-circle-outline", "success");
                this.props.update();
                await this.fetchCustomerAddresses(this.props.keycloak.token);
            } else {
                throw new Error("Failed to delete address");
            }
        } catch (error) {
            console.error("Error deleting address:", error);
            updateToast(toastId, "Fehler beim Löschen der Adresse", "close-circle-outline", "error");
        }
    }

    editAddress(address) {
        const { customer } = this.props.account;
        this.setState(
            {
                additionalAddressToEdit: address,
                indexOfAddressToEdit: customer.addresses.indexOf(address),
                edit: true,
            },
            () => {
                this.toggleAddAddressPopup();
            }
        );
    }

    async changeDefaultAddress(address, type) {
        const actionType = type === "billing" ? "rechnungs" : "liefer";
        const actionCreator = type === "billing" ? updateStandardBillingAddress : updateStandardDeliveryAddress;
        const addressKey = type === "billing" ? "isDefaultBillingAddress" : "isDefaultDeliveryAddress";

        const toastId = showToast(`Standard${actionType}adresse wird geändert`, "information-circle-outline", "info");

        try {
            const response = await store.dispatch(actionCreator(address.addressId, this.props.keycloak));
            // specific response from Azure functions
            if (response.message === "OK") {
                this.props.update();
                this.updateAddressesState(address.addressId, addressKey);
                updateToast(toastId, `Standard${actionType}adresse geändert`, "checkmark-circle-outline", "success");
            } else {
                throw new Error(`Failed to change default ${type} address`);
            }
        } catch (error) {
            console.error(`Error changing default ${actionType}address:`, error);
            updateToast(
                toastId,
                `Fehler beim Ändern der Standard${actionType}adresse`,
                "close-circle-outline",
                "error"
            );
        }
    }

    updateAddressesState(addressId, addressKey) {
        this.setState((prevState) => ({
            addresses: prevState.addresses.map((addr) => ({
                ...addr,
                [addressKey]: addr.addressId === addressId,
            })),
        }));
    }

    async fetchCustomerAddresses(token) {
        try {
            this.setState({ addressesAreLoading: true });
            const response = await store.dispatch(getCustomerAddresses(token));
            if (response.ok) {
                const addresses = await response.json();
                this.setState({ addresses });
                this.setState({ addressesAreLoading: false });
            } else {
                this.setState({ addressesAreLoading: false });
                console.error("Failed to fetch customer addresses:", response.statusText);
            }
        } catch (error) {
            this.setState({ addressesAreLoading: false });
            console.error("Error fetching customer addresses:", error);
        }
    }

    render() {
        const { customer } = this.props.account;
        const { theme, keycloak, windowWidth } = this.props;
        const { addresses } = this.state;
        const isDesktop = windowWidth >= desktopBreakpoint;

        return (
            <View style={[isDesktop ? styles.desktopContainerWrapper : null, { padding: !isDesktop ? 20 : null }]}>
                <View>
                    {this.props.showBackButton && (
                        <View style={{ display: "flex", flexDirection: "row" }}>
                            <CustomIconButton
                                icon="arrow-left"
                                tooltip="Zurück"
                                position="right"
                                onPress={() => this.props.history.push("/")}
                            ></CustomIconButton>
                            <Text style={{ marginTop: 17 }}>Zurück zur Startseite</Text>
                        </View>
                    )}
                    <View
                        nativeID="customer-area"
                        style={[
                            styles.customerArea,
                            isDesktop ? styles.customerAreaDesktop : styles.customerAreaMobile,
                            {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            },
                        ]}
                    >
                        <View
                            nativeID="customer-navigation"
                            style={[
                                styles.customerNavigation,
                                isDesktop ? styles.customerNavigationDesktop : styles.customerNavigationMobile,
                            ]}
                        >
                            <CustomerNavigation windowWidth={windowWidth} />
                        </View>

                        <View
                            nativeID="customer-content"
                            style={[
                                styles.customerContent,
                                isDesktop ? styles.customerContentDesktop : styles.customerContentMobile,
                                {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                },
                            ]}
                        >
                            {addresses && !this.state.addressesAreLoading ? (
                                <>
                                    {addresses.map((address, index) => {
                                        return (
                                            <Card
                                                style={{
                                                    width:
                                                        windowWidth < phabletBreakpoint
                                                            ? "calc(100% - 10px)"
                                                            : windowWidth <= desktopBreakpoint
                                                            ? "calc( (100% / 2) - 10px)"
                                                            : "calc( (100% / 3) - 10px)",
                                                    margin: 5,
                                                    marginBottom: 10,
                                                    minHeight: 200,
                                                    zIndex: addresses.length - index,
                                                }}
                                                contentStyle={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                }}
                                                key={index}
                                            >
                                                <Card.Title title={address.firstName + " " + address.lastName} />
                                                <Card.Content>
                                                    <Text>
                                                        {address.street +
                                                            " " +
                                                            address.housenumber +
                                                            "\n" +
                                                            address.zip +
                                                            " " +
                                                            address.city +
                                                            "\n" +
                                                            address.country +
                                                            "\n\n" +
                                                            (address.company ? address.company : "") +
                                                            "\n" +
                                                            (address.addressDetails ? address.addressDetails : "")}
                                                    </Text>
                                                </Card.Content>

                                                <Card.Actions
                                                    style={{
                                                        justifyContent: "center",
                                                        alignItems: "flex-end",
                                                        flexDirection: "row",
                                                        marginVertical: 15,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            justifyContent: "center",
                                                            alignItems: "flex-end",
                                                            flexDirection: "row",
                                                            marginTop: 0,
                                                            marginBottom: 0,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <CustomIconButton
                                                            icon="pencil"
                                                            color={getContrastColor(theme.colors.primary)}
                                                            tooltip="Adresse bearbeiten"
                                                            onPress={() => this.editAddress(address)}
                                                            backgroundStyle={{
                                                                backgroundColor: theme.colors.primary,
                                                                borderRadius: 7,
                                                                marginRight: 15,
                                                            }}
                                                        ></CustomIconButton>

                                                        <CustomIconButton
                                                            icon="receipt"
                                                            tooltip="als Standardrechnungsadresse wählen"
                                                            color={
                                                                address.isDefaultBillingAddress
                                                                    ? getContrastColor(theme.colors.accent)
                                                                    : getContrastColor(theme.colors.primary)
                                                            }
                                                            onPress={() =>
                                                                this.changeDefaultAddress(address, "billing")
                                                            }
                                                            backgroundStyle={{
                                                                backgroundColor: address.isDefaultBillingAddress
                                                                    ? theme.colors.accent
                                                                    : theme.colors.primary,
                                                                borderRadius: 7,
                                                                marginRight: 15,
                                                            }}
                                                        ></CustomIconButton>
                                                        <CustomIconButton
                                                            icon="truck"
                                                            tooltip="als Standardlieferadresse wählen"
                                                            color={
                                                                address.isDefaultDeliveryAddress
                                                                    ? getContrastColor(theme.colors.accent)
                                                                    : getContrastColor(theme.colors.primary)
                                                            }
                                                            onPress={() =>
                                                                this.changeDefaultAddress(address, "delivery")
                                                            }
                                                            backgroundStyle={{
                                                                backgroundColor: address.isDefaultDeliveryAddress
                                                                    ? theme.colors.accent
                                                                    : theme.colors.primary,
                                                                borderRadius: 7,
                                                                marginRight: 15,
                                                            }}
                                                        ></CustomIconButton>
                                                        <CustomIconButton
                                                            icon="delete"
                                                            tooltip="Adresse löschen"
                                                            color={getContrastColor(theme.colors.primary)}
                                                            onPress={() => {
                                                                if (customer.addresses.length === 1) {
                                                                    showToast(
                                                                        "Sie müssen mindestens eine Adresse angeben",
                                                                        "warning",
                                                                        "error",
                                                                        theme
                                                                    );
                                                                    return;
                                                                } else {
                                                                    this.setState(
                                                                        {
                                                                            addressToDelete: address,
                                                                            indexToDelete: addresses.indexOf(address),
                                                                        },
                                                                        () => this.toggleConfirmDeletePopup()
                                                                    );
                                                                }
                                                            }}
                                                            backgroundStyle={{
                                                                backgroundColor: theme.colors.primary,
                                                                borderRadius: 7,
                                                                marginRight: 0,
                                                            }}
                                                        ></CustomIconButton>
                                                    </View>
                                                </Card.Actions>
                                            </Card>
                                        );
                                    })}
                                    <Card
                                        style={{
                                            backgroundColor: "transparent",
                                            aspectRatio: "2 / 1",
                                            borderWidth: 3,
                                            borderStyle: "dashed",
                                            borderColor: theme.colors.primary,
                                            width:
                                                windowWidth < phabletBreakpoint
                                                    ? "calc(100% - 10px)"
                                                    : windowWidth <= desktopBreakpoint
                                                    ? "calc( (100% / 2) - 10px)"
                                                    : "calc( (100% / 3) - 10px)",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            margin: 5,
                                            cursor: "pointer",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        onPress={() => this.toggleAddAddressPopup()}
                                    >
                                        <Card.Content
                                            style={{
                                                margin: "auto",
                                                flex: 1,
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Avatar.Icon
                                                    icon="plus"
                                                    style={{
                                                        backgroundColor: theme.colors.primary,
                                                    }}
                                                    color={getContrastColor(theme.colors.primary)}
                                                    size={38}
                                                />
                                                <Text style={{ color: theme.colors.text, marginTop: 15 }}>
                                                    Adresse hinzufügen
                                                </Text>
                                            </View>
                                        </Card.Content>
                                    </Card>
                                </>
                            ) : (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    <ActivityIndicator size={"large"} />
                                </View>
                            )}
                        </View>
                    </View>
                    {this.state.showAddAddressPopup && (
                        <AddAddressPopup
                            visible={this.state.showAddAddressPopup}
                            togglePopup={this.toggleAddAddressPopup}
                            numberOfAddressesSaved={customer.addresses.length}
                            addressIndex={this.state.indexOfAddressToEdit}
                            addressToEdit={this.state.additionalAddressToEdit}
                            edit={this.state.edit}
                            theme={theme}
                            update={() => {
                                this.props.update();
                                this.fetchCustomerAddresses(this.props.keycloak.token);
                            }}
                            keycloak={keycloak}
                        />
                    )}
                    {this.state.showConfirmDeletePopup && (
                        <ConfirmDeletePopup
                            visible={this.state.showConfirmDeletePopup}
                            togglePopup={this.toggleConfirmDeletePopup}
                            theme={theme}
                            title="Adresse löschen?"
                            content="Möchten Sie diese Adresse wirklich unwiderruflich löschen?"
                            onConfirm={() => this.deleteAddress()}
                        />
                    )}
                </View>
            </View>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account } = state;
    return { settings, account };
}

export default connect(mapStateToProps)(withTheme(withRouter(CustomerAddresses)));
