import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { DataTable, Text } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { convertUnit, formatNumberToLocalString } from "../../../shared/helpers";
export default function NutritionalValues({ data }) {
    const [groupedData, setGroupedData] = useState(null);
    const [selected, setSelected] = useState(["Durchschnittliche Nährwertangaben"]);
    const groupByNutritionalValueGroup = (data) => {
        return data.reduce((acc, item) => {
            const { nutritionalValueGroup } = item.NutritionalValueDummy;
            if (nutritionalValueGroup) {
                if (!acc[nutritionalValueGroup]) {
                    acc[nutritionalValueGroup] = [];
                }
                acc[nutritionalValueGroup].push(item);
            }
            return acc;
        }, {});
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (data) {
            setGroupedData(groupByNutritionalValueGroup(data));
        }
    }, [data]);

    return (
        <View style={{ width: "100%", backgroundColor: windowWidth > 800 ? "inherit" : "#dddddd" }}>
            {data.length === 0 && (
                <DataTable>
                    <DataTable.Row style={{ cursor: "default", borderBottomWidth: 0 }}>
                        <DataTable.Cell style={{ cursor: "default", borderBottomWidth: 0 }}>
                            Keine Daten vorhanden
                        </DataTable.Cell>
                    </DataTable.Row>
                </DataTable>
            )}
            {data.length > 0 && (
                <View style={{ width: "100%" }}>
                    {windowWidth > 800 ? null : (
                        <View
                            style={{
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                marginTop: 15,
                                marginRight: 10,
                            }}
                        >
                            <Text style={{ fontSize: 11, paddingRight: "15px" }}>Angaben pro 100 g</Text>
                        </View>
                    )}

                    {groupedData &&
                        Object.keys(groupedData)
                            .sort((a, b) => {
                                if (a === "Durchschnittliche Nährwertangaben") return -1;
                                if (b === "Durchschnittliche Nährwertangaben") return 1;
                                return a.localeCompare(b);
                            })
                            .map((key, groupIndex) => {
                                return (
                                    <DataTable key={key} style={{ marginBottom: 20 }}>
                                        <DataTable.Header>
                                            <DataTable.Title
                                                onPress={() =>
                                                    setSelected(
                                                        selected.includes(key)
                                                            ? selected.filter((x) => x !== key)
                                                            : [...selected, key]
                                                    )
                                                }
                                            >
                                                {key && key !== "null" ? key : ""}
                                            </DataTable.Title>
                                            <DataTable.Title
                                                onPress={() =>
                                                    setSelected(
                                                        selected.includes(key)
                                                            ? selected.filter((x) => x !== key)
                                                            : [...selected, key]
                                                    )
                                                }
                                                numeric
                                            >
                                                {selected.includes(key) ? (
                                                    <Ionicons name="chevron-up" style={{ cursor: "pointer" }} />
                                                ) : (
                                                    <Ionicons name="chevron-down" style={{ cursor: "pointer" }} />
                                                )}
                                            </DataTable.Title>
                                            <DataTable.Title style={{ maxWidth: 30, paddingLeft: 5 }}></DataTable.Title>
                                        </DataTable.Header>
                                        {selected.includes(key) &&
                                            groupedData[key].map((item, index) => {
                                                const { NutritionalValue, NutritionalValueDummy } = item;
                                                const currentValue = item.value || 0;
                                                const currentUnit = NutritionalValue
                                                    ? NutritionalValue.unit
                                                    : NutritionalValueDummy.unit;
                                                const currentName = NutritionalValue
                                                    ? NutritionalValue.name
                                                    : NutritionalValueDummy.name;

                                                let convertedValue = currentValue;
                                                let convertedUnit = currentUnit;

                                                if (currentUnit === "mg") {
                                                    [convertedValue, convertedUnit] = convertUnit(
                                                        currentValue,
                                                        "mg",
                                                        "g"
                                                    );
                                                }

                                                const isLastRow = index === groupedData[key].length - 1;
                                                const rowStyle = {
                                                    borderBottomWidth: isLastRow ? 0 : 1,
                                                    cursor: "default",
                                                };

                                                return (
                                                    <DataTable.Row key={index} style={rowStyle}>
                                                        <DataTable.Cell style={{ cursor: "default" }}>
                                                            {currentName}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell style={{ cursor: "default" }} numeric>
                                                            {formatNumberToLocalString(convertedValue)}
                                                        </DataTable.Cell>
                                                        <DataTable.Cell
                                                            style={{ maxWidth: 40, paddingLeft: 5, cursor: "default" }}
                                                        >
                                                            {convertedUnit}
                                                        </DataTable.Cell>
                                                    </DataTable.Row>
                                                );
                                            })}
                                    </DataTable>
                                );
                            })}
                </View>
            )}
        </View>
    );
}
