import React from "react";
import { StyleSheet, Pressable } from "react-native";
import { Text } from "react-native-paper";

const CustomMenuItem = ({
    title = "MenuItem",
    onPress = () => {},
    style = {},
    backgroundColor = "FFFFFF",
    textColor = "000000",
}) => (
    <Pressable
        onPress={() => {
            onPress();
        }}
        style={[styles.menuItem, style, { backgroundColor }]}
    >
        <Text style={{ color: textColor, whiteSpace: "nowrap" }}>{title}</Text>
    </Pressable>
);

const styles = StyleSheet.create({
    menuItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: "#ccc",
        whiteSpace: "nowrap",
    },
    lastMenuItem: {
        padding: 10,
        borderBottomWidth: 0,
    },
});

export default CustomMenuItem;
