import React, { Component } from "react";
import { Text } from "react-native-paper";
import { View } from "react-native-web";
import { connect } from "react-redux";
import { withRouter } from "../../routers/routing";

import { setOnlineShopItemType } from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import { notFoundStyles } from "./NotFoundStyles";

class NotFound extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        store.dispatch(setOnlineShopItemType(-2));
    }
    render() {
        return (
            <React.Fragment>
                <View style={notFoundStyles.container}>
                    <Text variant="headlineLarge" style={notFoundStyles.text}>
                        404 - Seite nicht gefunden
                    </Text>
                    <Text style={notFoundStyles.text}>
                        Die von Ihnen aufgerufene Seite existiert nicht oder hat sich geändert
                    </Text>
                    <Text style={notFoundStyles.text}>
                        Bitte navigieren Sie über die Startseite oder dem Menü zu Ihrer gewünschten Seite
                    </Text>
                </View>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings } = state;
    return { settings };
}

export default connect(mapStateToProps)(withRouter(NotFound));
