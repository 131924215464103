import Keycloak from "keycloak-js";
import Config from "../../../Config";

const config = new Config();

const keycloakConfig = {
    url: "https://keycloak.gluecklichegaeste.de/",
    realm: config.keycloakRealm,
    clientId: config.keycloakClientId,
    //  : config.shopUrl.replaceAll(".", "-").replace("https://", "").replace("http://", ""),
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
