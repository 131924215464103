export const SHOW_SESSION_EXPIRED_DIALOG = "SHOW_SESSION_EXPIRED_DIALOG";
export const HIDE_SESSION_EXPIRED_DIALOG = "HIDE_SESSION_EXPIRED_DIALOG";
export const SET_LOGOUT_FLAG = "SET_LOGOUT_FLAG";

export function showSessionExpiredDialog() {
    return {
        type: SHOW_SESSION_EXPIRED_DIALOG,
    };
}

export function hideSessionExpiredDialog() {
    return {
        type: HIDE_SESSION_EXPIRED_DIALOG,
    };
}

export function setLogoutFlag(isLoggingOut) {
    return {
        type: SET_LOGOUT_FLAG,
        payload: isLoggingOut,
    };
}
