import React, { Component } from "react";
import { Dimensions, Pressable, StyleSheet, View } from "react-native";
import { Appbar, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";

import { Link, withRouter } from "../../routers/routing";
import { checkForRecipes, desktopBreakpoint, getContrastColor, withHooksHOC } from "../../shared/helpers";
import { headerStyles } from "./HeaderStyle";

class SubHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            showRecipes: false,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
        const showRecipes = checkForRecipes(this.props.biosInstances, this.props.onlineShopCategories);
        this.setState({ showRecipes });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            const showRecipes = checkForRecipes(this.props.biosInstances, this.props.onlineShopCategories);
            this.setState({ showRecipes });
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    render() {
        const { theme } = this.props;
        let mobile = this.props.windowSize[0] < desktopBreakpoint;
        return (
            <React.Fragment>
                <Appbar.Header
                    statusBarHeight={0}
                    style={{
                        ...StyleSheet.flatten(headerStyles.appsubbar),
                        backgroundColor: theme.colors.accent,
                    }}
                >
                    <View nativeID="menu" style={headerStyles.submenu}>
                        <View nativeID="links" style={headerStyles.links}>
                            <Pressable key={"home"}>
                                <Text>
                                    <Link
                                        style={{
                                            ...StyleSheet.flatten(headerStyles.link),
                                            color: getContrastColor(theme.colors.accent),
                                        }}
                                        to="/"
                                    >
                                        {this.props.onlineShopCategories.onlineShopItemType === -1 ? (
                                            <u>Startseite</u>
                                        ) : (
                                            "Startseite"
                                        )}
                                    </Link>
                                </Text>
                            </Pressable>
                            <Pressable key={"shop"}>
                                <Text>
                                    <Link
                                        style={{
                                            ...StyleSheet.flatten(headerStyles.link),
                                            color: getContrastColor(theme.colors.accent),
                                        }}
                                        to="/shop"
                                    >
                                        {this.props.onlineShopCategories.onlineShopItemType === 0 ? (
                                            <u>Shop</u>
                                        ) : (
                                            "Shop"
                                        )}
                                    </Link>
                                </Text>
                            </Pressable>
                            {this.state.showRecipes && (
                                <Pressable key={"recipes"}>
                                    <Text>
                                        <Link
                                            style={{
                                                ...StyleSheet.flatten(headerStyles.link),
                                                color: getContrastColor(theme.colors.accent),
                                            }}
                                            to="/recipes"
                                        >
                                            {this.props.onlineShopCategories.onlineShopItemType === 1 ? (
                                                <u>Rezepte</u>
                                            ) : (
                                                "Rezepte"
                                            )}
                                        </Link>
                                    </Text>
                                </Pressable>
                            )}
                        </View>
                    </View>
                </Appbar.Header>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, onlineShopCategories } = state;
    return { settings, account, onlineShopCategories };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(SubHeader))));
