import React from "react";
import { View } from "react-native";

import ContentImage from "./Contents/ContentImage";
import ContentText from "./Contents/ContentText";

export default function ColumnContent({ contentType, content, size }) {
    return (
        <View nativeID="columnContent" style={{ marginBottom: 20 }}>
            {contentType === "text" && <ContentText text={content} />}
            {contentType === "image" && <ContentImage image={content} size={size} />}
        </View>
    );
}
