import React, { useState, useRef } from "react";
import { View, Pressable, TextInput } from "react-native";
import { Text } from "react-native-paper";
import { styles } from "./AddToCardDialogStyles";
import { getContrastColor } from "../../../shared/helpers";

const defaultTheme = {
    colors: {
        primary: "black",
        surface: "white",
    },
};

const AmountSelection = ({
    initialAmount = 1,
    maxAmount = 10,
    theme = defaultTheme,
    onAmountChange = () => {},
    size = "normal",
}) => {
    const [amount, setLocalAmount] = useState(initialAmount);
    const [selection, setSelection] = useState({ start: 0, end: initialAmount.toString().length });
    const inputRef = useRef(null);

    const setAmount = (value) => {
        const newAmount = parseInt(value, 10);
        if (isNaN(newAmount) || newAmount < 1) {
            setLocalAmount(1);
            onAmountChange(1);
        } else if (newAmount > maxAmount) {
            setLocalAmount(maxAmount);
            onAmountChange(maxAmount);
        } else {
            setLocalAmount(newAmount);
            onAmountChange(newAmount);
        }
        setSelection({ start: value.length, end: value.length }); // Set selection to the end after each change
    };

    const increaseAmount = () => {
        if (amount < maxAmount) {
            const newAmount = amount + 1;
            setLocalAmount(newAmount);
            onAmountChange(newAmount);
        }
    };

    const decreaseAmount = () => {
        if (amount > 1) {
            const newAmount = amount - 1;
            setLocalAmount(newAmount);
            onAmountChange(newAmount);
        }
    };

    const handleFocus = () => {
        setSelection({ start: 0, end: amount.toString().length });
    };

    return (
        <View style={styles.amountContainer}>
            <Pressable
                onPress={decreaseAmount}
                style={[
                    styles.amountButtonStyle,
                    {
                        backgroundColor: theme.colors.primary,
                        marginRight: 10,
                    },
                    size === "small" && styles.amountSmallButtonStyle,
                ]}
            >
                <Text style={{ color: getContrastColor(theme.colors.primary) }}>-</Text>
            </Pressable>
            <TextInput
                ref={inputRef}
                style={[
                    styles.amountInputStyle,
                    {
                        backgroundColor: theme.colors.surface,
                        color: getContrastColor(theme.colors.surface),
                        borderColor: theme.colors.primary,
                    },
                    size === "small" && styles.amountSmallInputStyle,
                ]}
                keyboardType="numeric"
                underlineColor="transparent"
                onChangeText={setAmount}
                value={amount.toString()}
                onFocus={handleFocus}
                selection={selection}
            />
            <Pressable
                onPress={increaseAmount}
                style={[
                    styles.amountButtonStyle,
                    {
                        backgroundColor: theme.colors.primary,
                        marginLeft: 10,
                    },
                    size === "small" && styles.amountSmallButtonStyle,
                ]}
            >
                <Text style={{ color: getContrastColor(theme.colors.primary) }}>+</Text>
            </Pressable>
        </View>
    );
};

export default AmountSelection;
