import {
    ADD_ORDER_ITEM,
    REMOVE_ORDER_ITEM,
    REMOVE_ALL_ORDER_ITEMS,
    UPDATE_ORDER_ITEM,
    LOAD_CART_ITEMS,
} from "../actions/cartActions";

const initialState = {
    orderItems: JSON.parse(localStorage.getItem("orderItems")) || [],
};

const saveToLocalStorage = (orderItems) => {
    localStorage.setItem("orderItems", JSON.stringify(orderItems));
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_ORDER_ITEM: {
            const newOrderItem = action.orderItem;
            newOrderItem.orderItemId = state.orderItems.length;
            const updatedOrderItems = [...state.orderItems, newOrderItem];
            saveToLocalStorage(updatedOrderItems);
            return { ...state, orderItems: updatedOrderItems };
        }
        case REMOVE_ORDER_ITEM: {
            const newState = { ...state };
            const orderItemToRemove = newState.orderItems.findIndex(
                (x) => x.orderItemId === action.orderItem.orderItemId
            );

            if (orderItemToRemove !== -1) {
                const updatedOrderItems = [...newState.orderItems];
                updatedOrderItems.splice(orderItemToRemove, 1);
                saveToLocalStorage(updatedOrderItems);
                return { ...state, orderItems: updatedOrderItems };
            }

            return state;
        }
        case UPDATE_ORDER_ITEM: {
            const newState = { ...state };
            const orderItemToUpdate = newState.orderItems.findIndex((x) => {
                if (x) {
                    return x.orderItemId === action.orderItem.orderItemId;
                }
                return false;
            });
            if (newState.orderItems[orderItemToUpdate]) {
                newState.orderItems[orderItemToUpdate] = action.orderItem;
            }
            saveToLocalStorage(newState.orderItems);
            return newState;
        }
        case REMOVE_ALL_ORDER_ITEMS: {
            saveToLocalStorage([]);
            return { ...state, orderItems: [] };
        }
        case LOAD_CART_ITEMS: {
            const updatedOrderItems = JSON.parse(localStorage.getItem("orderItems")) || [];
            return { ...state, orderItems: updatedOrderItems };
        }
        default:
            return state;
    }
};
