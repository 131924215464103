export function loadFont(fonts) {
    var fontStyleElement = document.createElement("style");
    // const timestamp = new Date().getTime();
    const timestamp = fonts.updatedDate;

    fontStyleElement.textContent = `
        @font-face {
            font-family: "regular";
            src: url("${fonts.regularFontPath}?v=${timestamp}") format("woff2");
            font-weight: ${fonts.regularFontWeight};
            font-style: normal;
        }
        @font-face {
            font-family: "medium";
            src: url("${fonts.mediumFontPath}?v=${timestamp}") format("woff2");
            font-weight: ${fonts.mediumFontWeight};
            font-style: normal;
        }
        @font-face {
            font-family: "light";
            src: url("${fonts.lightFontPath}?v=${timestamp}") format("woff2");
            font-weight: ${fonts.lightFontWeight};
            font-style: normal;
        }
        @font-face {
            font-family: "thin";
            src: url("${fonts.thinFontPath}?v=${timestamp}") format("woff2");
            font-weight: ${fonts.thinFontWeight};
            font-style: normal;
        }`;
    fontStyleElement.id = "fontCSS";

    document.head.appendChild(fontStyleElement);
}
