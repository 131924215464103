import React, { useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Drawer, Text, useTheme } from "react-native-paper";
import { styles } from "./AdditionalProductDetailsStyles";
import Additives from "./Additives";
import Ingredients from "./Ingredients";
import NutritionalValues from "./NutritionalValues";
import { getContrastColor } from "../../../shared/helpers";

export default function TabView({ product, additives, addIngredientToCart, ingredientsInOnlineShop }) {
    const [infoDisplayed, setInfoDisplayed] = useState(0);
    const theme = useTheme();

    const renderLabel = (content, index) => (
        <View
            style={[
                styles.tabViewDrawerItemActive,
                infoDisplayed === index ? { backgroundColor: theme.colors.primary } : {},
            ]}
        >
            <Text
                style={[
                    styles.tabViewDrawerItemContent,
                    infoDisplayed === index ? { color: getContrastColor(theme.colors.primary) } : undefined,
                ]}
            >
                {content}
            </Text>
        </View>
    );

    return (
        <View style={styles.tabViewContainer}>
            <View
                style={{ ...StyleSheet.flatten(styles.tabViewDrawerContainer), backgroundColor: theme.colors.surface }}
            >
                <Drawer.Item
                    label={renderLabel(
                        <Text
                            style={[
                                styles.tabViewDrawerItem,
                                infoDisplayed === 0 ? { color: getContrastColor(theme.colors.primary) } : undefined,
                            ]}
                        >
                            Zutaten
                        </Text>,
                        0
                    )}
                    onPress={() => setInfoDisplayed(0)}
                    rippleColor="transparent"
                />
                <Drawer.Item
                    label={renderLabel(
                        <Text
                            style={[
                                styles.tabViewDrawerItem,
                                infoDisplayed === 1 ? { color: getContrastColor(theme.colors.primary) } : undefined,
                            ]}
                        >
                            Zusatzstoffe
                        </Text>,
                        1
                    )}
                    onPress={() => setInfoDisplayed(1)}
                    rippleColor="transparent"
                />
                <Drawer.Item
                    label={renderLabel(
                        <Text
                            style={[
                                styles.tabViewDrawerItem,
                                infoDisplayed === 2 ? { color: getContrastColor(theme.colors.primary) } : undefined,
                            ]}
                        >
                            Allergene
                        </Text>,
                        2
                    )}
                    onPress={() => setInfoDisplayed(2)}
                    rippleColor="transparent"
                />
                <Drawer.Item
                    label={renderLabel(
                        <Text
                            style={[
                                styles.tabViewDrawerItem,
                                infoDisplayed === 3 ? { color: getContrastColor(theme.colors.primary) } : undefined,
                            ]}
                        >
                            Nährwerte
                            <Text
                                style={{
                                    fontSize: 11,
                                    marginLeft: 5,
                                    color: infoDisplayed === 3 ? getContrastColor(theme.colors.primary) : undefined,
                                }}
                            >
                                (pro 100 g)
                            </Text>
                        </Text>,
                        3
                    )}
                    onPress={() => setInfoDisplayed(3)}
                    rippleColor="transparent"
                />
            </View>
            <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    height: "auto",
                    flex: 1,
                    borderBottomLeftRadius: 7,
                    borderBottomRightRadius: 7,
                    padding: 10,
                    backgroundColor: theme.colors.surface,
                }}
            >
                {infoDisplayed === 0 && (
                    <Ingredients
                        data={product.ingredients}
                        isArticle={product.onlineShopItemType === 0}
                        addIngredientToCart={addIngredientToCart}
                        ingredientsInOnlineShop={ingredientsInOnlineShop}
                    />
                )}
                {infoDisplayed === 1 && (
                    <Additives
                        data={
                            additives.length === 0
                                ? []
                                : additives.filter(
                                      (additive) => additive.AdditiveGroup.name === "Zusatzstoffe" && additive.isChecked
                                  )
                        }
                    />
                )}
                {infoDisplayed === 2 && (
                    <Additives
                        data={
                            additives.length === 0
                                ? []
                                : additives.filter(
                                      (additive) => additive.AdditiveGroup.name === "Allergene" && additive.isChecked
                                  )
                        }
                    />
                )}
                {infoDisplayed === 3 && <NutritionalValues data={product.nutritionalValues} />}
            </ScrollView>
        </View>
    );
}
