import React from "react";
import { View } from "react-native";

import Config from "../../../../../Config";

export default function ContentImage({ image, size }) {
    const config = new Config();
    return (
        <View nativeID="contentImage">
            <img
                src={config.imageStorageBlobURL + image}
                style={{ width: size + "%", maxWidth: "100%", height: "auto" }}
            />
        </View>
    );
}
