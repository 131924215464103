import React, { Component } from "react";
import { SafeAreaView, StyleSheet } from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

export default class CookingTerminal extends Component {
    render() {
        return <SafeAreaView style={styles.container}></SafeAreaView>;
    }
}
