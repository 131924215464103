import React, { Component } from "react";
import { Dimensions, Image, Pressable, StyleSheet, View } from "react-native";
import { ActivityIndicator, Appbar, Icon, Text, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import Config from "../../../Config";
import CustomMenu from "../../helpers/overlayMenu/CustomMenu";
import CustomMenuItem from "../../helpers/overlayMenu/CustomMenuItem";
import { withRouter } from "../../routers/routing";
import { checkForRecipes, desktopBreakpoint, getContrastColor, withHooksHOC } from "../../shared/helpers";
import { headerStyles } from "./HeaderStyle";
import Search from "./Search";

const config = new Config();

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            showRecipes: false,
            isAuthenticated: false,
            userInfo: "",
            orderItemsCount: 0,
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleStorageChange = this.handleStorageChange.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
        window.addEventListener("storage", this.handleStorageChange);

        const showRecipes = checkForRecipes(this.props.biosInstances, this.props.onlineShopCategories);
        this.setState({ showRecipes, orderItemsCount: this.props.cart.orderItems.length });
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.updateDimensions);
        window.removeEventListener("storage", this.handleStorageChange);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cart.orderItems.length !== this.props.cart.orderItems.length) {
            this.setState({ orderItemsCount: this.props.cart.orderItems.length });
        }

        if (prevProps !== this.props) {
            const showRecipes = checkForRecipes(this.props.biosInstances, this.props.onlineShopCategories);
            this.setState({ showRecipes });
        }
    }

    handleStorageChange(event) {
        if (event.key === "orderItems") {
            const orderItems = JSON.parse(event.newValue) || [];
            this.setState({ orderItemsCount: orderItems.length });
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    handleLogin() {
        this.props.toggleLoginPopup();
    }

    handleLogout() {
        this.props.logout();
    }

    render() {
        const { theme, account, cart, onCartClick, authLoading } = this.props;
        const { windowWidth, orderItemsCount } = this.state;

        return (
            <React.Fragment>
                <Appbar.Header
                    style={{
                        backgroundColor: theme.colors.primary,
                        ...(windowWidth >= desktopBreakpoint ? headerStyles.appbar : headerStyles.appbarMobile),
                    }}
                >
                    {theme.logoUrl && (
                        <Image
                            source={{ uri: config.imageStorageBlobURL + theme.logoUrl }}
                            style={windowWidth >= desktopBreakpoint ? headerStyles.logo : headerStyles.logoMobile}
                            resizeMode="contain"
                            onClick={() => this.props.history.push("/")}
                        />
                    )}
                    {theme && theme.title !== "" && (
                        <Text
                            style={{
                                marginRight: 20,
                                fontFamily: theme.fonts.regular.fontFamily,
                                fontWeight: theme.fonts.regular.fontWeight,
                                color: getContrastColor(theme.colors.primary),
                                display: windowWidth >= desktopBreakpoint ? "flex" : "none",
                            }}
                        >
                            {theme.title}
                        </Text>
                    )}
                    <View
                        style={{
                            ...StyleSheet.flatten(
                                windowWidth >= desktopBreakpoint
                                    ? headerStyles.searchActiveView
                                    : windowWidth >= 800
                                    ? headerStyles.searchActiveViewTablet
                                    : headerStyles.searchActiveViewMobile
                            ),
                            borderColor: getContrastColor(theme.colors.primary),
                        }}
                    >
                        <Search />
                    </View>

                    {windowWidth > desktopBreakpoint && (
                        <View nativeID="menu" style={headerStyles.menu}>
                            {account.customer && !authLoading && this.state.showRecipes && (
                                <View nativeID="links" style={headerStyles.links}>
                                    <Pressable
                                        onPress={() => this.props.history.push("/wochenplan")}
                                        style={headerStyles.buttonIcons}
                                    >
                                        <Icon
                                            source="calendar-month-outline"
                                            size={20}
                                            color={getContrastColor(theme.colors.primary)}
                                        />

                                        <Text
                                            style={[
                                                headerStyles.buttonsText,
                                                { color: getContrastColor(theme.colors.primary) },
                                            ]}
                                        >
                                            Wochenplan
                                        </Text>
                                    </Pressable>
                                    <Pressable>
                                        <a
                                            style={{
                                                ...StyleSheet.flatten(headerStyles.link),
                                                ...StyleSheet.flatten(headerStyles.buttonIcons),
                                                display: "flex",
                                                flexDirection: "column",
                                                color: getContrastColor(theme.colors.primary),
                                            }}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                            href={(() => {
                                                let cookingMonitorUrl = this.props.settings.store.urlBasePath;
                                                if (!cookingMonitorUrl.startsWith("https://")) {
                                                    cookingMonitorUrl = "https://" + cookingMonitorUrl;
                                                }
                                                return (
                                                    cookingMonitorUrl +
                                                    "guest/allCookingProcessesCustomer/" +
                                                    account.customer.customerUid +
                                                    "/p/"
                                                );
                                            })()}
                                        >
                                            <Icon
                                                source="monitor"
                                                size={20}
                                                color={getContrastColor(theme.colors.primary)}
                                            />
                                            <Text
                                                style={[
                                                    headerStyles.buttonsText,
                                                    { color: getContrastColor(theme.colors.primary) },
                                                ]}
                                            >
                                                Kochmonitor
                                            </Text>
                                        </a>
                                    </Pressable>
                                    <Pressable
                                        style={headerStyles.buttonIcons}
                                        onPress={() => this.props.history.push("/shoppingList")}
                                    >
                                        <Icon
                                            source="file-document-edit-outline"
                                            size={20}
                                            color={getContrastColor(theme.colors.primary)}
                                        />
                                        <Text
                                            style={[
                                                headerStyles.buttonsText,
                                                { color: getContrastColor(theme.colors.primary) },
                                            ]}
                                        >
                                            Einkaufsliste
                                        </Text>
                                    </Pressable>
                                </View>
                            )}
                            <View nativeID="buttons" style={headerStyles.buttons}>
                                <Pressable style={headerStyles.buttonIcons} onPress={onCartClick}>
                                    <Icon
                                        source="cart-outline"
                                        size={20}
                                        color={getContrastColor(theme.colors.primary)}
                                    />
                                    <Text
                                        style={[
                                            headerStyles.buttonsText,
                                            { color: getContrastColor(theme.colors.primary) },
                                        ]}
                                    >
                                        Warenkorb
                                        {orderItemsCount ? " (" + orderItemsCount + ")" : ""}
                                    </Text>
                                </Pressable>
                                {account.customer && !authLoading && (
                                    <View style={{ zIndex: 2000, marginRight: 20 }}>
                                        <CustomMenu
                                            anchor={
                                                <>
                                                    <Icon
                                                        source="account-outline"
                                                        size={20}
                                                        color={getContrastColor(theme.colors.primary)}
                                                    />
                                                    <Text
                                                        style={[
                                                            headerStyles.buttonsText,
                                                            { color: getContrastColor(theme.colors.primary) },
                                                        ]}
                                                    >
                                                        Account
                                                    </Text>
                                                </>
                                            }
                                            direction="down"
                                            menuItemBackgroundColor={theme.colors.accent}
                                            menuItemTextColor={getContrastColor(theme.colors.accent)}
                                        >
                                            <CustomMenuItem
                                                onPress={() => this.props.history.push("/customer/profile/")}
                                                title="Kundendaten"
                                            />
                                            <CustomMenuItem
                                                onPress={() => this.props.history.push("/customer/addresses/")}
                                                title="Meine Adressen"
                                            />

                                            <CustomMenuItem onPress={() => this.handleLogout()} title="Logout" />
                                        </CustomMenu>
                                    </View>
                                )}
                                {!account.customer && !authLoading ? (
                                    <Pressable onPress={() => this.handleLogin()} style={headerStyles.buttonIcons}>
                                        <Icon source="login" size={20} color={getContrastColor(theme.colors.primary)} />
                                        <Text
                                            style={[
                                                headerStyles.buttonsText,
                                                { color: getContrastColor(theme.colors.primary) },
                                            ]}
                                        >
                                            Anmelden
                                        </Text>
                                    </Pressable>
                                ) : authLoading ? (
                                    <View>
                                        <ActivityIndicator
                                            size="small"
                                            color={getContrastColor(theme.colors.primary)}
                                            style={{ marginRight: "5px" }}
                                        />
                                    </View>
                                ) : null}
                            </View>
                        </View>
                    )}
                </Appbar.Header>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, cart, onlineShopCategories } = state;
    return { settings, account, cart, onlineShopCategories };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(Header))));
