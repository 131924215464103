import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { IconButton } from "react-native-paper";

const CustomIconButton = ({
    icon = "exclamation-thick",
    onPress = () => {},
    color = "#000000",
    position = "bottom",
    tooltip = "",
    backgroundStyle = {},
}) => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleMouseEnter = () => {
        if (tooltip) {
            setTooltipVisible(true);
        }
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };

    const getTooltipPositionStyle = () => {
        switch (position) {
            case "top":
                return {
                    bottom: "100%",
                    left: "50%",
                    transform: [{ translateX: "-50%" }],
                };
            case "right":
                return {
                    top: "50%",
                    left: "100%",
                    transform: [{ translateY: "-50%" }],
                };
            case "left":
                return {
                    top: "50%",
                    right: "100%",
                    transform: [{ translateY: "-50%" }],
                };
            case "bottom":
            default:
                return {
                    top: "calc(100% + 10px)",
                    left: "50%",
                    transform: [{ translateX: "-50%" }],
                };
        }
    };

    return (
        <View style={styles.outerContainer}>
            <View
                style={[styles.buttonContainer, backgroundStyle]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <IconButton icon={icon} iconColor={color} onPress={onPress} rippleColor="transparent" />
                {tooltipVisible && tooltip && (
                    <View style={[styles.tooltip, getTooltipPositionStyle()]}>
                        <Text style={styles.tooltipText}>{tooltip}</Text>
                    </View>
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    outerContainer: {
        flexDirection: "row",
        zIndex: 1000,
    },
    buttonContainer: {
        alignSelf: "flex-start",
        position: "relative",
    },
    tooltip: {
        position: "absolute",
        backgroundColor: "#000000",
        padding: 5,
        borderRadius: 5,
        zIndex: 1000,
    },
    tooltipText: {
        fontFamily: "regular",
        color: "#ffffff",
        fontSize: 12,
        textAlign: "center",
    },
});

export default CustomIconButton;
