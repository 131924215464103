import { StyleSheet } from "react-native";

export const mobileMenuStyle = StyleSheet.create({
    container: {
        backgroundColor: "#fff",
    },
    navWrapper: {
        marginTop: 25,
    },
    mobileNavTitle: {
        marginBottom: 15,
        fontSize: 35,
    },
    menuItem: {
        paddingBottom: 10,
        marginBottom: 10,
    },
    activeMenuItem: {
        paddingLeft: 0,
    },
    menuItemText: {
        textDecorationLine: "none",
    },
    subMenuItem: {
        marginLeft: 20,
        borderBottomWidth: 1,
        paddingBottom: 10,
        marginBottom: 10,
    },
    subcategoryItem: {
        paddingLeft: 20,
    },
});
