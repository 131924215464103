import { StyleSheet } from "react-native";

export const ProductDetailsStyles = StyleSheet.create({
    productDetailsWrapper: {
        flex: 1,
    },
    productDetailsLoadingWrapper: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
    },
    desktopContainer: {
        margin: "auto",
        maxWidth: 1280,
        position: "relative",
        width: "100%",
    },
    desktopLeftContainer: {
        display: "flex",
        width: "25%",
    },
    desktopCenterContainer: {
        display: "flex",
        width: "50%",
    },
    desktopRightContainer: {
        display: "flex",
        position: "absolute",
        width: "25%",
    },
    desktopPriceBox: {
        borderRadius: 15,
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
        display: "flex",
        padding: 15,
        position: "fixed",
        right: 0,
        width: "25%",
        zIndex: 20,
    },
    fab: {
        margin: 0,
        marginRight: 0,
        position: "fixed",
        right: 0,
        borderRadius: 25,
        // top: 110,
    },

    // Article Size Card Buttons
    articelSizesContainerDesktop: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: 30,
        padding: 0,
        width: "100%",
    },
    articelSizesCardDesktop: {
        borderWidth: 2,
        cursor: "pointer",
        display: "flex",
        margin: 5,
        minHeight: 130,
        padding: 0,
    },
    articelSizesCardContent: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
    },
    articelSizesCardTitle: {
        alignSelf: "center",
        fontWeight: "bold",
        marginBottom: 10,
    },
    articelSizesCardPrice: {
        alignSelf: "flex-end",
        marginTop: "auto",
        textAlign: "right",
    },
    articleSizeCardNextButton: {
        alignItems: "center",
        display: "flex",
        height: 130,
        justifyContent: "center",
        marginBottom: "auto",
        marginTop: 21,
        position: "absolute",
        right: "-45px",
        width: 50,
        zIndex: 2,
    },

    articleSizeCardPreviousButton: {
        alignItems: "center",
        display: "flex",
        height: 130,
        justifyContent: "center",
        left: "-45px",
        marginBottom: "auto",
        marginTop: 21,
        position: "absolute",
        width: 50,
        zIndex: 2,
    },

    // Sold Out
    articleSoldOutDisplay: {
        marginRight: 0,
        marginTop: 0,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        height: 40,
        width: "100%",
        borderRadius: 25,
    },
    articleImageWrapper: {
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
        borderRadius: 15,
        position: "relative",
        overflow: "hidden",
    },
    articleImageSoldOutBadge: {
        position: "absolute",
        right: -30,
        top: 24,
        transform: [{ rotate: "45deg" }],
        paddingHorizontal: 30,
        paddingVertical: 5,
    },
});
