import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";

const config = new Config();

export function getPaymentLink(uid, displayPayments = undefined, paymentTypesId = undefined) {
    const requestBody = {
        uid: uid,
        backendHost: config.backendHost,
        shopUrl: config.shopUrl.slice(-1) != "/" ? config.shopUrl + "/" : config.shopUrl,
        displayPayments: displayPayments,
        paymentTypesId: paymentTypesId,
    };

    if (displayPayments !== undefined) requestBody.displayPayments = [displayPayments];
    if (paymentTypesId !== undefined) requestBody.paymentTypesId = paymentTypesId;

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
        body: JSON.stringify(requestBody),
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getPaymentLink`, requestOptions).then((response) => handleResponse(response));
    };
}

export function createStripeSession(uid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            uid: uid,
            backendHost: config.backendHost,
            shopUrl: config.shopUrl.slice(-1) != "/" ? config.shopUrl + "/" : config.shopUrl,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Payment/createStripeSession", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function checkAndBookPayment(tid, uid) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            tid: tid,
            uid: uid,
        }),
    };
    return function (dispatch) {
        return fetch(config.backendHost + "/Payment/checkAndBookPayment", requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}

export function getWebshopPaymentTypes() {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    return function (dispatch) {
        return fetch(`${config.apiUrl}/getWebshopPaymentTypes`, requestOptions).then((response) =>
            handleResponse(response)
        );
    };
}
