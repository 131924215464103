import React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { Toast } from "react-native-toast-notifications";
import { getContrastColor } from "../../shared/helpers";
import { store } from "../../store/store";

const ToastMessage = ({ message = "", icon = "", backgroundColor = "#ffffff", textColor = "#000000" }) => {
    return (
        <View style={{ ...StyleSheet.flatten(styles.container), backgroundColor }}>
            {icon && <Ionicons name={icon} size={24} color={textColor} />}
            <Text style={{ ...StyleSheet.flatten(styles.text), color: textColor }}>{message}</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        borderRadius: 5,
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
        flexShrink: 1,
        pointerEvents: "none !important",
    },
    text: {
        fontFamily: "regular",
        marginLeft: 10,
        flexShrink: 1,
    },
});

const DEFAULT_DURATION = 5000;

const getToastConfig = (type, theme) => {
    let backgroundColor;

    switch (type) {
        case "success":
            backgroundColor = theme.colors.success;
            break;
        case "warning":
            backgroundColor = theme.colors.warning;
            break;
        case "error":
            backgroundColor = theme.colors.error;
            break;
        case "info":
        default:
            backgroundColor = theme.colors.surface;
    }

    const textColor = getContrastColor(backgroundColor);
    return { backgroundColor, textColor };
};

const getToastOptions = (type) => ({
    type,
    placement: "top",
    duration: DEFAULT_DURATION,
    offset: 1000,
    offsetTop: 3000,
    animationType: "slide-in",
    style: { backgroundColor: "transparent", pointerEvents: "none !important" },
});

export const showToast = (message, icon, type = "info") => {
    const { theme } = store.getState().settings;
    const { backgroundColor, textColor } = getToastConfig(type, theme);

    return Toast.show(
        <ToastMessage
            message={message}
            icon={icon}
            backgroundColor={backgroundColor}
            style={{ zIndex: 0 }}
            textColor={textColor}
        />,
        getToastOptions(type)
    );
};

export const updateToast = (toastId, message, icon, status) => {
    const { theme } = store.getState().settings;
    const { backgroundColor, textColor } = getToastConfig(status, theme);

    Toast.update(
        toastId,
        <ToastMessage
            message={message}
            icon={icon}
            backgroundColor={backgroundColor}
            style={{ zIndex: 0 }}
            textColor={textColor}
        />,
        getToastOptions(status)
    );
};

export default ToastMessage;
