import React, { Component } from "react";
import { connect } from "react-redux";
import { Dialog, Portal, Button, Paragraph, withTheme } from "react-native-paper";
import { hideSessionExpiredDialog, setLogoutFlag } from "../../store/actions/uiActions";
import { StyleSheet, View } from "react-native-web";
import { getContrastColor } from "../../shared/helpers";

class SessionExpiredDialog extends Component {
    handleLogin = () => {
        const { dispatch, login } = this.props;
        dispatch(setLogoutFlag(true));
        dispatch(hideSessionExpiredDialog());
        if (login) {
            login();
        } else {
            console.error("Login function is not provided");
        }
    };

    handleClose = () => {
        const { dispatch } = this.props;
        dispatch(hideSessionExpiredDialog());
    };

    render() {
        const { sessionExpiredDialogVisible, theme } = this.props;

        return (
            <Portal>
                {sessionExpiredDialogVisible && (
                    <View style={styles.container}>
                        <Dialog
                            visible={true}
                            dismissable={false}
                            style={{ maxWidth: "500px", alignSelf: "center", backgroundColor: theme.colors.surface }}
                        >
                            <Dialog.Title style={{ color: theme.colors.text }}>Session abgelaufen</Dialog.Title>
                            <Dialog.Content>
                                <Paragraph style={{ fontFamily: "regular", color: theme.colors.text }}>
                                    Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.
                                </Paragraph>
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button
                                    onPress={this.handleLogin}
                                    textColor={getContrastColor(theme.colors.primary)}
                                    style={[
                                        styles.button,
                                        {
                                            backgroundColor: theme.colors.primary,
                                            marginRight: "2%",
                                        },
                                    ]}
                                    uppercase={false}
                                    rippleColor="transparent"
                                >
                                    Anmelden
                                </Button>
                                <Button
                                    onPress={this.handleClose}
                                    color={theme.colors.primary}
                                    textColor={getContrastColor(theme.colors.primary)}
                                    style={[
                                        styles.button,
                                        {
                                            backgroundColor: theme.colors.primary,
                                        },
                                    ]}
                                    uppercase={false}
                                    rippleColor="transparent"
                                >
                                    Schließen
                                </Button>
                            </Dialog.Actions>
                        </Dialog>
                    </View>
                )}
            </Portal>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        maxHeight: "100vh",
        height: "100vh",
    },
    button: {
        marginBottom: 0,
        paddingHorizontal: 10,
        paddingVertical: 3,
    },
});

const mapStateToProps = (state) => ({
    sessionExpiredDialogVisible: state.uiReducer.sessionExpiredDialogVisible,
});

export default connect(mapStateToProps)(withTheme(SessionExpiredDialog));
