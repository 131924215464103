import React, { useState } from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { Icon, Text } from "react-native-paper";
import { DatePickerModal } from "react-native-paper-dates";
import moment from "moment";

function CustomDatePicker({
    label = "",
    selectedDate = moment(),
    handleDateChange = () => {},
    textColor = "black",
    backgroundColor = "white",
    borderColor = "black",
    icon = "calendar",
    iconColor = "black",
}) {
    const [open, setOpen] = useState(false);

    const formatDate = (date) => {
        return date ? date.format("DD.MM.YYYY") : "";
    };

    return (
        <View>
            <Text
                style={[
                    styles.labelStyle,
                    {
                        color: textColor || "black",
                    },
                ]}
            >
                {label}
            </Text>
            <Pressable
                onPress={() => setOpen(true)}
                style={[
                    styles.pressableStyle,
                    {
                        backgroundColor: backgroundColor || "white",
                        borderColor: borderColor || "black",
                    },
                ]}
            >
                <Text style={[styles.textStyle, { color: textColor || "black" }]}>{formatDate(selectedDate)}</Text>
                <Icon source={icon || "calendar"} size={20} color={iconColor || "black"} />
                {selectedDate && (
                    <DatePickerModal
                        mode="single"
                        visible={open}
                        onDismiss={() => setOpen(false)}
                        date={selectedDate.toDate()}
                        onConfirm={(date) => {
                            setOpen(false);
                            handleDateChange(date);
                        }}
                        locale={"de"}
                    />
                )}
            </Pressable>
        </View>
    );
}

const styles = StyleSheet.create({
    pressableStyle: {
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 2,
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 7,
    },
    textStyle: {
        flex: 1,
    },
    labelStyle: {
        fontSize: 16,
        fontWeight: "bold",
        marginBottom: 15,
    },
});

export default CustomDatePicker;
