import React, { useEffect, useRef } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Divider, IconButton, List, Surface, TextInput, useTheme } from "react-native-paper";
import FadeInOut from "../../helpers/animated/fadeInOut";
import { getContrastColor } from "../../shared/helpers";
import { store } from "../../store/store";
import { headerStyles } from "./HeaderStyle";
import { useHistory } from "../../routers/routing";
import { useWindowDimensions } from "react-native";
import _ from "lodash";

const INITIAL_SEARCH_RESULTS = {
    articles: [],
    recipes: [],
    categories: [],
};

export default function Search() {
    const [searchActive, setSearchActive] = React.useState(false);
    const [searchText, setSearchText] = React.useState("");
    const [searchResults, setSearchResults] = React.useState(INITIAL_SEARCH_RESULTS);

    const theme = useTheme();
    const history = useHistory();
    const dimension = useWindowDimensions();
    const searchInputRef = useRef(null);
    const blurTimeoutRef = useRef(null);

    let resultsHeight = dimension.height > 600 ? dimension.height / 2 : dimension.height - 90;

    const focusSearch = () => {
        searchInputRef.current.focus();
    };

    const deleteSearchInput = () => {
        setSearchText("");
    };

    const getSearchResults = _.debounce((text) => {
        let results = { ...INITIAL_SEARCH_RESULTS };
        let allCategories = [];
        let categoriesWithProducts = [];
        let articles = [];
        let recipes = [];

        if (text !== "") {
            let { onlineShopCategories } = store.getState();
            let keys = Object.keys(onlineShopCategories);
            let productKeys = keys.filter((key) => key.includes("articles_") || key.includes("recipes_"));

            for (let key of productKeys) {
                let shopItems = onlineShopCategories[key];
                let categories = shopItems[0];
                allCategories = [...allCategories, ...categories];
            }

            categoriesWithProducts = allCategories.filter((category) => category.OnlineShopItems.length > 0);

            for (let category of categoriesWithProducts) {
                let products = category.OnlineShopItems;
                let url = category.url;
                for (let product of products) {
                    product.url = url;
                    if (product.onlineShopItemType) {
                        recipes.push(product);
                    } else {
                        articles.push(product);
                    }
                }
            }

            articles = articles.filter((article, index, self) => self.findIndex((t) => t.id === article.id) === index);
            recipes = recipes.filter((recipe, index, self) => self.findIndex((t) => t.id === recipe.id) === index);

            let filteredArticles = articles.filter((article) =>
                article.name.toLowerCase().includes(text.toLowerCase())
            );
            let filteredRecipes = recipes.filter((recipe) => recipe.name.toLowerCase().includes(text.toLowerCase()));
            let filteredCategories = categoriesWithProducts.filter((category) =>
                category.name.toLowerCase().includes(text.toLowerCase())
            );

            results = {
                articles: filteredArticles,
                recipes: filteredRecipes,
                categories: filteredCategories,
            };
        }
        setSearchResults(results);
    }, 300);

    const goToDetails = (item) => {
        clearTimeout(blurTimeoutRef.current);
        history.push(
            "/ProductDetails/" +
                item.seoName +
                "/" +
                item.onlineShopItemType +
                "/" +
                item.id +
                "/" +
                encodeURIComponent(item.url)
        );
        setSearchText("");
    };

    const goToCategory = (item) => {
        clearTimeout(blurTimeoutRef.current);
        history.push("/category/" + item.seoName + "/" + item.categoriesId + "/" + encodeURIComponent(item.url));
        setSearchText("");
    };

    useEffect(() => {
        getSearchResults(searchText);
        setSearchActive(searchText.length > 2);
    }, [searchText]);

    return (
        <React.Fragment>
            <IconButton iconColor={getContrastColor(theme.colors.primary)} icon="magnify" onPress={focusSearch} />
            <TextInput
                style={
                    searchActive
                        ? {
                              ...StyleSheet.flatten(headerStyles.searchActiveTextInput),
                              backgroundColor: "transparent",
                          }
                        : headerStyles.searchInactiveTextInput
                }
                onChangeText={(text) => setSearchText(text)}
                onBlur={() => {
                    blurTimeoutRef.current = setTimeout(() => setSearchActive(false), 200);
                }}
                ref={searchInputRef}
                value={searchText}
                underlineColor="transparent"
                placeholder="Suche..."
                textColor={getContrastColor(theme.colors.primary)}
                placeholderTextColor={getContrastColor(theme.colors.primary)}
            />
            {searchText !== "" && (
                <IconButton
                    iconColor={getContrastColor(theme.colors.primary)}
                    icon="alpha-x-circle-outline"
                    onPress={deleteSearchInput}
                />
            )}
            <View
                style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    marginTop: 1,
                }}
            >
                {searchActive && (
                    <FadeInOut duration={300} show={searchActive}>
                        <Surface elevation={2} style={{ backgroundColor: "#fff" }}>
                            <View style={{ maxHeight: resultsHeight }}>
                                <ScrollView style={{ flex: 1 }}>
                                    {searchResults.categories.length > 0 && (
                                        <List.Section>
                                            <List.Subheader>Kategorien</List.Subheader>
                                            {searchResults.categories.map((result, index) => (
                                                <List.Item
                                                    key={index}
                                                    title={result.name}
                                                    left={(props) => <List.Icon {...props} icon="magnify" />}
                                                    right={(props) => <List.Icon {...props} icon="arrow-right" />}
                                                    description={result.onlineShopItemType ? "Rezepte" : "Artikel"}
                                                    onPress={() => goToCategory(result)}
                                                />
                                            ))}
                                        </List.Section>
                                    )}

                                    {searchResults.articles.length > 0 && (
                                        <List.Section>
                                            <Divider />
                                            <List.Subheader>Artikel</List.Subheader>
                                            {searchResults.articles.map((result, index) => (
                                                <List.Item
                                                    key={index}
                                                    title={result.name}
                                                    left={(props) => <List.Icon {...props} icon="magnify" />}
                                                    right={(props) => <List.Icon {...props} icon="arrow-right" />}
                                                    onPress={() => goToDetails(result)}
                                                />
                                            ))}
                                        </List.Section>
                                    )}

                                    {searchResults.recipes.length > 0 && (
                                        <List.Section>
                                            <Divider />
                                            <List.Subheader>Rezepte</List.Subheader>
                                            {searchResults.recipes.map((result, index) => (
                                                <List.Item
                                                    key={index}
                                                    title={result.name}
                                                    left={(props) => <List.Icon {...props} icon="magnify" />}
                                                    right={(props) => <List.Icon {...props} icon="arrow-right" />}
                                                    onPress={() => goToDetails(result)}
                                                />
                                            ))}
                                        </List.Section>
                                    )}

                                    {searchResults.categories.length === 0 &&
                                        searchResults.articles.length === 0 &&
                                        searchResults.recipes.length === 0 && (
                                            <List.Section>
                                                <List.Item
                                                    title="Keine Suchergebnisse"
                                                    left={(props) => <List.Icon {...props} icon="magnify" />}
                                                />
                                            </List.Section>
                                        )}
                                </ScrollView>
                            </View>
                        </Surface>
                    </FadeInOut>
                )}
            </View>
        </React.Fragment>
    );
}
