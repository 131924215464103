const { StyleSheet } = require("react-native");

export const styles = StyleSheet.create({
    addToCartDialogSelect: {
        fontFamily: "regular",
        paddingHorizontal: 15,
        paddingVertical: 7,
        borderRadius: 5,
    },
    addToCartDialogSelectLabel: {
        fontSize: 16,
        fontWeight: "bold",
        width: "100%",
        marginBottom: 15,
        textAlign: "center",
    },
    addToCartDialogButton: {
        paddingHorizontal: 15,
        paddingVertical: 2,
        marginLeft: 5,
    },
    addToCartImage: {
        height: 145,
        width: 145,
        marginRight: 0,
        borderRadius: 15,
    },
    addToCartArticleName: {
        flex: 1,
        flexWrap: "wrap",
        fontWeight: "bold",
        fontSize: 18,
    },
    addToCartArticleDetailsContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "stretch",
    },
    addToCartArticleDetailsAmountAndSize: {
        padding: 10,
        flex: 1,
        boxSizing: "border-box",
        justifyContent: "flex-end",
    },
    addToCartSizeStandardText: {
        textAlign: "center",
        marginTop: 5,
    },
    addToCartPriceBoxWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignSelf: "flex-end",
        flexGrow: 0,
        paddingHorizontal: 25,
        paddingTop: 15,
        paddingBottom: 25,
        borderRadius: 15,
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
        width: "100%",
    },
    addToCartPriceBoxInnerWrapper: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 0,
        marginTop: 0,
        alignItems: "center",
    },
    addToCartPriceBoxHeadline: {
        fontWeight: "bold",
    },
    addToCartPriceBoxPriceText: {
        fontSize: 40,
        marginRight: 0,
    },
    addToCartPriceBoxBasePriceText: {
        fontSize: 16,
        marginRight: 0,
        marginBottom: 0,
    },
    addToCartPriceBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "100%",
        marginBottom: 0,
        marginRight: 0,
    },
    addToCartArticleDetailsPriceContainer: {
        display: "flex",
        flex: 1,
        padding: 10,
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        // height: "100%",
    },
    amountContainer: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 0,
        width: "100%",
    },
    amountInputStyle: {
        borderRadius: 4,
        borderWidth: 1,
        flexGrow: 0,
        flexBasis: "60%",
        flexShrink: 1,
        height: 40,
        textAlign: "center",
        maxWidth: 200,
    },
    amountSmallInputStyle: {
        maxWidth: 100,
        height: 30,
    },
    amountButtonStyle: {
        alignItems: "center",
        borderRadius: 40,
        height: 40,
        justifyContent: "center",
        width: 40,
    },
    amountSmallButtonStyle: {
        height: 30,
        width: 30,
    },
    twoColContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
    col1Container: {
        width: "48%",
        justifyContent: "center",
        alignItems: "center",
    },
    col2Container: {
        width: "48%",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
});
