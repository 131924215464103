import React from "react";
import { View, Text } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { useSelector } from "react-redux";
import { customerDataStyles } from "../CustomerDataStyles";
import { styles } from "../../../shared/styles";
import { desktopBreakpoint } from "../../../shared/helpers";

const PaymentForm = ({ formProps, windowWidth, theme, setPaymentMethod }) => {
    const settings = useSelector((state) => state.settings);

    return (
        <View
            style={
                windowWidth <= desktopBreakpoint
                    ? customerDataStyles.containerFullRow
                    : customerDataStyles.containerSplitRow
            }
        >
            <View style={{ width: "100%" }}>
                <View style={{ paddingBottom: 10 }}>
                    <Text style={customerDataStyles.headlineText}>Bitte wählen Sie eine Zahlungsart</Text>
                    <Picker
                        id="paymentMethod"
                        title="Bitte wählen Sie eine Zahlungsart"
                        selectedValue={formProps.values.paymentMethod}
                        onValueChange={(value) => {
                            formProps.setFieldValue("paymentMethod", value);
                            setPaymentMethod(value);
                        }}
                        onBlur={formProps.handleBlur}
                        style={styles.picker}
                    >
                        {settings.store.availablePaymentTypes.map(({ name, identifier }) => (
                            <Picker.Item key={name} label={name} value={identifier} />
                        ))}
                    </Picker>
                    {formProps.errors.paymentMethod && (
                        <Text style={[customerDataStyles.helperText, { color: theme.colors.error }]}>
                            {formProps.errors.paymentMethod}
                        </Text>
                    )}
                </View>
            </View>
        </View>
    );
};

export default PaymentForm;
