import React from "react";
import { View } from "react-native";
import RenderHTML, { defaultSystemFonts } from "react-native-render-html";

const systemFonts = [...defaultSystemFonts, "regular"];

export default function ContentText({ text }) {
    if (text) {
        text = text.replaceAll(/font-family\s*:\s*[^;]+;?/gi, "font-family: regular;");

        // Replace strong tags within sup tags to avoid incorrect rendering and costum CSS
        text = text.replaceAll(/<sup\b[^>]*>(.*?)<\/sup>/g, function (match, p1) {
            var innerText = p1.replace(/<[^>]*>/g, "");
            return '<sup style="font-size: 20px; line-height: 8px; font-weight: bold">' + innerText + "</sup>";
        });

        text = text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
    }

    const systemFonts = [...defaultSystemFonts, "regular"];

    return (
        <View nativeID="contentText">
            {text && (
                <RenderHTML
                    source={{ html: htmlDecode(text) }}
                    tagsStyles={{
                        span: {
                            fontFamily: "regular",
                        },
                        div: {
                            fontFamily: "regular",
                        },
                    }}
                    systemFonts={systemFonts}
                />
            )}
        </View>
    );
}

function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}
