import { StyleSheet } from "react-native";

export const notFoundStyles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "1280px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 20,
        paddingVertical: 50,
    },
    text: {
        textAlign: "center",
        marginBottom: 25,
    },
});
