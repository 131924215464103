import React, { Component } from "react";
import { getContrastColor } from "../../shared/helpers";

/**
 * For original Theme-Colors see: https://github.com/orestbida/cookieconsent/blob/master/src/scss/abstracts/_dark-color-scheme.scss
 */

class CookieConsentTheme extends Component {
    static defaultProps = {
        theme: {
            colors: {
                primary: "#444444",
                accent: "#ffffff",
                error: "#B00020",
                text: "#000000",
                notification: "#db0f0f",
                surface: "#FFFFFF",
            },
            fonts: {
                regular: {
                    fontFamily: "regular",
                },
            },
        },
    };

    componentDidMount() {
        this.style = document.createElement("style");
        this.updateStyle();
        document.head.appendChild(this.style);
    }

    componentDidUpdate(prevProps) {
        if (this.props.theme !== prevProps.theme) {
            this.updateStyle();
        }
    }

    componentWillUnmount() {
        document.head.removeChild(this.style);
    }

    updateStyle() {
        if (this.props.theme && this.props.theme.colors && this.props.theme.fonts && this.props.theme.fonts.regular) {
            const { primary, accent, error, text, notification, surface } = this.props.theme.colors;
            const fontFamily = this.props.theme.fonts.regular.fontFamily;

            this.style.textContent = `
                #cc-main {
                    --cc-font-family: ${fontFamily};
                    --cc-btn-primary-bg: ${primary};
                    --cc-btn-primary-border-color: ${primary};
                    --cc-btn-primary-hover-bg: ${accent};
                    --cc-btn-primary-hover-border-color: ${primary};
                    --cc-btn-border-radius: 25px;

                    --cc-bg: ${surface};
                    --cc-primary-color: ${primary};
                    --cc-secondary-color: #5e6266;

                    --cc-btn-primary-bg: ${primary};
                    --cc-btn-primary-color: ${getContrastColor(primary)};
                    --cc-btn-primary-border-color: var(--cc-btn-primary-bg);
                    --cc-btn-primary-hover-bg: #000000;
                    --cc-btn-primary-hover-color: #ffffff;
                    --cc-btn-primary-hover-border-color: var(--cc-btn-primary-hover-bg);

                    --cc-btn-secondary-bg: #eaeff2;
                    --cc-btn-secondary-color: var(--cc-primary-color);
                    --cc-btn-secondary-border-color: var(--cc-btn-secondary-bg);
                    --cc-btn-secondary-hover-bg: #d4dae0;
                    --cc-btn-secondary-hover-color: #000000;
                    --cc-btn-secondary-hover-border-color: #d4dae0;

                    --cc-separator-border-color: #f0f4f7;

                    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
                    --cc-toggle-off-bg: ${primary};
                    --cc-toggle-on-knob-bg: #ffffff;
                    --cc-toggle-off-knob-bg: var(--cc-toggle-on-knob-bg);

                    --cc-toggle-enabled-icon-color: var(--cc-bg);   
                    --cc-toggle-disabled-icon-color: var(--cc-bg);  

                    --cc-toggle-readonly-bg: #d5dee2;
                    --cc-toggle-readonly-knob-bg: #fff;
                    --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);

                    --cc-section-category-border: var(--cc-cookie-category-block-bg);

                    --cc-cookie-category-block-bg: #f0f4f7;
                    --cc-cookie-category-block-border: #f0f4f7;
                    --cc-cookie-category-block-hover-bg: #e9eff4;
                    --cc-cookie-category-block-hover-border: #e9eff4;
                    --cc-cookie-category-expanded-block-bg: transparent;
                    --cc-cookie-category-expanded-block-hover-bg: #dee4e9;

                    --cc-overlay-bg: rgba(0, 0, 0, 0.65);
                    --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
                    --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);

                    --cc-footer-bg: var(--cc-btn-secondary-bg);
                    --cc-footer-color: var(--cc-secondary-color);
                    --cc-footer-border-color: #e4eaed;
                }
        `;
        }
    }

    render() {
        return "";
    }
}

export default CookieConsentTheme;
