import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { TextInput, HelperText } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";

export default function CustomDropdown({
    disabled = false,
    error = false,
    helperText = "",
    id = "",
    label = "",
    onChange = () => {},
    placeholder = "",
    style = {},
    value = "",
    items = [],
    mode = "outlined",
}) {
    const normalizedValue = value?.toString();

    const selectedItem = items.find((item) => item.value.toString() === normalizedValue);

    const isSingleItem = items.length === 1;

    return (
        <>
            <View style={[style]}>
                <TextInput
                    disabled={disabled || isSingleItem}
                    error={error}
                    label={label}
                    value={selectedItem ? selectedItem.label : ""}
                    style={styles.input}
                    mode={mode}
                    editable={false}
                    pointerEvents={isSingleItem ? "none" : "auto"}
                    theme={{
                        colors: {
                            background: "#eee",
                        },
                    }}
                />
                {!isSingleItem && (
                    <Picker
                        selectedValue={normalizedValue}
                        onValueChange={(itemValue) => {
                            onChange(itemValue);
                        }}
                        enabled={!disabled}
                        style={styles.picker}
                        mode="dropdown"
                    >
                        {items.map((item) => (
                            <Picker.Item key={item.value.toString()} label={item.label} value={item.value.toString()} />
                        ))}
                    </Picker>
                )}
            </View>
            {helperText ? (
                <HelperText type="error" visible={error} style={styles.helperText}>
                    {helperText}
                </HelperText>
            ) : null}
        </>
    );
}

const styles = StyleSheet.create({
    input: {
        backgroundColor: "#eee",
        color: "black",
        borderWidth: 0,
        borderRadius: 5,
        height: 50,
    },
    picker: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
    },
    helperText: {
        marginBottom: 0,
    },
});
