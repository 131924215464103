import React, { Component } from "react";
import { Button, DataTable, IconButton, withTheme, Text } from "react-native-paper";
import { createReadableAndSafeURL, withHooksHOC } from "../../../shared/helpers";
import { withRouter } from "react-router-dom";
import { View } from "react-native";

class Ingredients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ingredientAdded: false,
            selectedIndex: undefined,
            windowWidth: window.innerWidth,
        };

        this.addIngredientToCart = this.addIngredientToCart.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    async addIngredientToCart(article, index) {
        this.setState({ ingredientAdded: true, selectedIndex: index });
        await delay(500);
        this.setState({ ingredientAdded: false, selectedIndex: index });
        this.props.addIngredientToCart(article);
    }

    handleItemClick(item) {
        const { history } = this.props;

        if (this.props.ingredientsInOnlineShop.OnlineShopItems) {
            const onlineShopItem = this.props.ingredientsInOnlineShop.OnlineShopItems.find(
                (shopItem) => shopItem.name === item.name
            );

            if (onlineShopItem) {
                const url = `/ProductDetails/${createReadableAndSafeURL(item.name)}/${
                    onlineShopItem.onlineShopItemType
                }/${onlineShopItem.id}/${encodeURIComponent(this.props.match.params.backend)}`;
                history.push(url);
            }
        }
    }

    render() {
        var addToCardButtonsVisible = false;
        const { windowSize, theme, ingredientsInOnlineShop } = this.props;
        const mobile = windowSize[0] < 700;

        if (this.props.data) {
            this.props.data.forEach((item) => {
                if (
                    item.article &&
                    item.article.webshopEnabled &&
                    item.article.ArticleSizes.find((size) => size.Size && !size.Size.isDefault) &&
                    item.article.ArticleSizes.find((size) => size.Size && !size.Size.isDefault).Prices.length > 0
                ) {
                    addToCardButtonsVisible = true;
                }
            });
        }

        const numberOfRows = this.props.data ? this.props.data.length : 0;

        return (
            <View style={{ width: "100%", backgroundColor: this.state.windowWidth > 800 ? "inherit" : "#dddddd" }}>
                <DataTable>
                    {numberOfRows === 0 && (
                        <DataTable.Row style={{ cursor: "default", borderBottomWidth: 0 }}>
                            <DataTable.Cell style={{ cursor: "default", borderBottomWidth: 0 }}>Keine</DataTable.Cell>
                        </DataTable.Row>
                    )}
                    {numberOfRows > 0 && (
                        <>
                            {this.props.isArticle &&
                                this.props.data.map((item, index) => {
                                    const isLinkItem = this.isLink(item);
                                    const isLastRow = index === numberOfRows - 1;
                                    const rowStyle = {
                                        borderBottomWidth: numberOfRows === 1 || isLastRow ? 0 : 1,
                                        borderBottomColor: theme.colors.divider,
                                    };

                                    return (
                                        <DataTable.Row
                                            key={index}
                                            style={{ ...rowStyle, cursor: isLinkItem ? "pointer" : "default" }}
                                        >
                                            <DataTable.Cell
                                                onPress={isLinkItem ? () => this.handleItemClick(item) : null}
                                                style={{ cursor: isLinkItem ? "pointer" : "default" }}
                                            >
                                                {isLinkItem && (
                                                    <IconButton
                                                        icon="cart-arrow-right"
                                                        size={18}
                                                        style={{
                                                            margin: 0,
                                                            marginLeft: -8,
                                                            padding: 0,
                                                        }}
                                                    />
                                                )}
                                                <Text>{item.name}</Text>
                                            </DataTable.Cell>
                                            {addToCardButtonsVisible && !this.state.ingredientAdded && (
                                                <DataTable.Cell
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        cursor: "default",
                                                    }}
                                                >
                                                    {item.article &&
                                                        item.article.webshopEnabled &&
                                                        item.article.ArticleSizes.find(
                                                            (size) => size.Size && !size.Size.isDefault
                                                        ) &&
                                                        item.article.ArticleSizes.find(
                                                            (size) => size.Size && !size.Size.isDefault
                                                        ).Prices.length > 0 && (
                                                            <React.Fragment>
                                                                {!mobile && (
                                                                    <Button
                                                                        icon="plus"
                                                                        mode="contained"
                                                                        onPress={() =>
                                                                            this.addIngredientToCart(
                                                                                item.article,
                                                                                index
                                                                            )
                                                                        }
                                                                        style={{ cursor: "default" }}
                                                                    >
                                                                        in den Warenkorb
                                                                    </Button>
                                                                )}
                                                                {mobile && (
                                                                    <IconButton
                                                                        icon="cart"
                                                                        iconColor={theme.colors.primary}
                                                                        onPress={() =>
                                                                            this.addIngredientToCart(
                                                                                item.article,
                                                                                index
                                                                            )
                                                                        }
                                                                        style={{ cursor: "default" }}
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                </DataTable.Cell>
                                            )}
                                            {addToCardButtonsVisible &&
                                                this.state.ingredientAdded &&
                                                this.state.selectedIndex === index && (
                                                    <DataTable.Cell
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            cursor: "default",
                                                        }}
                                                    >
                                                        <React.Fragment>
                                                            {!mobile && (
                                                                <Button
                                                                    icon="check"
                                                                    mode="contained"
                                                                    style={{ cursor: "default" }}
                                                                >
                                                                    hinzugefügt
                                                                </Button>
                                                            )}
                                                            {mobile && (
                                                                <IconButton
                                                                    icon="check"
                                                                    iconColor={theme.colors.primary}
                                                                    style={{ cursor: "default" }}
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    </DataTable.Cell>
                                                )}
                                        </DataTable.Row>
                                    );
                                })}
                            {!this.props.isArticle && (
                                <DataTable.Header>
                                    <DataTable.Title>Name</DataTable.Title>
                                    {addToCardButtonsVisible && <DataTable.Title></DataTable.Title>}
                                    <DataTable.Title numeric>Menge</DataTable.Title>
                                </DataTable.Header>
                            )}
                            {!this.props.isArticle &&
                                this.props.data.map((item, index) => {
                                    const isLinkItem = this.isLink(item);
                                    const isLastRow = index === numberOfRows - 1;
                                    const rowStyle = {
                                        borderBottomWidth: numberOfRows === 1 || isLastRow ? 0 : 1,
                                        borderBottomColor: theme.colors.divider,
                                        cursor: "default",
                                    };

                                    return (
                                        <DataTable.Row key={index} style={rowStyle}>
                                            <DataTable.Cell
                                                onPress={isLinkItem ? () => this.handleItemClick(item) : null}
                                                rippleColor="transparent"
                                                style={
                                                    isLinkItem
                                                        ? { cursor: "pointer", width: "100%" }
                                                        : { cursor: "default" }
                                                }
                                            >
                                                {isLinkItem && (
                                                    <IconButton
                                                        icon="cart-arrow-right"
                                                        size={18}
                                                        style={{
                                                            margin: 0,
                                                            marginLeft: -8,
                                                            padding: 0,
                                                        }}
                                                    />
                                                )}
                                                <Text style={{ lineHeight: 50 }}>{item.name}</Text>
                                            </DataTable.Cell>
                                            {addToCardButtonsVisible &&
                                                (!this.state.ingredientAdded || this.state.selectedIndex !== index) && (
                                                    <DataTable.Cell
                                                        style={{ display: "flex", justifyContent: "center" }}
                                                    >
                                                        {item.article &&
                                                            item.article.webshopEnabled &&
                                                            item.article.ArticleSizes.find(
                                                                (size) => size.Size && !size.Size.isDefault
                                                            ) &&
                                                            item.article.ArticleSizes.find(
                                                                (size) => size.Size && !size.Size.isDefault
                                                            ).Prices.length > 0 && (
                                                                <React.Fragment>
                                                                    {!mobile && (
                                                                        <Button
                                                                            icon="plus"
                                                                            mode="contained"
                                                                            onPress={() =>
                                                                                this.addIngredientToCart(
                                                                                    item.article,
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            in den Warenkorb
                                                                        </Button>
                                                                    )}
                                                                    {mobile && (
                                                                        <IconButton
                                                                            icon="cart"
                                                                            iconColor={theme.colors.primary}
                                                                            onPress={() =>
                                                                                this.addIngredientToCart(
                                                                                    item.article,
                                                                                    index
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                    </DataTable.Cell>
                                                )}
                                            {addToCardButtonsVisible &&
                                                this.state.ingredientAdded &&
                                                this.state.selectedIndex === index && (
                                                    <DataTable.Cell
                                                        style={{ display: "flex", justifyContent: "center" }}
                                                    >
                                                        <React.Fragment>
                                                            {!mobile && (
                                                                <Button icon="check" mode="contained">
                                                                    hinzugefügt
                                                                </Button>
                                                            )}
                                                            {mobile && (
                                                                <IconButton
                                                                    icon="check"
                                                                    iconColor={theme.colors.primary}
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    </DataTable.Cell>
                                                )}

                                            <DataTable.Cell style={{ cursor: "default" }} numeric>
                                                {item.grammage} g
                                            </DataTable.Cell>
                                        </DataTable.Row>
                                    );
                                })}
                        </>
                    )}
                </DataTable>
            </View>
        );
    }

    isLink(item) {
        return (
            this.props.ingredientsInOnlineShop.OnlineShopItems &&
            this.props.ingredientsInOnlineShop.OnlineShopItems.some((shopItem) => shopItem.name === item.name)
        );
    }
}

function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export default withHooksHOC(withRouter(withTheme(Ingredients)));
