import React from "react";
import { View } from "react-native";
import { List, useTheme } from "react-native-paper";
import { styles } from "./AdditionalProductDetailsStyles";
import Additives from "./Additives";
import Ingredients from "./Ingredients";
import NutritionalValues from "./NutritionalValues";
import { getContrastColor } from "../../../shared/helpers";

export default function AccordionView({ product, additives, addIngredientToCart, ingredientsInOnlineShop }) {
    const theme = useTheme();
    const accordionTheme = {
        ...theme,
        colors: {
            ...theme.colors,
            background: theme.colors.backdrop,
            onBackground: "rgb(225, 225, 225)",
        },
    };

    return (
        <View style={styles.accordionContainer}>
            <List.Section style={styles.listSection}>
                {product.ingredients && (
                    <List.Accordion
                        title="Zutaten"
                        theme={accordionTheme}
                        titleStyle={{ color: getContrastColor(theme.colors.backdrop) }}
                    >
                        <View>
                            <Ingredients
                                data={product.ingredients}
                                isArticle={product.onlineShopItemType === 0}
                                addIngredientToCart={addIngredientToCart}
                                ingredientsInOnlineShop={ingredientsInOnlineShop}
                            />
                        </View>
                    </List.Accordion>
                )}
                <List.Accordion
                    title="Zusatzstoffe"
                    theme={accordionTheme}
                    titleStyle={{ color: getContrastColor(theme.colors.backdrop) }}
                >
                    <View style={styles.accordionContentContainer}>
                        <Additives
                            data={
                                additives.length === 0
                                    ? []
                                    : additives.filter(
                                          (additive) =>
                                              additive.AdditiveGroup.name === "Zusatzstoffe" && additive.isChecked
                                      )
                            }
                        />
                    </View>
                </List.Accordion>
                <List.Accordion
                    title="Allergene"
                    theme={accordionTheme}
                    titleStyle={{ color: getContrastColor(theme.colors.backdrop) }}
                >
                    <View style={styles.accordionContentContainer}>
                        <Additives
                            data={
                                additives.length === 0
                                    ? []
                                    : additives.filter(
                                          (additive) =>
                                              additive.AdditiveGroup.name === "Allergene" && additive.isChecked
                                      )
                            }
                        />
                    </View>
                </List.Accordion>
                <List.Accordion
                    title="Nährwerte"
                    theme={accordionTheme}
                    titleStyle={{ color: getContrastColor(theme.colors.backdrop) }}
                >
                    <View style={styles.accordionContentContainer}>
                        <NutritionalValues data={product.nutritionalValues} />
                    </View>
                </List.Accordion>
            </List.Section>
        </View>
    );
}
