import { createReadableAndSafeURL } from "../../shared/helpers";
import Config from "../../../Config";
import { handleResponse } from "../../helpers/requestHelpers";
import { store } from "../../store/store";
import { fetchWithSessionHandling } from "../../helpers/sessionHandling/fetchWithSessionHandling";

const config = new Config();

export function getArticlesForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return async function (dispatch) {
        try {
            const response = await fetch(`${config.apiUrl}/getArticlesForOnlineShop`, requestOptions);
            const json = await handleResponse(response);
            var data = json.map((item) => {
                const updatedItem = {
                    ...item,
                    seoName: createReadableAndSafeURL(item.name),
                    OnlineShopItems: item.OnlineShopItems.map((shopItem) => ({
                        ...shopItem,
                        seoName: createReadableAndSafeURL(shopItem.name),
                    })),
                };
                return updatedItem;
            });
            return store.dispatch(setArticles(data, config.backendHost));
        } catch (err) {
            console.log(err);
        }
    };
}

export function getRecipesForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return async function (dispatch) {
        try {
            const response = await fetch(`${config.apiUrl}/getRecipesForOnlineShop`, requestOptions);
            const json = await handleResponse(response);
            var data = json.map((item) => {
                const updatedItem = {
                    ...item,
                    seoName: createReadableAndSafeURL(item.name),
                    OnlineShopItems: item.OnlineShopItems.map((shopItem) => ({
                        ...shopItem,
                        seoName: createReadableAndSafeURL(shopItem.name),
                    })),
                };
                return updatedItem;
            });
            return store.dispatch(setRecipes(data, config.backendHost));
        } catch (err) {
            console.log(err);
        }
    };
}

export function getRecommendationsForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return async function (dispatch) {
        try {
            const response = await fetch(
                `${config.apiUrl}/getRecommendationsForOnlineShop`,
                requestOptions
            );
            const json = await handleResponse(response);
            if (json && Array.isArray(json)) {
            var data = json.map((item) => {
                const updatedItem = {
                    ...item,
                    seoName: createReadableAndSafeURL(item.name),
                    OnlineShopItems: item.OnlineShopItems.map((shopItem) => ({
                        ...shopItem,
                        seoName: createReadableAndSafeURL(shopItem.name),
                    })),
                };
                return updatedItem;
            });
            return store.dispatch(setRecommendations(data, config.backendHost));
        } else {
            return [];
        }
        } catch (err) {
            console.log(err);
        }
    };
}

export function getCurrentRecommendationsForOnlineShop(instanceUrl) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken, 
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return async function (dispatch) {
        try {
            const response = await fetch(`${config.apiUrl}/getCurrentRecommendationsForOnlineShop`,
                requestOptions
            );
            const json = await handleResponse(response);

            if (json && json.OnlineShopItems && Array.isArray(json.OnlineShopItems)) {
                const updatedJson = {
                    ...json,
                    seoName: createReadableAndSafeURL(json.name),
                    OnlineShopItems: json.OnlineShopItems.map((shopItem) => ({
                        ...shopItem,
                        seoName: createReadableAndSafeURL(shopItem.name),
                    })),
                };

                return store.dispatch(setCurrentRecommendations(updatedJson, config.backendHost));
            } else {
                console.error("JSON hat nicht die erwartete Struktur oder OnlineShopItems ist kein Array.");
            }
        } catch (err) {
            console.log(err);
        }
    };
}

export function addRecipesToWeeklyPlan(recipesToWeeklyPlanDto, keycloak) {
    const requestOptions = {
        method: "POST",
        headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
         },
        body: JSON.stringify(recipesToWeeklyPlanDto),
    };

    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/addRecipesToWeeklyPlan`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function updateCookingProcess(updateCookingProcessDto, keycloak) {
    const requestOptions = {
        method: "POST",
        headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
         },
        body: JSON.stringify(updateCookingProcessDto),
    };

    return function (dispatch) {
        return fetchWithSessionHandling(`${config.apiUrl}/updateCookingProcess`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getCategoryById(instanceUrl, categoriesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/GetCategoryById/${categoriesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getArticleSizesByRecipesId(instanceUrl, recipesId) {
    const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(config.backendHost + "/OnlineShop/GetArticleSizesByRecipesId/" + recipesId, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getIngredientsInOnlineShopwithRecipesId(instanceUrl, recipesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getIngredientsInOnlineShopWithRecipesId/${recipesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getIngredientsInOnlineShopwithArticlesId(instanceUrl, articlesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getIngredientsInOnlineShopWithArticlesId/${articlesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getBiosInstances() {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    };

    return function (dispatch) {
        return fetch(config.backendHost + "/Licenses/getBiosInstances", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getRecipesForOnlineShopForWeeklyPlan() {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getRecipesForOnlineShopForWeeklyPlan`, requestOptions).then(
            (response) => handleResponse(response),
            (err) => console.log(err)
        );
    };
}

export function getSimilarArticles(instanceUrl, articlesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getSimilarArticles/${articlesId}`, requestOptions)
            .then((response) => handleResponse(response))
            .then((json) => json);
    };
}

export function getOnlineShopItemByArticlesId(instanceUrl, articlesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getOnlineShopItemByArticlesId/${articlesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getOnlineShopItemByRecipesId(instanceUrl, recipesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getOnlineShopItemByRecipesId/${recipesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getSimilarRecipes(instanceUrl, articlesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getSimilarRecipes/${articlesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getAltAndTitleByGuidFilenName(instanceUrl, guidFileName) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(guidFileName),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(config.backendHost + "/media/getAltAndTitleByGuidFilenName", requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getArticleRatings(instanceUrl, articlesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getArticleRatings/${articlesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getRecipeRatings(instanceUrl, recipesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getRecipeRatings/${recipesId}`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function rateArticle(instanceUrl, articlesId, rating, ratingText, keycloak) {
    const requestOptions = {
        method: "POST",
        headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
         },
        body: JSON.stringify({
            articlesId: articlesId,
            rating: rating,
            ratingText: ratingText,
        }),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/rateArticle`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function rateRecipe(instanceUrl, articlesId, rating, ratingText, keycloak) {
    const requestOptions = {
        method: "POST",
        headers: { 
            "Content-Type": "application/json",
            Authorization: `Bearer ${keycloak.token}`,
            shopIdentification: config.biosShopToken,
         },
        body: JSON.stringify({
            articlesId: articlesId,
            rating: rating,
            ratingText: ratingText,
        }),
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/rateRecipe`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}


export const SET_ONLINESHOPITEMTYPE = "SET_ONLINESHOPITEMTYPE";
export const setOnlineShopItemType = (type) => {
    return {
        type: SET_ONLINESHOPITEMTYPE,
        onlineShopItemType: type,
    };
};

export const SET_RECIPES = "SET_RECIPES";
export const setRecipes = (categories, url) => {
    return {
        type: SET_RECIPES,
        payload: categories,
        url: url,
    };
};
export const SET_ARTICLES = "SET_ARTICLES";
export const setArticles = (categories, url) => {
    return {
        type: SET_ARTICLES,
        payload: categories,
        url: url,
    };
};
export const SET_RECOMMENDATIONS = "SET_RECOMMENDATIONS";
export const setRecommendations = (recommendations, url) => {
    return {
        type: SET_RECOMMENDATIONS,
        payload: recommendations,
        url: url,
    };
};
export const SET_CURRENT_RECOMMENDATIONS = "SET_CURRENT_RECOMMENDATIONS";
export const setCurrentRecommendations = (currentRecommendations) => {
    return {
        type: SET_CURRENT_RECOMMENDATIONS,
        payload: currentRecommendations,
    };
};

export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";
export const setCurrentCategory = (currentCategory) => {
    return {
        type: SET_CURRENT_CATEGORY,
        payload: currentCategory,
    };
};

export function getArticleDetailsByArticlesId(instanceUrl, articlesId) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(
            `${config.apiUrl}/getArticleDetailsByArticlesId/${articlesId}`,
            requestOptions
        )
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}

export function getAssignedNutritionalValues(instanceUrl) {
    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json",
            shopIdentification: config.biosShopToken,
        },
    };
    if (process.env.NODE_ENV === "development") {
        instanceUrl = "http://localhost:5000/b";
    }

    return function (dispatch) {
        return fetch(`${config.apiUrl}/getAssignedNutritionalValues`, requestOptions)
            .then(
                (response) => handleResponse(response),
                (err) => console.log(err)
            )
            .then((json) => json);
    };
}
