import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import {
    Dimensions,
    FlatList,
    Image,
    ImageBackground,
    Platform,
    Pressable,
    SafeAreaView,
    ScrollView,
    StyleSheet,
    TextInput,
    View,
} from "react-native";
import { ActivityIndicator, Button, Card, FAB, Icon, Text, Title, withTheme } from "react-native-paper";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Ionicons } from "@expo/vector-icons";
import Config from "../../../Config";
import CustomIconButton from "../../helpers/IconButton/CustomIconButton";
import { showToast } from "../../helpers/toastMessage/ToastMessage";
import {
    checkIfArticleSizeHasPrices,
    desktopBreakpoint,
    formatNumberToLocalString,
    getContrastColor,
    withHooksHOC,
} from "../../shared/helpers";
import {
    getArticleDetailsByArticlesId,
    getArticleRatings,
    getIngredientsInOnlineShopwithArticlesId,
    getIngredientsInOnlineShopwithRecipesId,
    getOnlineShopItemByArticlesId,
    getOnlineShopItemByRecipesId,
    getRecipeRatings,
    getSimilarArticles,
    getSimilarRecipes,
    rateArticle,
    rateRecipe,
} from "../../store/actions/onlineShopCategoriesActions";
import { store } from "../../store/store";
import AdditionalProductDetails from "./AdditionalProductDetails/AdditionalProductDetails";
import ArticleDetails from "./ArticleDetails/ArticleDetails";
import Category from "./Category";
import { ProductDetailsStyles } from "./ProdctDetailsStyles";
import { ShopStyles } from "./ShopStyles";
import AmountSelection from "./addToCartDialog/AmountSelection";

const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
});

class ProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: undefined,
            itemType: 0,
            similiarProducts: undefined,
            infoToDisplay: undefined,
            infoDisplayed: 0,
            ingredientsInOnlineShop: undefined,
            rateArticleDialogVisible: false,
            reviewPopupVisible: false,
            articleRating: 0,
            customerRating: { rating: 0 },
            filteredRatings: [],
            ratings: [],
            numberOfReviews: 3,
            loading: true,
            articleDetailsRows: undefined,

            totalPrices: 0,
            totalArticleSizes: 0,
            selectedArticleSize: null,
            selectedPrice: 0,
            selectedPricePer100: 0,
            selectedPortionSize: 0,
            selectedDisplayName: "",
            showMore: false,
            scrollingPosition: 0,
            visibleTiles: 0,
            tileWidth: 146,
            isManualScrolling: false,
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            firstTriggerPoint: 0,
            secondTriggerPoint: 0,
            priceBoxHeight: 0,
            priceBoxWidth: 0,
            distanceFromRight: 0,
            showPriceBoxSwitcher: false,
            priceBoxVisible: true,
            forceShowPriceBox: false,
            amount: 1,
            id: undefined,
        };

        this.rateArticle = this.rateArticle.bind(this);
        this.loadItem = this.loadItem.bind(this);
        this.addShopHeaderItem = this.addShopHeaderItem.bind(this);
        this.toggleRateArticleDialog = this.toggleRateArticleDialog.bind(this);
        this.showMoreReviews = this.showMoreReviews.bind(this);
        this.showLessReviews = this.showLessReviews.bind(this);
        this.filterRatings = this.filterRatings.bind(this);
        this.showAddRecipeToWeeklyPlanPopup = this.showAddRecipeToWeeklyPlanPopup.bind(this);
        this.showAddArticleToCartPopup = this.showAddArticleToCartPopup.bind(this);
        this.cookRecipe = this.cookRecipe.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.loadArticleDetails = this.loadArticleDetails.bind(this);
        this.toggleShowMore = this.toggleShowMore.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.flatListRef = React.createRef();
        this.scrollLeft = this.scrollLeft.bind(this);
        this.scrollRight = this.scrollRight.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.updateVisibleTiles = this.updateVisibleTiles.bind(this);
        this.updateSelectedArticleSize = this.updateSelectedArticleSize.bind(this);
        this.firstTriggerRef = React.createRef();
        this.secondTriggerRef = React.createRef();
        this.scrollViewRef = React.createRef();
        this.checkScrollPosition = this.checkScrollPosition.bind(this);
        this.onLayoutFirstTrigger = this.onLayoutFirstTrigger.bind(this);
        this.calculateDistanceFromRight = this.calculateDistanceFromRight.bind(this);
        this.showPriceBoxViaButton = this.showPriceBoxViaButton.bind(this);
        this.addArticleToCart = this.addArticleToCart.bind(this);
    }

    addArticleToCart(selectedArticleSize, article, amount) {
        // this.props.addArticleToCart(this.state.selectedArticleSize, this.props.selectedArticle, this.state.amount);
        this.props.addArticleToCart(
            selectedArticleSize,
            article,
            amount,
            this.props.settings.store.orderTypes,
            this.props.settings.store.priceGroups.priceGroupsId
        );
    }

    toggleRateArticleDialog() {
        document.body.style.overflow = !this.state.rateArticleDialogVisible ? "hidden" : "auto";
        this.setState({ rateArticleDialogVisible: !this.state.rateArticleDialogVisible });
    }

    showMoreReviews() {
        let newNumber = this.state.numberOfReviews === 3 ? 10 : this.state.numberOfReviews + 10;
        this.setState({ numberOfReviews: newNumber });
    }

    showLessReviews() {
        this.setState({ numberOfReviews: 3 });
    }

    showAddRecipeToWeeklyPlanPopup(recipe, selectedArticleSize) {
        this.props.showAddRecipeToWeeklyPlanPopup(
            recipe,
            this.props.account.customer.customerUid,
            undefined,
            selectedArticleSize
        );
    }

    showAddArticleToCartPopup(article, selectedArticleSize) {
        this.props.showAddArticleToCartPopup(
            article,
            this.props.account.customer ? this.props.account.customer.customerUid : undefined,
            this.props.settings.store.orderTypes,
            this.props.settings.store.priceGroups.priceGroupsId,
            selectedArticleSize
        );
    }

    cookRecipe(recipe, selectedArticleSize) {
        if (!this.props.account || !this.props.account.customer) {
            this.props.toggleLoginPopup();
        } else {
            this.props.cookRecipe(recipe, this.props.account.customer.customerUid, selectedArticleSize);
        }
    }

    isValidId(id) {
        return id && !isNaN(id) && parseInt(id) > 0;
    }

    handleInvalidId() {
        console.error("Ungültige ID");
        this.props.history.push("/404");
    }

    setLoadingState(id) {
        this.setState({
            id: id,
            loading: true,
        });
    }

    handleResult(result) {
        if (result && Object.keys(result).length > 0 && result.status !== 404) {
            this.setState({ item: result, infoToDisplay: result.ingredients, loading: false }, () => {
                this.loadArticleDetails();
            });
        } else {
            this.props.history.push("/404");
            return;
        }

        if (result && result.status !== 404 && result.articleSizes) {
            const sortedArticleSizes = result.articleSizes.sort((a, b) => a.Size.sortorder - b.Size.sortorder);
            const articleSize = sortedArticleSizes.find((a) => a.Prices.length > 0);

            if (articleSize) {
                const articlePrice = articleSize.Prices.find(
                    (p) =>
                        p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                        p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                );

                this.setState({
                    selectedArticleSize: articleSize,
                    selectedPortionSize: articleSize.portionSize,
                    selectedDisplayName: articleSize.Size.displayName,
                });

                if (articlePrice) {
                    this.setState({
                        selectedPrice: articlePrice.price1,
                        selectedPricePer100: (articlePrice.price1 / articleSize.portionSize) * 100,
                    });
                }
            } else {
                this.setState({
                    selectedArticleSize: sortedArticleSizes[0],
                    selectedPortionSize: sortedArticleSizes[0].portionSize,
                    selectedDisplayName: sortedArticleSizes[0].Size.displayName,
                });
            }
            let prices = result.articleSizes
                .filter((a) =>
                    a.Prices.some(
                        (p) =>
                            p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                            p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                    )
                )
                .flatMap((a) =>
                    a.Prices.filter(
                        (p) =>
                            p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                            p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                    ).map((p) => ({
                        price: p.price1,
                        pricePer100: (p.price1 / a.portionSize) * 100,
                        portionSize: a.portionSize,
                        displayName: a.Size.displayName,
                    }))
                );

            this.setState({ totalPrices: prices.length, totalArticleSizes: result.articleSizes.length });
        }
    }

    handleSimilarResult(result) {
        if (result && result.status !== 404) {
            this.setState({ similiarProducts: result });
        }
    }

    handleIngredientsResult(result) {
        if (result && result.status !== 404) {
            this.setState({ ingredientsInOnlineShop: result });
        }
    }

    handleRatingResult(result) {
        if (result && result.status !== 404) {
            if (this.props.account.customer && this.props.account.customer.customerUid)
                this.setState({
                    customerRating: result.filter(
                        (rating) => rating.customerUid === this.props.account.customer.customerUid
                    ),
                });
            this.setState({ ratings: result, filteredRatings: result });
        }
    }

    loadItem() {
        const { params } = this.props.match;

        if (!this.isValidId(params.id)) {
            this.handleInvalidId();
            return;
        }

        this.setLoadingState(params.id);

        if (params.type === "0") {
            this.loadArticle(params);
        } else if (params.type === "1") {
            this.loadRecipe(params);
        }

        if (this.state.item && this.state.item.articleSizes.length > 0) {
            const defaultArticleSize = this.state.item.articleSizes[0];
            this.updateSelectedArticleSize(defaultArticleSize);
        }
    }

    loadArticle(params) {
        const paramsId = parseInt(params.id);
        store
            .dispatch(getOnlineShopItemByArticlesId(decodeURIComponent(params.backend), paramsId))
            .then((itemResult) => {
                this.handleResult(itemResult);
                if (itemResult && Object.keys(itemResult).length > 0 && itemResult.status !== 404) {
                    return Promise.all([
                        store.dispatch(getSimilarArticles(decodeURIComponent(params.backend), paramsId)),
                        store.dispatch(
                            getIngredientsInOnlineShopwithArticlesId(decodeURIComponent(params.backend), paramsId)
                        ),
                        store.dispatch(getArticleRatings(decodeURIComponent(params.backend), paramsId)),
                    ]);
                } else {
                    console.error("No item result");
                }
            })
            .then(([similarResult, ingredientsResult, ratingsResult]) => {
                this.handleSimilarResult(similarResult);
                this.handleIngredientsResult(ingredientsResult);
                this.handleRatingResult(ratingsResult);
            })
            .catch((error) => {
                console.error(error);
                this.props.history.push("/404");
            });
    }

    loadRecipe(params) {
        const paramsId = parseInt(params.id);
        store
            .dispatch(getOnlineShopItemByRecipesId(decodeURIComponent(params.backend), paramsId))
            .then((itemResult) => {
                this.handleResult(itemResult);
                if (itemResult && Object.keys(itemResult).length > 0 && itemResult.status !== 404) {
                    return Promise.all([
                        store.dispatch(getSimilarRecipes(decodeURIComponent(params.backend), paramsId)),
                        store.dispatch(
                            getIngredientsInOnlineShopwithRecipesId(decodeURIComponent(params.backend), paramsId)
                        ),
                        store.dispatch(getRecipeRatings(decodeURIComponent(params.backend), paramsId)),
                    ]);
                } else {
                    console.error("No item result");
                }
            })
            .then(([similarResult, ingredientsResult, ratingsResult]) => {
                this.handleSimilarResult(similarResult);
                this.handleIngredientsResult(ingredientsResult);
                this.handleRatingResult(ratingsResult);
            })
            .catch((error) => {
                console.error(error);
                this.props.history.push("/404");
            });
    }

    componentDidMount() {
        this.loadItem();
        this.updateVisibleTiles();
        Dimensions.addEventListener("change", this.updateDimensions);
        this.updateDimensions();

        // necessary fix, because onScroll is not available
        this.scrollCheckInterval = setInterval(this.checkScrollPosition, 5);
    }

    componentDidUpdate() {
        if (this.props) {
            if (this.state.id !== this.props.match.params.id) {
                this.loadItem();
            }
        }
    }

    componentWillUnmount() {
        Dimensions.removeEventListener("change", this.updateDimensions);

        clearInterval(this.scrollCheckInterval);
    }

    setLoading(load) {
        this.setState({ loading: load });
    }

    toggleShowMore() {
        this.setState((prevState) => ({
            showMore: !prevState.showMore,
        }));
    }

    updateDimensions() {
        const windowWidth = Dimensions.get("window").width;
        this.setState(
            {
                windowWidth: windowWidth,
                windowHeight: Dimensions.get("window").height,
            },
            () => {
                this.updateVisibleTiles();
                this.calculateDistanceFromRight();
            }
        );
    }

    calculateDistanceFromRight = () => {
        if (this.firstTriggerRef.current) {
            this.firstTriggerRef.current.measure((x, y, width, height, pageX, pageY) => {
                const distanceFromRight = this.state.windowWidth - (pageX + width);
                this.setState({ distanceFromRight: distanceFromRight });
            });
        }
    };

    updateVisibleTiles = () => {
        const tileWidth = 146;
        const containerWidth = this.state.windowWidth;
        const visibleTiles = Math.floor(containerWidth / tileWidth);
        this.setState({ visibleTiles, tileWidth });
    };

    scrollLeft = () => {
        const newIndex = Math.max(this.state.scrollingPosition - this.state.visibleTiles, 0);
        this.flatListRef.current.scrollToIndex({ animated: true, index: newIndex });
    };

    scrollRight = () => {
        const { params } = this.props.match;
        const totalTiles = params.type === "0" ? this.state.totalPrices : this.state.totalArticleSizes;
        const newIndex = Math.min(this.state.scrollingPosition + this.state.visibleTiles, totalTiles - 1);
        this.flatListRef.current.scrollToIndex({ animated: true, index: newIndex });
    };

    handleScroll = (event) => {
        const scrollOffset = event.nativeEvent.contentOffset.x;
        const viewWidth = event.nativeEvent.layoutMeasurement.width;

        const currentIndex = Math.round(scrollOffset / this.state.tileWidth);

        this.setState({ scrollingPosition: currentIndex });
    };

    filterRatings(numberOfStars) {
        if (numberOfStars === -1) {
            this.setState({ filteredRatings: this.state.ratings });
        } else
            this.setState({ filteredRatings: this.state.ratings.filter((rating) => rating.rating === numberOfStars) });
    }

    updateSelectedArticleSize = (articleSize) => {
        const articlePrice = articleSize.Prices.find(
            (p) =>
                p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
        );

        this.setState({
            selectedArticleSize: articleSize,
            selectedPortionSize: articleSize.portionSize,
            selectedDisplayName: articleSize.Size.displayName,
        });

        if (articlePrice) {
            const selectedPrice = articlePrice.price1;
            const selectedPricePer100 = (selectedPrice / articleSize.portionSize) * 100;

            this.setState({
                selectedPrice: selectedPrice,
                selectedPricePer100: selectedPricePer100,
            });
        }
    };

    renderArticleSizeTiles = () => {
        const {
            item,
            selectedArticleSize,
            windowWidth,
            selectedPortionSize,
            selectedDisplayName,
            totalPrices,
            totalArticleSizes,
        } = this.state;
        const { theme } = this.props;
        const { params } = this.props.match;
        const isMobile = windowWidth <= desktopBreakpoint;

        const articleSizes =
            params.type === "0"
                ? item.articleSizes.filter((articleSize) =>
                      articleSize.Prices.some(
                          (p) =>
                              p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                              p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                      )
                  )
                : item.articleSizes;

        if (isMobile) {
            return (
                <>
                    <View style={{ width: "100%", position: "relative" }}>
                        {this.state.scrollingPosition > 0 && (
                            <Ionicons
                                name="chevron-back"
                                size={40}
                                color={theme.colors.primary}
                                onPress={this.scrollLeft}
                                style={ProductDetailsStyles.articleSizeCardPreviousButton}
                            />
                        )}
                        <View
                            style={{
                                overflow: "hidden",
                                width: "100%",
                            }}
                        >
                            <FlatList
                                horizontal
                                data={articleSizes}
                                maxToRenderPerBatch={2}
                                initialNumToRender={2}
                                ref={this.flatListRef}
                                onScroll={this.handleScroll}
                                scrollEventThrottle={16}
                                style={{
                                    marginLeft: 0,
                                    marginRight: 0,
                                }}
                                contentContainerStyle={{
                                    height: 170,
                                    alignItems: "center",
                                    overflow: "hidden",
                                }}
                                renderItem={({ item: articleSize, index }) => {
                                    const priceInfo = articleSize.Prices.find(
                                        (p) =>
                                            p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                                            p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                                    );

                                    const isActive =
                                        selectedArticleSize &&
                                        selectedArticleSize.articleSizesId === articleSize.articleSizesId;

                                    return (
                                        <Card
                                            style={{
                                                width: 136,
                                                backgroundColor: theme.colors.surface,
                                                borderColor: isActive ? theme.colors.primary : theme.colors.surface,
                                                minHeight: params.type === "0" ? 130 : 80,
                                                marginHorizontal: 5,
                                            }}
                                            key={index}
                                            onPress={() => this.updateSelectedArticleSize(articleSize)}
                                        >
                                            <Card.Content
                                                style={{
                                                    ...StyleSheet.flatten(ProductDetailsStyles.articelSizesCardContent),
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        ...StyleSheet.flatten(
                                                            ProductDetailsStyles.articelSizesCardTitle
                                                        ),

                                                        color: getContrastColor(theme.colors.surface),
                                                    }}
                                                >
                                                    {articleSize.Size.displayName}
                                                </Text>
                                                <Text
                                                    style={{
                                                        alignSelf: "flex-end",
                                                        color: getContrastColor(theme.colors.surface),
                                                    }}
                                                >
                                                    Menge: {formatNumberToLocalString(articleSize.portionSize)} g
                                                </Text>

                                                {priceInfo && params.type === "0" && (
                                                    <Text
                                                        style={{
                                                            ...StyleSheet.flatten(
                                                                ProductDetailsStyles.articelSizesCardPrice
                                                            ),
                                                            color: getContrastColor(theme.colors.surface),
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                            {formatter.format(priceInfo.price1)}
                                                        </Text>
                                                        <Text style={{ fontSize: 12 }}>
                                                            {`\n(${formatter.format(
                                                                (priceInfo.price1 / articleSize.portionSize) * 100
                                                            )} / 100 g)`}
                                                        </Text>
                                                    </Text>
                                                )}
                                            </Card.Content>
                                        </Card>
                                    );
                                }}
                                keyExtractor={(item, index) => index.toString()}
                                showsHorizontalScrollIndicator={false}
                            />
                        </View>
                        {articleSizes.length > this.state.visibleTiles &&
                            this.state.scrollingPosition < articleSizes.length - this.state.visibleTiles && (
                                <Ionicons
                                    name="chevron-forward"
                                    size={40}
                                    color={theme.colors.primary}
                                    onPress={this.scrollRight}
                                    style={ProductDetailsStyles.articleSizeCardNextButton}
                                />
                            )}
                    </View>
                </>
            );
        } else {
            const renderCard = (articleSize, index) => {
                const priceInfo = articleSize.Prices.find(
                    (p) =>
                        p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                        p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                );
                const isActive =
                    selectedArticleSize && selectedArticleSize.articleSizesId === articleSize.articleSizesId;
                return (
                    <Card
                        style={{
                            ...StyleSheet.flatten(ProductDetailsStyles.articelSizesCardDesktop),
                            width: "calc( (630px / 3) - 10px)",
                            backgroundColor: theme.colors.surface,
                            borderColor: isActive ? theme.colors.primary : theme.colors.surface,
                            minHeight: params.type === "0" ? 130 : 80,
                        }}
                        key={index}
                        onPress={() => {
                            this.updateSelectedArticleSize(articleSize);
                        }}
                    >
                        <Card.Content
                            style={{
                                ...StyleSheet.flatten(ProductDetailsStyles.articelSizesCardContent),
                            }}
                        >
                            <Text
                                style={{
                                    ...StyleSheet.flatten(ProductDetailsStyles.articelSizesCardTitle),
                                    color: getContrastColor(theme.colors.surface),
                                }}
                            >
                                {articleSize.Size.displayName}
                            </Text>
                            <Text style={{ alignSelf: "flex-end", color: getContrastColor(theme.colors.surface) }}>
                                Menge: {formatNumberToLocalString(articleSize.portionSize)} g
                            </Text>
                            {priceInfo && params.type === "0" && (
                                <Text
                                    style={{
                                        ...StyleSheet.flatten(ProductDetailsStyles.articelSizesCardPrice),

                                        color: getContrastColor(theme.colors.surface),
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {formatter.format(priceInfo.price1)}
                                    </Text>
                                    {`\n(${formatter.format(
                                        (priceInfo.price1 / articleSize.portionSize) * 100
                                    )} / 100 g)`}
                                </Text>
                            )}
                        </Card.Content>
                    </Card>
                );
            };
            return (
                <>
                    <View
                        style={{
                            ...StyleSheet.flatten(ProductDetailsStyles.articelSizesContainerDesktop),
                        }}
                    >
                        {articleSizes.slice(0, 6).map((articleSize, index) => renderCard(articleSize, index))}

                        {this.state.showMore && (
                            <View
                                style={{
                                    ...StyleSheet.flatten(ProductDetailsStyles.articelSizesContainerDesktop),
                                }}
                            >
                                {articleSizes.slice(6).map((articleSize, index) => renderCard(articleSize, index))}
                            </View>
                        )}

                        {articleSizes.length > 6 && (
                            <Button
                                textColor={getContrastColor(theme.colors.primary)}
                                style={{
                                    backgroundColor: theme.colors.primary,
                                    marginTop: 10,
                                    marginLeft: 5,
                                    justifyContent: "center",
                                }}
                                onPress={this.toggleShowMore}
                            >
                                {this.state.showMore ? "Weniger anzeigen" : "Mehr anzeigen"}
                            </Button>
                        )}
                    </View>
                </>
            );
        }
    };

    renderPriceBox = (item, price, theme, selectedPrice, selectedPricePer100) => {
        return (
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 0,
                    marginTop: 0,
                    alignItems: "center",
                }}
            >
                {price !== 0 && (
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            minWidth: "100%",
                            marginBottom: 15,
                            marginRight: 0,
                        }}
                    >
                        <Text
                            style={{
                                color: getContrastColor(theme.colors.surface),
                                fontSize: 40,
                                marginRight: 0,
                            }}
                        >
                            {formatter.format(selectedPrice)}
                        </Text>
                        <Text
                            style={{
                                fontSize: 16,
                                marginRight: 0,
                                marginBottom: 5,
                                color: getContrastColor(theme.colors.surface),
                            }}
                        >
                            ({formatter.format(selectedPricePer100)} / 100 g)
                        </Text>
                    </View>
                )}
                {item.onlineShopItemType === 0 && !item.soldOut && (
                    <AmountSelection
                        initialAmount={this.state.amount}
                        maxAmount={this.props.settings.store.maxPortionNumber}
                        theme={theme}
                        onAmountChange={(newAmount) => this.setState({ amount: newAmount })}
                    />
                )}

                {item.onlineShopItemType === 1 && (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: theme.colors.primary,
                            marginRight: 0,
                            marginBottom: 0,
                            justifyContent: "center",
                            height: 40,
                            width: "100%",
                        }}
                        onPress={() => {
                            this.cookRecipe(item, this.state.selectedArticleSize);
                        }}
                        icon={"pot-steam"}
                    >
                        Sofort kochen
                    </Button>
                )}

                {item.soldOut && item.onlineShopItemType === 0 ? (
                    <View
                        style={[
                            ProductDetailsStyles.articleSoldOutDisplay,
                            {
                                backgroundColor: theme.colors.primary,

                                ...Platform.select({
                                    web: {
                                        cursor: "not-allowed",
                                    },
                                }),
                            },
                        ]}
                    >
                        <Icon source="alert" size={20} color={getContrastColor(theme.colors.primary)} />
                        <Text style={{ marginLeft: 10, color: getContrastColor(theme.colors.primary) }}>
                            Leider ausverkauft
                        </Text>
                    </View>
                ) : (
                    <Button
                        textColor={getContrastColor(theme.colors.primary)}
                        style={{
                            backgroundColor: theme.colors.primary,
                            marginRight: 0,
                            marginTop: 15,
                            justifyContent: "center",
                            height: 40,
                            width: "100%",
                        }}
                        onPress={() => {
                            this.addShopHeaderItem(item);
                        }}
                        icon={item.onlineShopItemType === 1 ? "calendar" : "cart"}
                    >
                        {item.onlineShopItemType === 1 ? "In den Wochenplan" : "In den Warenkorb"}
                    </Button>
                )}
            </View>
        );
    };

    rateArticle(rating, text) {
        const { params } = this.props.match;
        const { account } = this.props;
        if (params.type === "0")
            store
                .dispatch(rateArticle(decodeURIComponent(params.backend), params.id, rating, text, this.props.keycloak))
                .then(() => this.loadItem());
        else if (params.type === "1")
            store
                .dispatch(rateRecipe(decodeURIComponent(params.backend), params.id, rating, text, this.params.keycloak))
                .then(() => this.loadItem());
        this.toggleRateArticleDialog();
    }

    checkAddButtonVisibility(item) {
        if (item.onlineShopItemType === 1) return true;
        if (
            item.articleSizes &&
            item.articleSizes.find((articleSize) => {
                return checkIfArticleSizeHasPrices(
                    articleSize,
                    this.props.settings.store.orderTypes.orderTypesId,
                    this.props.settings.store.priceGroups.priceGroupsId
                );
            })
        )
            return true;
        return false;
    }

    loadArticleDetails() {
        const { params } = this.props.match;
        const article = this.state.item;
        if (article) {
            this.setState({
                loading: true,
            });
            store
                .dispatch(getArticleDetailsByArticlesId(decodeURIComponent(params.backend), article.id))
                .then((res) => {
                    this.setState({
                        loading: false,
                        articleDetailsRows: res,
                    });
                });
        }
    }

    groupBy(array, f) {
        var groups = {};
        if (!array) return [];
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    }

    onLayoutFirstTrigger = (event) => {
        const layout = event.nativeEvent.layout;
        const windowWidth = Dimensions.get("window").width;
        const distanceFromRight = windowWidth - (layout.x + layout.width);

        this.setState({
            firstTriggerPoint: layout.y,
            distanceFromRight: distanceFromRight,
        });
    };

    checkScrollPosition = () => {
        const scrollView = this.scrollViewRef.current;

        if (scrollView) {
            scrollView.measure((x, y, width, height, pageX, pageY) => {
                const { firstTriggerPoint, secondTriggerPoint, priceBoxHeight } = this.state;
                const stickyHeaderHeight = 160;

                let priceBoxVisible = true;

                let positivePageY = Math.abs(pageY) + stickyHeaderHeight;

                if (positivePageY >= firstTriggerPoint) {
                    if (positivePageY >= secondTriggerPoint - priceBoxHeight) {
                        priceBoxVisible = false;
                        this.setState({ showPriceBoxSwitcher: true });
                    } else {
                        priceBoxVisible = true;

                        this.setState({ showPriceBoxSwitcher: false });
                    }
                }
                this.setState({ priceBoxVisible: priceBoxVisible });
            });
        }

        if (this.state.forceShowPriceBox) {
            this.setState({ priceBoxVisible: true });
            return;
        }
    };

    scrollToPriceBox = () => {
        const y = this.state.firstTriggerPoint;
        this.scrollViewRef.current.scrollTo({ y, animated: true });
    };

    showPriceBoxViaButton = () => {
        this.setState((prevState) => ({ forceShowPriceBox: !prevState.forceShowPriceBox }));
    };

    render() {
        const {
            item,
            selectedPrice,
            selectedPricePer100,
            selectedPortionSize,
            selectedDisplayName,
            loading,
            totalPrices,
            totalArticleSizes,
            windowWidth,
            showPriceBoxSwitcher,
            priceBoxWidth,
            distanceFromRight,
            priceBoxVisible,
            forceShowPriceBox,
        } = this.state;
        var additives = [];
        if (item) {
            additives = this.groupBy(item.additives, function (item) {
                return [item.additiveGroupsId];
            });
        }
        let icons = [];
        if (item && item.articleIcons) {
            try {
                icons = JSON.parse(item.articleIcons);
            } catch (error) {
                console.error("error", error);
            }
        }
        const { theme, account } = this.props;
        const { params } = this.props.match;
        const { windowSize, settings } = this.props;
        let mobile = windowSize[0] < desktopBreakpoint;
        const config = new Config();
        var price = 0;
        var price100 = 0;
        const isDesktop = windowWidth >= desktopBreakpoint;

        if (item && item.onlineShopItemType === 0) {
            const articleSize = item.articleSizes.find((a) => a.Prices.length > 0);
            if (articleSize) {
                const articlePrice = articleSize.Prices.find(
                    (p) =>
                        p.orderTypesId === this.props.settings.store.orderTypes.orderTypesId &&
                        p.priceGroupsId === this.props.settings.store.priceGroups.priceGroupsId
                );
                price = articlePrice ? articlePrice.price1 : 0;
                price100 = (price / articleSize.portionSize) * 100;
            }
        }
        return (
            <React.Fragment>
                <View
                    // onScroll={() => console.log("Scrollingsss...")}
                    style={ProductDetailsStyles.productDetailsWrapper}
                >
                    {loading && (
                        <View style={ProductDetailsStyles.productDetailsLoadingWrapper}>
                            <ActivityIndicator size="large" />
                        </View>
                    )}
                    {item && !loading && (
                        <View>
                            {isDesktop && (
                                <View
                                    onLayout={(event) => {
                                        this.setState({
                                            priceBoxHeight: event.nativeEvent.layout.height,
                                        });
                                    }}
                                    nativeID="priceBox"
                                    style={{
                                        ...StyleSheet.flatten(ProductDetailsStyles.desktopPriceBox),
                                        backgroundColor: theme.colors.surface,
                                        color: "green",
                                        // top: 160,
                                        top: "calc(" + this.props.headerHeight + " + 55px)",
                                        right: distanceFromRight - 20,
                                        width: priceBoxWidth,
                                        opacity: priceBoxVisible || forceShowPriceBox ? 1 : 0,
                                    }}
                                >
                                    {this.checkAddButtonVisibility(item) &&
                                        isDesktop &&
                                        this.renderPriceBox(item, price, theme, selectedPrice, selectedPricePer100)}
                                </View>
                            )}

                            <ScrollView showsHorizontalScrollIndicator={false} ref={this.scrollViewRef}>
                                <SafeAreaView
                                    style={{
                                        display: "flex",
                                        justifyContent: mobile ? "center" : "space-around",
                                        alignItems: mobile ? "center" : "space-around",
                                        flexDirection: "row",
                                    }}
                                >
                                    <View style={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}>
                                        <ImageBackground
                                            source={{
                                                uri: (() => {
                                                    if (this.state.item.onlineShopHeaderImage)
                                                        return (
                                                            config.imageStorageBlobURL +
                                                            this.state.item.onlineShopHeaderImage
                                                        );
                                                    if (this.state.item.guidFileName)
                                                        return (
                                                            config.imageStorageBlobURL + this.state.item.guidFileName
                                                        );
                                                    return "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/c57c74ca-9b1a-4e86-9f13-d1131d8dd670.png";
                                                })(),
                                            }}
                                            style={ShopStyles.ProductDetailsHeader}
                                        >
                                            <LinearGradient
                                                colors={["transparent", theme.colors.background]}
                                                style={{
                                                    position: "absolute",
                                                    left: 0,
                                                    right: 0,
                                                    top: 0,
                                                    height: "100%",
                                                }}
                                                start={{ x: 1, y: 0.5 }}
                                                end={{ x: 0, y: 0.5 }}
                                            />
                                            <LinearGradient
                                                colors={["transparent", theme.colors.background]}
                                                style={{
                                                    position: "absolute",
                                                    left: 0,
                                                    right: 0,
                                                    top: 0,
                                                    height: "100%",
                                                }}
                                            />
                                            <CustomIconButton
                                                icon="arrow-left"
                                                tooltip="Zurück"
                                                position="right"
                                                onPress={() => this.props.history.push("/")}
                                            ></CustomIconButton>
                                            <View
                                                ref={this.firstTriggerRef}
                                                onLayout={this.onLayoutFirstTrigger}
                                                style={isDesktop ? ProductDetailsStyles.desktopContainer : null}
                                            >
                                                <View
                                                    nativeID="head-area"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: mobile ? "column" : "row",
                                                        marginTop: 20,
                                                        marginBottom: mobile ? 20 : 50,
                                                        marginLeft: 0,
                                                        justifyContent: mobile ? "center" : "flex-start",
                                                        alignItems: mobile ? "center" : "flex-start",
                                                        width: "100%",
                                                        position: "relative",
                                                    }}
                                                >
                                                    {!mobile && (
                                                        <View
                                                            style={
                                                                isDesktop
                                                                    ? ProductDetailsStyles.desktopLeftContainer
                                                                    : null
                                                            }
                                                        >
                                                            <View
                                                                style={[
                                                                    ProductDetailsStyles.articleImageWrapper,
                                                                    {
                                                                        height: mobile ? 170 : 300,
                                                                        width: mobile ? 170 : 300,
                                                                    },
                                                                ]}
                                                            >
                                                                <Image
                                                                    style={{
                                                                        height: mobile ? 170 : 300,
                                                                        width: mobile ? 170 : 300,
                                                                        marginRight: 80,
                                                                        borderRadius: 13,
                                                                    }}
                                                                    source={
                                                                        item.onlineShopTileImage
                                                                            ? config.imageStorageBlobURL +
                                                                              item.onlineShopTileImage
                                                                            : item.guidFileName
                                                                            ? config.imageStorageBlobURL +
                                                                              item.guidFileName
                                                                            : "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/c57c74ca-9b1a-4e86-9f13-d1131d8dd670.png"
                                                                    }
                                                                />
                                                                {item.soldOut && (
                                                                    <View
                                                                        style={[
                                                                            ProductDetailsStyles.articleImageSoldOutBadge,
                                                                            {
                                                                                backgroundColor: theme.colors.primary,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                color: getContrastColor(
                                                                                    theme.colors.primary
                                                                                ),
                                                                            }}
                                                                        >
                                                                            Ausverkauft
                                                                        </Text>
                                                                    </View>
                                                                )}
                                                            </View>
                                                        </View>
                                                    )}
                                                    <View style={isDesktop ? ProductDetailsStyles.desktopCenter : null}>
                                                        <View
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                justifyContent: mobile ? "center" : "flex-start",
                                                                alignItems: mobile ? "center" : "flex-start",
                                                                maxWidth: mobile ? 300 : "auto",
                                                            }}
                                                        >
                                                            <Title
                                                                style={{
                                                                    fontFamily: "regular",
                                                                    fontSize: 32,
                                                                    textAlign: mobile ? "center" : "left",
                                                                    marginBottom: 10,
                                                                }}
                                                            >
                                                                {item.name}
                                                            </Title>
                                                            {item.categories && item.categories.length > 0 && (
                                                                <View
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        flexDirection: "row",
                                                                        textAlign: mobile ? "center" : "left",
                                                                        marginTop: mobile && price !== 0 ? 15 : 5,
                                                                        marginBottom: 25,
                                                                    }}
                                                                >
                                                                    <Text
                                                                        style={{ fontWeight: "bold", marginRight: 7 }}
                                                                    >
                                                                        {item.categories.length > 1
                                                                            ? "Kategorien:"
                                                                            : "Kategorie:"}
                                                                    </Text>
                                                                    <Text style={{ fontStyle: "italic" }}>
                                                                        {item.categories.join(" | ")}
                                                                    </Text>
                                                                </View>
                                                            )}
                                                            {icons.length > 0 && (
                                                                <View
                                                                    style={[
                                                                        ShopStyles.ProductDetailsIconsWrapper,
                                                                        {
                                                                            textAlign: mobile ? "center" : "left",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Text
                                                                        style={{ fontWeight: "bold", marginRight: 7 }}
                                                                    >
                                                                        Merkmale:
                                                                    </Text>
                                                                    {icons.map((icon, index) => (
                                                                        <View
                                                                            key={index}
                                                                            style={[
                                                                                ShopStyles.ArticleIconsItem,
                                                                                ShopStyles.ProductDetailsArticleIconsItemWrapper,
                                                                            ]}
                                                                        >
                                                                            <Image
                                                                                source={{ uri: icon }}
                                                                                style={[
                                                                                    // ShopStyles.ArticleIconsItem,
                                                                                    ShopStyles.ProductDetailsArticleIconsItem,
                                                                                ]}
                                                                            />
                                                                        </View>
                                                                    ))}
                                                                </View>
                                                            )}
                                                            {/* Fixme - deactivated until a new solution is considered and an admin interface is available */}
                                                            {/* {this.state.ratings && this.state.ratings.length > 0 && (
                                                            <View
                                                                style={{
                                                                    flexDirection: "row",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    marginBottom: 20,
                                                                }}
                                                            >
                                                                <AirbnbRating
                                                                    type="custom"
                                                                    selectedColor={theme.colors.notification}
                                                                    reviewColor={theme.colors.surface}
                                                                    ratingCount={5}
                                                                    reviews={[]}
                                                                    showRating={false}
                                                                    isDisabled={
                                                                        !(
                                                                            this.props.account &&
                                                                            this.props.account.customer
                                                                        )
                                                                    }
                                                                    size={20}
                                                                    onFinishRating={(rating) => {
                                                                        if (
                                                                            account.customer &&
                                                                            account.customer.customerUid
                                                                        ) {
                                                                            this.setState({
                                                                                articleRating: rating,
                                                                            });
                                                                            this.toggleRateArticleDialog();
                                                                        }
                                                                    }}
                                                                    defaultRating={Math.round(item.rating * 10) / 10}
                                                                    ratingBackgroundColor={"transparent"}
                                                                    ratingContainerStyle={{
                                                                        backgroundColor: "transparent",
                                                                        marginLeft: 0,
                                                                        alignItems: "center",
                                                                        marginRight: 5,
                                                                    }}
                                                                />

                                                                <Text>{Math.round(item.rating * 10) / 10}</Text>
                                                            </View>
                                                             )}  */}

                                                            <View
                                                                showsVerticalScrollIndicator={false}
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: mobile ? "center" : "flex-start",
                                                                    alignItems: mobile ? "center" : "flex-start",
                                                                }}
                                                            >
                                                                {item.description ? (
                                                                    <Text
                                                                        style={[
                                                                            mobile
                                                                                ? ShopStyles.descriptionMobile
                                                                                : ShopStyles.description,
                                                                        ]}
                                                                    >
                                                                        {item.description}
                                                                    </Text>
                                                                ) : null}

                                                                <View
                                                                    style={{
                                                                        flexDirection: mobile ? "column" : "column",
                                                                        justifyContent: mobile
                                                                            ? "center"
                                                                            : "flex-start",
                                                                        alignItems: "flex-start",
                                                                        marginTop: 0,
                                                                        marginLeft: mobile ? 20 : 0,
                                                                        marginRight: mobile ? 20 : 0,
                                                                        marginBottom: mobile ? 20 : 20,
                                                                        maxWidth: windowSize[0] <= 800 ? 300 : 640,
                                                                    }}
                                                                >
                                                                    <View
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            flexDirection: "row",
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={{ marginLeft: 0, marginBottom: 10 }}
                                                                        >
                                                                            <Text
                                                                                style={{
                                                                                    fontWeight: "bold",
                                                                                    marginRight: 7,
                                                                                }}
                                                                            >
                                                                                {totalPrices > 1 ? "Größen:" : "Größe:"}
                                                                            </Text>
                                                                            {selectedDisplayName} -{" "}
                                                                            {formatNumberToLocalString(
                                                                                selectedPortionSize
                                                                            )}{" "}
                                                                            g
                                                                        </Text>
                                                                    </View>

                                                                    {(params.type === "0" && totalPrices > 1) ||
                                                                    (params.type === "1" && totalArticleSizes > 1)
                                                                        ? this.renderArticleSizeTiles()
                                                                        : null}
                                                                </View>

                                                                {!isDesktop && this.checkAddButtonVisibility(item) && (
                                                                    <View>
                                                                        {this.renderPriceBox(
                                                                            item,
                                                                            price,
                                                                            theme,
                                                                            selectedPrice,
                                                                            selectedPricePer100
                                                                        )}
                                                                    </View>
                                                                )}
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View
                                                        onLayout={(event) => {
                                                            this.setState({
                                                                priceBoxWidth: event.nativeEvent.layout.width,
                                                            });
                                                        }}
                                                        style={[
                                                            isDesktop
                                                                ? ProductDetailsStyles.desktopRightContainer
                                                                : null,
                                                        ]}
                                                    >
                                                        {/* Dummy Element needed for real Desktoppricebox */}
                                                    </View>
                                                </View>
                                            </View>
                                        </ImageBackground>
                                        <View style={isDesktop ? ProductDetailsStyles.desktopContainer : null}>
                                            <View
                                                style={{
                                                    flexDirection: mobile ? "column" : "column",
                                                    justifyContent: mobile ? "center" : "flex-start",
                                                    alignItems: "flex-start",
                                                    marginLeft: mobile ? 20 : 0,
                                                    marginRight: mobile ? 20 : 0,
                                                    marginBottom: mobile ? 20 : 100,
                                                    maxWidth: mobile ? "100%" : 700,
                                                }}
                                            >
                                                <AdditionalProductDetails
                                                    product={item}
                                                    additives={item.additives}
                                                    ingredientsInOnlineShop={this.state.ingredientsInOnlineShop || []}
                                                />
                                            </View>
                                        </View>
                                        <View
                                            style={isDesktop ? ProductDetailsStyles.desktopContainer : null}
                                            ref={this.secondTriggerRef}
                                            onLayout={(event) => {
                                                const layout = event.nativeEvent.layout;
                                                this.setState({ secondTriggerPoint: layout.y });
                                            }}
                                        >
                                            {this.state.articleDetailsRows && (
                                                <View>
                                                    <ArticleDetails
                                                        article={item}
                                                        articleDetailsRows={this.state.articleDetailsRows}
                                                        isDesktop={isDesktop ? true : false}
                                                        windowWidth={windowWidth}
                                                    />
                                                </View>
                                            )}
                                        </View>
                                        <View style={{ marginBottom: mobile ? 20 : 0 }}>
                                            {this.state.similiarProducts &&
                                                this.state.similiarProducts.OnlineShopItems &&
                                                this.state.similiarProducts.OnlineShopItems.length > 0 && (
                                                    <Category
                                                        category={this.state.similiarProducts}
                                                        onlineShopItems={this.state.similiarProducts.OnlineShopItems}
                                                        moreButton={false}
                                                        numberOfTiles={5}
                                                        mobile={mobile}
                                                        showAddRecipeToWeeklyPlanPopup={
                                                            this.showAddRecipeToWeeklyPlanPopup
                                                        }
                                                        showAddArticleToCartPopup={this.showAddArticleToCartPopup}
                                                        cookRecipe={this.cookRecipe}
                                                    />
                                                )}
                                            {this.state.ingredientsInOnlineShop &&
                                                this.state.ingredientsInOnlineShop.OnlineShopItems &&
                                                this.state.ingredientsInOnlineShop.OnlineShopItems.length > 0 && (
                                                    <Category
                                                        category={this.state.ingredientsInOnlineShop}
                                                        onlineShopItems={
                                                            this.state.ingredientsInOnlineShop.OnlineShopItems
                                                        }
                                                        moreButton={false}
                                                        numberOfTiles={5}
                                                        mobile={mobile}
                                                        showAddRecipeToWeeklyPlanPopup={
                                                            this.showAddRecipeToWeeklyPlanPopup
                                                        }
                                                        showAddArticleToCartPopup={this.showAddArticleToCartPopup}
                                                        cookRecipe={this.cookRecipe}
                                                    />
                                                )}
                                        </View>
                                        <View style={isDesktop ? ProductDetailsStyles.desktopContainer : null}>
                                            {/* Fixme - deactivated until a new solution is considered and an admin interface is available */}
                                            {/* {this.state.ratings.length > 0 && (
                                            <React.Fragment>
                                                <Title
                                                    style={{
                                                        fontFamily: "regular",
                                                        fontSize: mobile ? 18 : 28,
                                                        marginLeft: mobile ? 20 : 50,
                                                    }}
                                                >
                                                    Kundenbewertungen
                                                </Title>
                                                <View
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        marginBottom: 20,
                                                        marginLeft: mobile ? 20 : 50,
                                                    }}
                                                >
                                                    <AirbnbRating
                                                        type="star"
                                                        selectedColor={theme.colors.notification}
                                                        ratingCount={5}
                                                        readonly
                                                        reviews={[]}
                                                        showRating={false}
                                                        size={18}
                                                        defaultRating={item.rating}
                                                        ratingContainerStyle={{
                                                            backgroundColor: "transparent",
                                                            marginLeft: 0,
                                                            alignItems: "center",
                                                            marginRight: 5,
                                                        }}
                                                    />

                                                    <Text>{item.rating}</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignItems: "flex-start",
                                                        flexDirection: mobile ? "column" : "row",
                                                        marginLeft: mobile ? 20 : 50,
                                                        marginRight: mobile ? 20 : 0,
                                                    }}
                                                >
                                                    <RatingOverview
                                                        ratings={this.state.ratings}
                                                        filterRatings={this.filterRatings}
                                                        mobile={mobile}
                                                    />
                                                    <View
                                                        style={{
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            maxWidth: mobile ? "100%" : "55%",
                                                            width: mobile ? "100%" : "inherit",
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                borderTopColor: theme.colors.surface,
                                                                borderTopWidth: 0,
                                                                flexDirection: "column",
                                                                height: "auto",
                                                                overflow: "hidden",
                                                                width: mobile ? "100%" : "inherit",
                                                            }}
                                                        >
                                                            {this.state.filteredRatings
                                                                .slice(0, this.state.numberOfReviews)
                                                                .map((rating, index) => {
                                                                    return (
                                                                        <View key={index} style={{ marginBottom: 20 }}>
                                                                            <View
                                                                                style={{
                                                                                    flexDirection: "row",
                                                                                }}
                                                                            >
                                                                                <View>
                                                                                    <AirbnbRating
                                                                                        type="star"
                                                                                        selectedColor={
                                                                                            theme.colors.notification
                                                                                        }
                                                                                        ratingCount={5}
                                                                                        reviews={[]}
                                                                                        ratingContainerStyle={{
                                                                                            height: 0,
                                                                                            marginLeft: mobile ? 0 : 10,
                                                                                        }}
                                                                                        isDisabled={true}
                                                                                        defaultRating={
                                                                                            Math.round(
                                                                                                rating.rating * 10
                                                                                            ) / 10
                                                                                        }
                                                                                        readonly
                                                                                        size={12}
                                                                                        ratingBackgroundColor={
                                                                                            "transparent"
                                                                                        }
                                                                                    />
                                                                                </View>
                                                                                <Text
                                                                                    style={{
                                                                                        paddingTop: 2,
                                                                                        marginLeft: 10,
                                                                                    }}
                                                                                >
                                                                                    {rating.Customer.firstName +
                                                                                        " " +
                                                                                        rating.Customer.lastName.charAt(
                                                                                            0
                                                                                        ) +
                                                                                        ". am " +
                                                                                        new Date(
                                                                                            rating.date
                                                                                        ).toLocaleDateString("de-DE")}
                                                                                </Text>
                                                                            </View>
                                                                            <Text
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    marginLeft: mobile ? 5 : 110,
                                                                                }}
                                                                            >
                                                                                {rating.ratingText
                                                                                    ? rating.ratingText
                                                                                    : ""}
                                                                            </Text>
                                                                        </View>
                                                                    );
                                                                })}
                                                        </View>
                                                        <React.Fragment>
                                                            {this.state.numberOfReviews <
                                                                this.state.filteredRatings.length && (
                                                                <Pressable
                                                                    onPress={() => this.showMoreReviews()}
                                                                    style={{
                                                                        color: theme.colors.surface,
                                                                        fontSize: 12,
                                                                        marginBottom: 5,
                                                                    }}
                                                                >
                                                                    <Text>Mehr anzeigen</Text>
                                                                </Pressable>
                                                            )}
                                                            {this.state.numberOfReviews > 3 &&
                                                                this.state.filteredRatings.length > 3 && (
                                                                    <Pressable
                                                                        onPress={() => this.showLessReviews()}
                                                                        style={{
                                                                            color: theme.colors.surface,
                                                                            fontSize: 12,
                                                                            marginBottom: 5,
                                                                        }}
                                                                    >
                                                                        <Text> Weniger</Text>
                                                                    </Pressable>
                                                                )}
                                                        </React.Fragment>
                                                    </View>
                                                </View>
                                            </React.Fragment>
                                             )}
                                            <RateArticleDialog
                                                visible={this.state.rateArticleDialogVisible}
                                                // visible="true"
                                                toggleRateArticleDialog={this.toggleRateArticleDialog}
                                                rateArticle={this.rateArticle}
                                                rating={this.state.articleRating}
                                                mobile={mobile}
                                                type={this.state.itemType}
                                            /> */}
                                        </View>
                                    </View>
                                </SafeAreaView>
                            </ScrollView>
                        </View>
                    )}
                </View>
                {showPriceBoxSwitcher && (
                    <FAB
                        style={{
                            ...StyleSheet.flatten(ProductDetailsStyles.fab),
                            backgroundColor: this.props.theme.colors.accent,
                            right: distanceFromRight - 90,
                            top: "calc(" + this.props.headerHeight + " + 45px)",
                        }}
                        color={getContrastColor(this.props.theme.colors.accent)}
                        icon={item.onlineShopItemType === 1 ? "pot-steam" : "cart-plus"}
                        onPress={this.showPriceBoxViaButton}
                    />
                )}
            </React.Fragment>
        );
    }
    addShopHeaderItem(item) {
        if (item.onlineShopItemType === 0) {
            if (this.state.priceBoxVisible && this.state.showPriceBoxSwitcher) {
                this.setState({ priceBoxVisible: false, forceShowPriceBox: false });
            }

            this.addArticleToCart(this.state.selectedArticleSize, item, this.state.amount);
            showToast(`Der Artikel ${item.name} wurde erfolgreich in den Warenkorb gelegt!`, "cart", "success");
        } else {
            if (!this.props.account || !this.props.account.customer) {
                this.props.toggleLoginPopup();
            } else {
                this.showAddRecipeToWeeklyPlanPopup(item, this.state.selectedArticleSize);
            }
        }
    }
}

function mapStateToProps(state) {
    const { account, settings } = state;
    return { account, settings };
}

export default connect(mapStateToProps)(withRouter(withHooksHOC(withTheme(ProductDetails))));
