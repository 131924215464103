import { StyleSheet } from "react-native";
import { Colors } from "react-native-paper";

export const ShoppingListStyles = StyleSheet.create({
    container: {
        padding: 0,
        width: "100%",
        maxWidth: 670,
        margin: "auto",
    },
    dateSurface: {
        padding: 16,
    },
    listContainer: {
        padding: 0,
    },
});
